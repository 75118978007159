import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";
import CurrencyFormat from "react-currency-format";
import Select from "react-select";
import { Col, Modal, Row, Table, Form, Button } from "react-bootstrap";
import UnitAdd from "../../unit/UnitAdd";
import IngredentModal from "../../ingredient/IngredentModal";
import Check from "../../other/Check";
import api from "../../../../helper/axiosInstance";

const errorTextStyle = {
  fontSize: 13,
  color: "red",
  marginTop: 6,
};

const FoodAdd = ({ show, onHide, copiedFoodDetail, copiedIngredients }) => {
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [ingredientOptions, setIngredientOptions] = useState([]);
  const [classGroups, setClassGroups] = useState([]);

  const [foodDetail, setFoodDetail] = useState(
    copiedFoodDetail
      ? copiedFoodDetail
      : {
          food_name: "",
          id_category: "",
          id_unit: "",
          state_unit: "",
          state_value: "",
        }
  );
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [errorMessage, setErrorMessage] = useState({
    food_name: "",
    id_category: "",
    id_unit: "",
    state_unit: "",
    state_value: "",
  });

  const [addUnitModalShow, setAddUnitModalShow] = useState(false);
  const [addIngredientModalShow, setAddIngredientModalShow] = useState(false);

  useEffect(() => {
    getListUnits();
    getListCategories();
    getListClassGroups();
    getListIngredients();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // API
  const getListUnits = async () => {
    await api
      .get(`/unit-show`)
      .then((res) => {
        if (res.data.errCode === 0) {
          setUnitOptions(res.data.units);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {});
  };

  const getListCategories = async () => {
    await api
      .get(`/category-show`)
      .then((res) => {
        if (res.data.errCode === 0) {
          setCategoryOptions(res.data.categories);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {});
  };

  const getListClassGroups = async () => {
    await api
      .get(`/viewClassGroup`)
      .then((res) => {
        if (res.data.status === true) {
          let ingredients = [];

          let groups = res.data.data.filter(
            (groupItem) =>
              Number(groupItem.is_main) === 1 &&
              Number(groupItem.is_adult) === 0
          );

          if (copiedIngredients) {
            ingredients = copiedIngredients;
          } else {
            ingredients.push({
              id: 0,
              name: "",
              id_unit_cook: 0,
              unit_cook_name: "",
              groups: groups.map((groupItem) => {
                return {
                  id: groupItem.id,
                  quantity: 0,
                };
              }),
            });
          }

          setClassGroups(groups);
          setSelectedIngredients(ingredients);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {});
  };

  const getListIngredients = async () => {
    await api
      .get(`/ingredent-show`)
      .then((res) => {
        if (res.data.errCode === 0) {
          const ingredients = res.data.ingredents
            .filter((item) => item.ingredent_type !== 3)
            .map((ingredient) => {
              return {
                id: ingredient.id,
                name: ingredient.ingredent_name,
                id_unit_cook: ingredient.id_unit_cook,
                unit_cook_name: ingredient.unit_cook_name,
              };
            });

          setIngredientOptions(ingredients);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {});
  };

  const createNewFood = async () => {
    setErrorMessage({
      food_name: "",
      id_category: "",
      id_unit: "",
      state_unit: "",
      state_value: "",
    });

    if (!foodDetail.state_unit) {
      toast("Vui lòng nhập Khối lượng/Thể tích", {
        type: "error",
        autoClose: 2000,
      });

      return;
    }

    if (!foodDetail.state_value) {
      toast("Vui lòng nhập giá trị Khối lượng/Thể tích", {
        type: "error",
        autoClose: 2000,
      });
      return;
    }

    if (
      selectedIngredients.filter((ingredient) => ingredient.id !== 0).length ===
      0
    ) {
      toast("Vui lòng chọn nguyên liệu món ăn", {
        type: "error",
        autoClose: 1000,
      });
      return;
    }

    if (
      selectedIngredients.every((item) =>
        item.groups.every((group) => parseFloat(group.quantity) === 0)
      )
    ) {
      toast("Vui lòng nhập định lượng", {
        type: "error",
        autoClose: 1000,
      });
      return;
    }

    var bodyFormData = new FormData();
    bodyFormData.append("food_name", foodDetail.food_name);
    bodyFormData.append("id_category", foodDetail.id_category);
    bodyFormData.append("id_unit", foodDetail.id_unit);
    bodyFormData.append("state_unit", foodDetail.state_unit);
    bodyFormData.append("state_value", foodDetail.state_value);
    bodyFormData.append(
      "ingredents",
      JSON.stringify(
        selectedIngredients
          .filter((ingredientItem) => ingredientItem.id !== 0)
          .map((ingredientItem) => {
            delete ingredientItem.name;
            delete ingredientItem.id_unit_cook;
            delete ingredientItem.unit_cook_name;

            return {
              ...ingredientItem,
              groups: ingredientItem.groups.map((groupItem) => {
                return {
                  id: Number(groupItem.id),
                  quantity: parseFloat(groupItem.quantity),
                };
              }),
            };
          })
      )
    );

    await api({
      method: "post",
      url: "food-create",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (res.data.errCode === 0) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });

          onHide(true);
        } else {
          setErrorMessage(res.data.message);

          toast("Thêm thất bại", {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {});
  };
  // API

  // Logic
  const removeSelectedIngredient = (ingredentId) => {
    swal({
      title: "Bạn muốn xóa nguyên liệu?",
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        setSelectedIngredients(
          selectedIngredients.filter(
            (ingredentItem) => ingredentItem.id !== ingredentId
          )
        );

        toast("Đã xóa nguyên liệu.", {
          type: "success",
          autoClose: 1000,
        });
      }
    });
  };

  const handleShowAddUnitModal = () => {
    setAddUnitModalShow(true);
  };
  const handleCloseAddUnitModal = (isReload) => {
    setAddUnitModalShow(false);

    if (isReload) {
      getListUnits();
    }
  };

  const handleShowAddIngredientModal = () => {
    setAddIngredientModalShow(true);
  };
  const handleCloseAddIngredientModal = () => {
    setAddIngredientModalShow(false);
    getListIngredients();
  };
  // Logic

  // Render
  const RenderIngredientTable = () => {
    return (
      <div style={{ overflowX: "scroll" }}>
        <Table size="lg" bordered hover>
          <thead>
            <tr className="align-middle text-center">
              <th rowSpan={2}>STT</th>
              <th rowSpan={2} style={{ width: "220px" }}>
                Nguyên liệu <i className="text-danger">*</i>
              </th>
              <th rowSpan={2}>ĐVT</th>
              <th colSpan={classGroups.length}>
                Định lượng<i className="text-danger">*</i>
              </th>
              <th rowSpan={2}>Phương thức</th>
            </tr>
            <tr className="align-middle text-center">
              {classGroups.map((classGroup) => {
                return (
                  <th key={`${classGroup.id}`}>{classGroup.group_name}</th>
                );
              })}
            </tr>
          </thead>
          <tbody>{RenderSelectedIngredients()}</tbody>
        </Table>
      </div>
    );
  };

  const RenderSelectedIngredients = () => {
    return selectedIngredients?.map((ingredientItem, index) => {
      return (
        <tr key={ingredientItem.id} className="align-middle text-center">
          <td>{index + 1}</td>
          <td>
            <Select
              options={ingredientOptions}
              className="text-start"
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder={<>Chọn nguyên liệu</>}
              value={
                ingredientOptions.find(
                  (ingredient) => ingredient.id === ingredientItem.id
                ) || "Chọn nguyên liệu"
              }
              onChange={(choice) => {
                if (
                  selectedIngredients.some(
                    (item) => Number(item.id) === Number(choice.id)
                  )
                ) {
                  toast("Bạn đã chọn nguyên liệu này rồi", {
                    type: "error",
                    autoClose: 1000,
                  });
                  return;
                }

                let newIngredent = selectedIngredients.map((item) => {
                  if (Number(ingredientItem.id) === Number(item.id)) {
                    return {
                      id: choice.id,
                      name: choice.name,
                      id_unit_cook: choice.id_unit_cook,
                      unit_cook_name: choice.unit_cook_name,
                      groups: item.groups,
                    };
                  }
                  return item;
                });

                if (ingredientItem.id === 0) {
                  newIngredent.push({
                    id: 0,
                    name: "",
                    id_unit_cook: 0,
                    unit_cook_name: "",
                    groups: classGroups.map((groupItem) => {
                      return {
                        id: groupItem.id,
                        quantity: 0,
                      };
                    }),
                  });
                }

                setSelectedIngredients(newIngredent);
              }}
              menuPortalTarget={document.querySelector("body")}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
              }}
            />
          </td>
          <td>{ingredientItem.unit_cook_name}</td>

          {ingredientItem.groups.map((groupItem) => {
            return (
              <td key={groupItem.id}>
                <CurrencyFormat
                  className="form-control text-end"
                  thousandSeparator={true}
                  required
                  value={groupItem.quantity}
                  onValueChange={(values) =>
                    setSelectedIngredients(
                      selectedIngredients.map((ingredient) => {
                        if (ingredientItem.id === ingredient.id) {
                          return {
                            ...ingredient,
                            groups: ingredient.groups.map((group) => {
                              if (group.id === groupItem.id) {
                                return {
                                  id: groupItem.id,
                                  quantity: values.value,
                                };
                              }

                              return group;
                            }),
                          };
                        }

                        return ingredient;
                      })
                    )
                  }
                  decimalScale={4}
                  disabled={ingredientItem.id === 0}
                />
              </td>
            );
          })}

          <td>
            <Button
              variant="outline-danger"
              size="sm"
              type="button"
              disabled={ingredientItem.id === 0}
              onClick={() => removeSelectedIngredient(ingredientItem.id)}
            >
              <i className="fa-solid fa-times"></i>
            </Button>
          </td>
        </tr>
      );
    });
  };

  return (
    <Modal
      size="xl"
      backdrop="static"
      keyboard={false}
      show={show}
      onHide={() => onHide()}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <i className="fa-solid fa-bowl-rice me-2"></i>
          Thêm món ăn
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col md={4}>
            <Form.Floating className="mb-3">
              <Form.Control
                type="text"
                value={foodDetail.food_name}
                onChange={(e) => {
                  setFoodDetail({
                    ...foodDetail,
                    food_name: e.target.value,
                  });
                }}
                placeholder="Tên món ăn"
                required
              />
              <Form.Label>
                Tên món ăn
                <i className="text-danger">*</i>
              </Form.Label>

              {errorMessage.food_name && (
                <p style={errorTextStyle}>{errorMessage.food_name}</p>
              )}
            </Form.Floating>

            <Form.Floating className="mb-3">
              <Form.Select
                value={foodDetail.id_category}
                onChange={(e) => {
                  setFoodDetail({
                    ...foodDetail,
                    id_category: e.target.value,
                  });
                }}
                required
              >
                <option value="">--Chọn--</option>
                {categoryOptions.length > 0
                  ? categoryOptions.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.category_name}
                        </option>
                      );
                    })
                  : "Không có lựa chọn"}
              </Form.Select>
              <Form.Label>
                Danh mục<i className="text-danger">*</i>
              </Form.Label>

              {errorMessage.id_category && (
                <p style={errorTextStyle}>{errorMessage.id_category}</p>
              )}
            </Form.Floating>

            {new Check().permission(["68"]) ? (
              <Form.Group className="text-end">
                <Button
                  size="sm"
                  variant="link"
                  type="button"
                  className="fst-italic"
                  onClick={() => handleShowAddUnitModal()}
                >
                  +Thêm đơn vị tính
                </Button>
              </Form.Group>
            ) : (
              <></>
            )}

            <Form.Floating className="mb-3">
              <Form.Select
                value={foodDetail.id_unit}
                onChange={(e) => {
                  setFoodDetail({
                    ...foodDetail,
                    id_unit: e.target.value,
                  });
                }}
                required
              >
                <option value="">--Chọn--</option>
                {unitOptions.length > 0
                  ? unitOptions.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.unit_name}
                        </option>
                      );
                    })
                  : "Không có lựa chọn"}
              </Form.Select>
              <Form.Label>
                Đơn vị tính
                <i className="text-danger">*</i>
              </Form.Label>

              {errorMessage.id_unit && (
                <p style={errorTextStyle}>{errorMessage.id_unit}</p>
              )}
            </Form.Floating>

            <Form.Floating className="mb-3">
              <Form.Select
                value={foodDetail.state_unit}
                onChange={(e) => {
                  setFoodDetail({
                    ...foodDetail,
                    state_unit: e.target.value,
                  });
                }}
                required
              >
                <option value="">--Chọn--</option>
                <option value="gam">gam</option>
                <option value="ml">ml</option>
              </Form.Select>
              <Form.Label>
                Khối lượng/Thể tích<i className="text-danger">*</i>
              </Form.Label>
            </Form.Floating>

            <Form.Floating className="mb-3">
              <Form.Control
                type="text"
                value={foodDetail.state_value}
                onChange={(e) => {
                  setFoodDetail({
                    ...foodDetail,
                    state_value: e.target.value,
                  });
                }}
                placeholder="Giá trị KL/TT"
                required
              />
              <Form.Label>
                Giá trị KL/TT<i className="text-danger">*</i>
              </Form.Label>
            </Form.Floating>
          </Col>

          <Col md={8}>
            {RenderIngredientTable()}

            {new Check().permission(["41"]) ? (
              <Button
                size="sm"
                variant="link fst-italic float-end"
                onClick={() => handleShowAddIngredientModal()}
              >
                +Thêm nguyên liệu
              </Button>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button size="sm" variant="secondary" onClick={() => onHide()}>
          <i className="fa-solid fa-times"></i> Đóng
        </Button>

        <Button
          size="sm"
          type="submit"
          variant="success"
          onClick={() => createNewFood()}
        >
          <i className="fa-solid fa-check"></i> Lưu
        </Button>
      </Modal.Footer>

      {addUnitModalShow ? (
        <UnitAdd show={addUnitModalShow} onHide={handleCloseAddUnitModal} />
      ) : (
        ""
      )}

      {addIngredientModalShow ? (
        <IngredentModal
          show={addIngredientModalShow}
          onHide={handleCloseAddIngredientModal}
          typeModal={`TM`}
        />
      ) : (
        ""
      )}
    </Modal>
  );
};

export default FoodAdd;
