import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { Modal, Table, Form, Button } from "react-bootstrap";
import Select from "react-select";
import * as XLSX from "xlsx-js-style";
import api from "../../../helper/axiosInstance";

const roles = [
  { role: "standard", name: "Cơ bản" },
  { role: "advanced", name: "Nâng cao" },
];

const errorTextStyle = {
  fontSize: 13,
  color: "red",
  marginTop: 6,
};

const ParentAccountsCreate = ({ show, onHide, listStudents }) => {
  const accountDummy = {
    id_student: null, // required, integer, match with school
    role: "standard", // required, string, only allow free, paid
    full_name: "", // required, string, lte 50 characters
    phone_number: "", // required, unique, must be phone number
    email: "", // nullable or email lte 100 characters unique
    professional: "", // nullable, professional lte 50 characters
    address: "", // nullable, address lte 120 characters
    student_name: "",
    error: "",
  };

  const [listAccounts, setListAccounts] = useState([]);
  const [messageErr, setMessageErr] = useState([]);
  const [listAccountsRes, setListAccountsRes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    onShowModal();
  }, []);

  //#region API
  const createParentAccounts = () => {
    setIsSending(true);
    let messagesArray = {};
    api({
      method: "post",
      url: "parent-account-create",
      data: {
        accounts: listAccounts,
      },
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        setIsSending(false);
        // exportListAccountsCreated(res.data.data);
        toast(res.data.messages, {
          type: "success",
          autoClose: 1000,
        });
        setListAccountsRes(res.data.data);
        // onHide(true);
      })
      .catch((err) => {
        //#region Show error messages from api
        const messages = err.response.data.messages;
        setMessageErr(messages);
        messagesArray = Object.keys(messages).map((key) => ({
          key: key,
          value: messages[key],
        }));
        const newListAccounts = [...listAccounts];
        messagesArray.forEach((message) => {
          let msg = "";
          const index = parseInt(
            message.key.substring(
              message.key.indexOf("[") + 1,
              message.key.indexOf("]")
            ),
            10
          );
          if (!Number.isNaN(index)) {
            if (message.key.includes("phone_number")) {
              msg = `${msg ? `${msg}<br>` : ""}Số điện thoại: ${message.value}`;
            }
            if (message.key.includes("full_name")) {
              msg = `${msg ? `${msg}<br>` : ""}Tên phụ huynh: ${message.value}`;
            }
            if (message.key.includes("email")) {
              msg = `${msg ? `${msg}<br>` : ""}Email: ${message.value}`;
            }
          }
          newListAccounts[index] = { ...newListAccounts[index], error: msg };
        });
        setListAccounts(newListAccounts);
        //#endregion
        setIsSending(false);
        toast("Thêm thất bại", {
          type: "error",
          autoClose: 1000,
        });
      });
  };
  //#endregion API

  //#region Logic
  const onShowModal = async () => {
    let i;
    setIsLoading(true);
    setListAccounts([]);
    const _listStudents = [];
    for (i = 0; i < listStudents.length; i++) {
      const student = listStudents[i];
      const parent = student.student_mother.name
        ? student.student_mother
        : student.student_father;
      _listStudents.push({
        ...accountDummy,
        id_student: student.id,
        student_name: student.student_name,
        full_name: parent.name ?? "",
        phone_number: parent.phone,
        professional: parent.job,
      });
    }
    setListAccounts(_listStudents);
    setIsLoading(false);
  };

  const checkInput = () => {
    let hasError = false;
    const accountsValidated = listAccounts.map((account, index) => {
      let errorMsg = "";
      if (!account.full_name) {
        hasError = true;
        errorMsg = `${
          errorMsg ? `${errorMsg}<br>` : ""
        }Tên phụ huynh không được để trống`;
      }
      if (!account.phone_number) {
        hasError = true;
        errorMsg = `${
          errorMsg ? `${errorMsg}<br>` : ""
        }Số điện thoại không được để trống`;
      }
      let i;
      for (i = 0; i < index; i++) {
        if (
          account.phone_number &&
          account.phone_number === listAccounts[i].phone_number
        ) {
          hasError = true;
          errorMsg = `${
            errorMsg ? `${errorMsg}<br>` : ""
          }Số điện thoại không được trùng nhau`;
        }
        if (account.email && account.email === listAccounts[i].email) {
          hasError = true;
          errorMsg = `${
            errorMsg ? `${errorMsg}<br>` : ""
          }Email không được trùng nhau`;
        }
      }
      return {
        ...account,
        error: errorMsg,
      };
    });
    setListAccounts(accountsValidated);
    // if (!hasError) {
    createParentAccounts();
    // }
  };

  const exportListAccountsCreated = (listAccounts) => {
    onHide(true);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, book1(listAccounts), "tài khoản");

    XLSX.writeFile(wb, "Danh sách tài khoản phụ huynh.xlsx");
  };

  const book1 = (listAccounts) => {
    const title = ["Danh sách tài khoản phụ huynh"];
    const header = [
      "STT",
      "Tên học sinh",
      "Tên phụ huynh",
      "Email",
      "Tài khoản",
      "Mật khẩu",
      "Loại tài khoản",
    ];
    const aoaAccounts = [
      title,
      header,
      ...listAccounts.map((account, index) => {
        return [
          index + 1,
          account.student_name,
          account.full_name,
          account.email,
          account.username,
          account.password,
          account.role == "standard" ? "Cơ bản" : "Nâng cao",
        ];
      }),
    ];
    var ws1 = XLSX.utils.aoa_to_sheet(aoaAccounts);
    const colAlpha = ["A", "B", "C", "D", "E", "F", "G"];
    ws1[`A1`].s = {
      font: {
        name: "Times New Roman",
        sz: 16,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      },
    };
    const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 6 } }]; // merge A1:G1
    ws1["!merges"] = merge;

    colAlpha.forEach((alpha) => {
      ws1[`${alpha}2`].s = {
        font: {
          name: "Times New Roman",
          sz: 12,
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };
    });

    for (let i = 0; i < listAccounts.length; i++) {
      colAlpha.forEach((alpha) => {
        ws1[`${alpha}${3 + i}`].s = {
          font: {
            name: "Times New Roman",
            sz: 12,
          },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        };
      });
    }

    ws1["!cols"] = [
      { wch: 5 },
      { wch: 25 },
      { wch: 25 },
      { wch: 25 },
      { wch: 20 },
      { wch: 20 },
      { wch: 25 },
    ];

    return ws1;
  };
  //#endregion Logic

  //#region Render
  return (
    <Modal
      backdrop="static"
      keyboard={false}
      show={show}
      onHide={() => onHide(true)}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title className="me-2">
          <i className="fa fa-users me-2"></i>
          Tạo tài khoản phụ huynh{" "}
        </Modal.Title>
        {listAccountsRes?.length > 0 ? (
          <Button
            size="sm"
            type="submit"
            variant="success"
            onClick={() => exportListAccountsCreated(listAccountsRes)}
            disabled={isLoading || isSending}
          >
            <i className="fa-solid fa-file-export"></i> Xuất DS Tài khoản
          </Button>
        ) : null}
      </Modal.Header>

      <Modal.Body>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : listAccountsRes?.length > 0 ? (
          <Table key="tableAccounts" size="lg" bordered hover>
            <thead>
              <tr className="text-center">
                <th colSpan={7}>DANH SÁCH TÀI KHOẢN PHỤ HUYNH</th>
              </tr>
              <tr className="align-middle text-center">
                <th>STT</th>
                <th style={{ width: "220px" }}>Tên học sinh</th>
                <th>
                  Tên phụ huynh<i className="text-danger"></i>
                </th>
                <th>Email</th>
                <th>
                  Tài khoản<i className="text-danger"></i>
                </th>{" "}
                <th>
                  Mật khẩu<i className="text-danger"></i>
                </th>
                <th>
                  Loại tài khoản<i className="text-danger"></i>
                </th>
              </tr>
            </thead>
            <tbody>
              {listAccountsRes?.map((account, index) => {
                return (
                  <>
                    <tr key={index} className="align-middle text-center">
                      {/* STT */}
                      <td>{index + 1}</td>
                      {/* Tên học sinh */}
                      <td>{account.student_name}</td>
                      {/* Tên phụ huynh */}
                      <td>{account.full_name}</td>
                      {/* Loại tài khoản  */}
                      <td>{account.email}</td>
                      {/* Số điện thoại */}
                      <td>{account.username}</td>

                      <td>{account.password}</td>
                      <td>
                        {account.role == "advanced" ? "Nâng cao" : "Cơ bản"}
                      </td>
                      {/* Email */}
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <div style={{ overflowX: "scroll" }}>
            <Table key="tableAccounts" size="lg" bordered hover>
              <thead>
                <tr className="align-middle text-center">
                  <th>STT</th>
                  <th style={{ width: "220px" }}>Tên học sinh</th>
                  <th>
                    Tên phụ huynh<i className="text-danger">*</i>
                  </th>
                  <th>
                    Loại tài khoản<i className="text-danger">*</i>
                  </th>
                  <th>
                    Số điện thoại<i className="text-danger">*</i>
                  </th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {listAccounts?.map((account, index) => {
                  return (
                    <>
                      <tr key={index} className="align-middle text-center">
                        {/* STT */}
                        <td>{index + 1}</td>
                        {/* Tên học sinh */}
                        <td>
                          <Form.Control
                            type="text"
                            value={account.student_name}
                            disabled
                          />
                        </td>
                        {/* Tên phụ huynh */}
                        <td>
                          <Form.Control
                            type="text"
                            value={account.full_name}
                            onChange={(e) => {
                              let newAccounts = listAccounts.map((item) => {
                                if (item.id_student === account.id_student) {
                                  return {
                                    ...account,
                                    full_name: e.target.value,
                                  };
                                }
                                return item;
                              });
                              delete messageErr[`accounts[${index}].full_name`];

                              setListAccounts(newAccounts);
                            }}
                            placeholder="Tên phụ huynh"
                            required
                          />
                        </td>
                        {/* Loại tài khoản  */}
                        <td>
                          <Select
                            options={roles}
                            className="text-start"
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.role}
                            placeholder={"Chọn loại tài khoản"}
                            value={
                              roles.find(
                                (role) => role.role === account.role
                              ) || "Chọn loại tài khoản"
                            }
                            onChange={(choice) => {
                              let newAccounts = listAccounts.map((item) => {
                                if (item.id_student === account.id_student) {
                                  return {
                                    ...account,
                                    role: choice.role,
                                  };
                                }
                                return item;
                              });
                              setListAccounts(newAccounts);
                            }}
                            menuPortalTarget={document.querySelector("body")}
                            styles={{
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                            }}
                          />
                        </td>
                        {/* Số điện thoại */}
                        <td>
                          <Form.Control
                            type="text"
                            value={account.phone_number}
                            onChange={(e) => {
                              let newAccounts = listAccounts.map((item) => {
                                if (item.id_student === account.id_student) {
                                  return {
                                    ...account,
                                    phone_number: e.target.value,
                                  };
                                }
                                return item;
                              });

                              delete messageErr[
                                `accounts[${index}].phone_number`
                              ];
                              setListAccounts(newAccounts);
                            }}
                            placeholder="Số điện thoại"
                            required
                          />
                        </td>
                        {/* Email */}
                        <td>
                          <Form.Control
                            type="text"
                            value={account.email}
                            onChange={(e) => {
                              let newAccounts = listAccounts.map((item) => {
                                if (item.id_student === account.id_student) {
                                  return {
                                    ...account,
                                    email: e.target.value,
                                  };
                                }
                                return item;
                              });

                              setListAccounts(newAccounts);
                              delete messageErr[`accounts[${index}].email`];
                            }}
                            placeholder="Email"
                          />
                        </td>
                      </tr>
                      {messageErr[`accounts[${index}].full_name`] ||
                      messageErr[`accounts[${index}].phone_number`] ||
                      messageErr[`accounts[${index}].email`] ? (
                        <tr>
                          <td></td>
                          <td></td>
                          <td>
                            <span className="text-form-err">
                              {messageErr[`accounts[${index}].full_name`]}
                            </span>
                          </td>{" "}
                          <td>
                            <span className="text-form-err"></span>
                          </td>
                          <td>
                            <span className="text-form-err">
                              {messageErr[`accounts[${index}].phone_number`]}
                            </span>
                          </td>
                          <td>
                            <span className="text-form-err">
                              {messageErr[`accounts[${index}].email`]}
                            </span>
                          </td>
                        </tr>
                      ) : null}
                    </>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button size="sm" variant="secondary" onClick={() => onHide(true)}>
          <i className="fa-solid fa-times"></i> Đóng
        </Button>
        {listAccountsRes?.length > 0 ? (
          <Button
            size="sm"
            type="submit"
            variant="success"
            onClick={() => exportListAccountsCreated(listAccountsRes)}
            disabled={isLoading || isSending}
          >
            <i className="fa-solid fa-file-export"></i> Xuất DS Tài khoản
          </Button>
        ) : (
          <Button
            size="sm"
            type="submit"
            variant="success"
            onClick={() => checkInput()}
            disabled={isLoading || isSending}
          >
            <i className="fa-solid fa-check"></i> Tạo
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
  //#endregion Render
};
export default ParentAccountsCreate;
