exports.PARENT_STATUS = {
  ACTIVE: "active",
  BLOCKED: "blocked",
};

exports.PARENT_ROLES = {
  FREE: "free",
  PAID: "paid",
};

exports.PARENT_PERMISSIONS = {
  MEAL_REDUCTION_NOTICE: "Báo cắt suất ăn",
  SCHOOL_LEAVE_NOTICE: "Báo nghỉ học",
  ABSENCE_HISTORY: "Lịch sử nghỉ học",
  TUITION_PAYMENT: "Thanh toán học phí",
  VACCINATION: "Tiêm ngừa",
  ACTIVITY_AND_ACHIEVEMENT_PHOTO_GALLERY: "Kho ảnh hoạt động và thành tích",
  ALLERGY_ALERT_FOR_MENU_ITEM: "Thông báo món ăn bị dị ứng",
  HEALTH_JOURNAL: "Sổ theo dõi sức khỏe",
  ACADEMIC_ACHIEVEMENT: "Thành tích học tập",
  TEACHER_EVALUATION: "Đánh giá giáo viêng",
  ACADEMIC_ACTIVITY_TIMETABLE: "Thời khóa biểu hoạt động học tập",
  ENERGY_LEVEL_OF_THE_CHILD: "Năng lượng của trẻ",
  MENU: "Thực đơn",
};

exports.DAY_OFF_STATUS = {
  PENDING: "pending",
  APPROVED: "approved",
  REJECTED: "rejected",
};

exports.STUDY_GROUP_STATUS = {
  BLOCKED: 0,
  ACTIVE: 1,
};

exports.IS_DELETED = {
  TRUE: 0,
  FALSE: 1,
};

exports.STUDENT_STATUS = {
  ACTIVE: 1,
  IN_ACTIVE: 0,
};

exports.IS_MAIN = {
  TRUE: 1,
  FALSE: 0,
};

exports.IS_ACTIVE = {
  TRUE: 1,
  FALSE: 0,
};

exports.IS_GENDER = {
  FEMALE: 1,
  MALE: 2,
  OTHER: 3,
};

exports.ONE_ITEM_FOOD = 1;

exports.SHOW_INVENTORY = {
  TRUE: 1,
  FALSE: 0,
};

exports.INGREDIENT_TYPE = {
  MARKET: 1,
  STORE: 2,
  MATERIAL: 3,
};

exports.EAT_URBAN_AREAS = {
  PROTIT: 14,
  LIPID: 26,
  GLUCID: 60,
};

exports.CALORIES = {
  PROTEIN: 4,
  LIPID: 9,
  GLUCID: 4,
};

exports.QUANTITIATIVE_ZONE = [
  {
    PROTEIN: { MIN: 13, MAX: 20 },
    LIPID: { MIN: 30, MAX: 40 },
    GLUCID: { MIN: 47, MAX: 50 },
  },
  {
    PROTEIN: { MIN: 13, MAX: 20 },
    LIPID: { MIN: 25, MAX: 35 },
    GLUCID: { MIN: 52, MAX: 60 },
  },
  {
    PROTEIN: { MIN: 13, MAX: 20 },
    LIPID: { MIN: 20, MAX: 30 },
    GLUCID: { MIN: 55, MAX: 65 },
  },
];

exports.OPTION_SELETE_MONTH = [
  { value: "", label: "Tất cả tháng" },
  { value: 1, label: "Tháng 1" },
  { value: 2, label: "Tháng 2" },
  { value: 3, label: "Tháng 3" },
  { value: 4, label: "Tháng 4" },
  { value: 5, label: "Tháng 5" },
  { value: 6, label: "Tháng 6" },
  { value: 7, label: "Tháng 7" },
  { value: 8, label: "Tháng 8" },
  { value: 9, label: "Tháng 9" },
  { value: 10, label: "Tháng 10" },
  { value: 11, label: "Tháng 11" },
  { value: 12, label: "Tháng 12" },
];

exports.INJECTIONS = [
  { value: 1, label: "Mũi sơ sinh" },
  { value: 2, label: "Mũi 1" },
  { value: 3, label: "Mũi 2" },
  { value: 4, label: "Mũi 3" },
  { value: 5, label: "Mũi 4" },
  { value: 6, label: "Mũi nhắc lại" },
];

exports.ANIMAL_PLANT_RANGE_RATIO = {
  ANIMAL: { MIN: 42, MAX: 58 },
  PLANT: { MIN: 44, MAX: 56 },
};

exports.STATUS_CODE = {
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NO_CONTENT: 204,
  MOVED_PERMANENTLY: 301,
  FOUND: 302,
  NOT_MODIFIED: 304,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  UNPROCESSABLE_ENTITY: 422,
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  SERVICE_UNAVAILABLE: 503,
};

exports.IS_STATUS = {
  ACTIVE: 1,
  IN_ACTIVE: 0,
};

exports.IS_RANK = {
  PRINCIPAL: 1,
  VICE_PRICIPAL: 2,
  CLERK: 3,
  TEACHER: 4,
  MEDICAL_PERSON: 5,
  OTHER: 6,
};

exports.IS_AUTHOR = {
  TRUE: 1,
  FALSE: 0,
};
