import React, { Component } from "react";
import {
  Button,
  Breadcrumb,
  Card,
  Container,
  Table,
  Modal,
} from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import * as XLSX from "xlsx-js-style";
import DatePicker, { registerLocale } from "react-datepicker";
import vi from "date-fns/locale/vi";
import { dateFormatter } from "../../utils/helpers";
import api from "../../../../helper/axiosInstance";

registerLocale("vi", vi);

class SupplierShoppingReportIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suppliers: {},
      selectedIngredients: {},
      ingredients: [],
      listSupplier: [],
      supplierSelected: 0,

      isLoading: false,

      startDate: new Date(),
      endDate: new Date(),

      tmpStartDate: new Date(),
      tmpEndDate: new Date(),
    };
  }

  async componentDidMount() {
    document.title = "Báo cáo mua hàng theo nhà cung cấp";

    const date = new Date();

    const start = new Date(date.getFullYear(), date.getMonth(), 1);
    start.setHours(0, 0, 0, 0);

    const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    end.setHours(23, 59, 59, 0);

    this.setState({
      startDate: start,
      endDate: end,

      tmpStartDate: start,
      tmpEndDate: end,
    });

    this.getVouchers(start, end);
  }

  getVouchers = (startDate, endDate) => {
    this.setState({
      isLoading: true,
    });
    api
      .get(
        `voucher-show?start_at=${startDate.getTime() / 1000}&term_at=${
          endDate.getTime() / 1000
        }`
      )
      .then((res) => {
        this.setState({ isLoading: false });

        if (res.data.errCode === 0) {
          let suppliers = {};
          let ingredients = [];
          res.data.vouchers.map((voucher) => {
            if (voucher.ingredents.length > 0) {
              voucher.ingredents.map((ingredient) => {
                if (!suppliers[ingredient.id_supplier]) {
                  suppliers[ingredient.id_supplier] = {
                    id: ingredient.id_supplier,
                    supplier_name: ingredient.supplier_name,
                    supplier_author: ingredient.supplier_author,
                    totalMoney: 0,
                    ingredients: [],
                  };
                }

                if (ingredient.ingredent_type === 1) {
                  const money =
                    parseFloat(ingredient.ing_price) *
                    parseFloat(ingredient.ing_quantity);

                  suppliers[ingredient.id_supplier].ingredients.push(
                    ingredient
                  );

                  suppliers[ingredient.id_supplier].totalMoney += money;

                  ingredients.push(ingredient);
                }

                return ingredient;
              });
            }

            return voucher;
          });

          this.setState({
            suppliers: suppliers,
            ingredients: ingredients,
          });
        }
      });
  };

  //#region Handle Logic
  xport = () => {
    const wb = XLSX.utils.book_new();

    const ws1 = this.sumarizeReport();
    const ws2 = this.detailReport();

    XLSX.utils.book_append_sheet(wb, ws1, "Tổng hợp mua hàng");
    XLSX.utils.book_append_sheet(wb, ws2, "Chi tiết mua hàng");

    XLSX.writeFile(wb, "Báo cáo mua hàng theo NCC.xlsx");
  };

  sumarizeReport = () => {
    const table1 = document.getElementById("supplier-sumarize-report");

    var ws1 = XLSX.utils.table_to_sheet(table1);

    // Style cell
    const colAlpha = ["A", "B", "C", "D"];

    ws1[`A1`].s = {
      font: {
        name: "Times New Roman",
        sz: 16,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    ws1[`A2`].s = {
      font: {
        name: "Times New Roman",
        sz: 14,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    colAlpha.map((alpha) => {
      ws1[`${alpha}3`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      return alpha;
    });

    for (let i = 4; i <= Object.entries(this.state.suppliers).length + 4; i++) {
      ws1[`A${i}`].s = {
        font: {
          name: "Times New Roman",
          sz: 11,
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      colAlpha.slice(1).map((alpha) => {
        if (ws1[`${alpha}${i}`].v === "empty") {
          ws1[`${alpha}${i}`].v = " ";
        }

        ws1[`${alpha}${i}`].s = {
          font: {
            name: "Times New Roman",
          },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        };

        return alpha;
      });

      ws1[`C${i}`].z = "#,###,###,###";
      delete ws1[`C${i}`].w;
      XLSX.utils.format_cell(ws1[`C${i}`]);
    }

    ws1["!cols"] = [{ wch: 5 }, { wch: 20 }, { wch: 25 }, { wch: 20 }];

    ws1["!rows"] = [{ hpt: 40 }, { hpt: 30 }];

    colAlpha.map((alpha) => {
      if (
        ws1[`${alpha}${3 + Object.entries(this.state.suppliers).length + 1}`]
          .v === "empty"
      ) {
        ws1[
          `${alpha}${3 + Object.entries(this.state.suppliers).length + 1}`
        ].v = " ";
      }

      ws1[`${alpha}${3 + Object.entries(this.state.suppliers).length + 1}`].s =
        {
          font: {
            name: "Times New Roman",
            bold: true,
          },
          alignment: {
            vertical: "center",
            horizontal: alpha === "B" ? "center" : "",
          },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        };

      return alpha;
    });

    ws1[`C${3 + Object.entries(this.state.suppliers).length + 1}`].z =
      "#,###,###,###";
    delete ws1[`C${3 + Object.entries(this.state.suppliers).length + 1}`].w;
    XLSX.utils.format_cell(
      ws1[`C${3 + Object.entries(this.state.suppliers).length + 1}`]
    );

    return ws1;
  };

  detailReport = () => {
    const table2 = document.getElementById("supplier-detail-report");

    var ws2 = XLSX.utils.table_to_sheet(table2, { raw: true });

    let totalLength = this.state.ingredients.length;

    // Style cell
    const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H", "I"];
    const colAlphaStyle = ["B", "D", "E", "F"];

    ws2[`A1`].s = {
      font: {
        name: "Times New Roman",
        sz: 16,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    ws2[`A2`].s = {
      font: {
        name: "Times New Roman",
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "left",
      },
    };

    colAlpha.map((alpha) => {
      ws2[`${alpha}3`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
          wrapText: true,
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      return alpha;
    });

    for (let i = 4; i <= totalLength + 3; i++) {
      ws2[`A${i}`].s = {
        font: {
          name: "Times New Roman",
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      ws2[`C${i}`].s = {
        font: {
          name: "Times New Roman",
        },
        alignment: {
          vertical: "center",
          horizontal: "right",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      ws2[`G${i}`].z = "#,###,###,##0.00";
      delete ws2[`G${i}`].w;
      XLSX.utils.format_cell(ws2[`G${i}`]);

      ws2[`G${i}`].s = {
        font: {
          name: "Times New Roman",
        },
        alignment: {
          vertical: "center",
          horizontal: "right",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      ws2[`H${i}`].z = "#,###,###,###";
      delete ws2[`H${i}`].w;
      XLSX.utils.format_cell(ws2[`H${i}`]);

      ws2[`H${i}`].s = {
        font: {
          name: "Times New Roman",
        },
        alignment: {
          vertical: "center",
          horizontal: "right",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      ws2[`I${i}`].z = "#,###,###,###";
      delete ws2[`I${i}`].w;
      XLSX.utils.format_cell(ws2[`I${i}`]);

      ws2[`I${i}`].s = {
        font: {
          name: "Times New Roman",
        },
        alignment: {
          vertical: "center",
          horizontal: "right",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      colAlphaStyle.map((alpha) => {
        ws2[`${alpha}${i}`].s = {
          font: {
            name: "Times New Roman",
          },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        };

        return alpha;
      });
    }

    ws2["!cols"] = [
      { wch: 4 },
      { wch: 20 },
      { wch: 10 },
      { wch: 15 },
      { wch: 20 },
      { wch: 8 },
      { wch: 8 },
      { wch: 13 },
      { wch: 13 },
    ];

    ws2["!rows"] = [{ hpt: 35 }, {}, { hpt: 30 }];

    colAlpha.map((alpha) => {
      if (ws2[`${alpha}${3 + totalLength + 1}`].v === "empty") {
        ws2[`${alpha}${3 + totalLength + 1}`].v = " ";
      }

      ws2[`${alpha}${3 + totalLength + 1}`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: alpha === "I" ? "right" : "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      return alpha;
    });

    ws2[`I${3 + totalLength + 1}`].z = "#,###,###,###";

    delete ws2[`C${3 + totalLength + 1}`].w;
    XLSX.utils.format_cell(ws2[`C${3 + totalLength + 1}`]);

    return ws2;
  };

  stringDate = (dateNumber) => {
    const date = new Date(dateNumber * 1000);
    return (
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear()
    );
  };

  stringDateTime = (dateNumber) => {
    const date = new Date(dateNumber * 1000);
    return (
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear() +
      " " +
      ("0" + date.getHours()).slice(-2) +
      ":" +
      ("0" + date.getMinutes()).slice(-2)
    );
  };

  group = (arr, key) => {
    return [
      ...arr
        .reduce(
          (acc, o) => acc.set(o[key], (acc.get(o[key]) || []).concat(o)),
          new Map()
        )
        .values(),
    ];
  };
  //#endregion

  // Render
  RenderSupplierReport() {
    let totalBigMoney = 0;
    let totalMoney = 0;
    let i = 0;
    const suppliers = this.state.suppliers;
    const selectedIngredients = this.state.selectedIngredients;
    return (
      <>
        <div
        //  style={{overflowX: "scroll"}}
        >
          <Table
            bordered
            striped
            hover
            // style={{ minWidth: 1024 }}
          >
            <thead>
              <tr className="text-center">
                <th>STT</th>
                <th>Nhà cung cấp</th>
                <th>Nhân viên</th>
                <th>Thành tiền</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(suppliers).length > 0 ? (
                Object.keys(suppliers).map((index) => {
                  i++;
                  totalBigMoney += suppliers[index].totalMoney;
                  return (
                    <tr
                      key={index}
                      className="text-end"
                      onClick={() => {
                        this.setState({
                          selectedIngredients: suppliers[index].ingredients,
                        });
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <td className="text-center">{i}</td>
                      <td className="text-start">
                        {suppliers[index].supplier_name}
                      </td>
                      <td className="text-start">
                        {" "}
                        {suppliers[index].supplier_author}{" "}
                      </td>
                      <td>
                        <CurrencyFormat
                          value={suppliers[index].totalMoney.toFixed(0)}
                          thousandSeparator={true}
                          allowNegative={false}
                          displayType="text"
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={3} className="text-center">
                    Không có dữ liệu
                  </td>
                </tr>
              )}
              <tr>
                <th></th>
                <th></th>
                <th className="text-end">TỔNG CỘNG</th>
                <th className="text-end">
                  <CurrencyFormat
                    value={totalBigMoney.toFixed(0)}
                    thousandSeparator={true}
                    allowNegative={false}
                    displayType="text"
                  />
                </th>
              </tr>
            </tbody>
          </Table>
        </div>
        {selectedIngredients.length > 0 ? (
          <div style={{ overflowX: "scroll" }}>
            <Table bordered striped hover style={{ minWidth: 800 }}>
              <thead>
                <tr className="text-center">
                  <th>STT</th>
                  <th>Sản phẩm</th>
                  <th>Ngày hóa đơn</th>
                  <th>Đơn vị tính</th>
                  <th className="text-end">Số lượng</th>
                  <th className="text-end">Đơn giá</th>
                  <th className="text-end">Thành tiền</th>
                </tr>
              </thead>
              <tbody>
                {selectedIngredients.map((item, i) => {
                  const ingredientQty = Number(item.ing_quantity).toFixed(4);
                  const ingredientPrice = Number(item.ing_price).toFixed(0);
                  const money = Number(ingredientPrice * ingredientQty).toFixed(
                    0
                  );

                  totalMoney += parseInt(money);

                  return (
                    <tr key={`ingredeint_${i}`} className="text-end">
                      <td className="text-center">{i + 1}</td>
                      <td className="text-start">{item.ingredent_name}</td>
                      <td className="text-end">
                        {dateFormatter(new Date(item.vou_date * 1000))}
                      </td>
                      <td className="text-start">{item.unit_market_name}</td>
                      <td>
                        <CurrencyFormat
                          value={parseFloat(ingredientQty)}
                          thousandSeparator={true}
                          allowNegative={false}
                          displayType="text"
                          decimalScale={4}
                        />
                      </td>
                      <td>
                        <CurrencyFormat
                          value={parseInt(ingredientPrice)}
                          thousandSeparator={true}
                          allowNegative={false}
                          displayType="text"
                        />
                      </td>
                      <td>
                        <CurrencyFormat
                          value={parseInt(money)}
                          thousandSeparator={true}
                          allowNegative={false}
                          displayType="text"
                        />
                      </td>
                    </tr>
                  );
                })}

                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th className="text-end">TỔNG CỘNG</th>
                  <th className="text-end">
                    <CurrencyFormat
                      value={parseInt(totalMoney)}
                      thousandSeparator={true}
                      allowNegative={false}
                      displayType="text"
                    />
                  </th>
                </tr>
              </tbody>
            </Table>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }

  RenderSupplierSumarizeReport() {
    let totalMoney = 0;
    const suppliers = this.state.suppliers;
    let i = 0;

    return (
      <Table id="supplier-sumarize-report">
        <thead>
          <tr>
            <th className="text-center h4" colSpan={4}>
              Thống kê mua hàng theo nhà cung cấp
            </th>
          </tr>
          <tr>
            <th className="text-center h5" colSpan={4}>
              Từ ngày {this.stringDate(this.state.startDate.getTime() / 1000)}{" "}
              đến ngày {this.stringDate(this.state.endDate.getTime() / 1000)}
            </th>
          </tr>
          <tr className="text-center">
            <th>STT</th>
            <th>NHÀ CUNG CẤP</th>
            <th>THÀNH TIỀN DỰ KIẾN</th>
            <th>GHI CHÚ</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(suppliers).length > 0
            ? Object.keys(suppliers).map((index) => {
                i++;
                totalMoney += parseFloat(suppliers[index].totalMoney);

                return (
                  <tr key={`supplier_excel_${index}`}>
                    <td>{i}</td>
                    <td>{suppliers[index].supplier_name}</td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={parseFloat(suppliers[index].totalMoney).toFixed(
                          0
                        )}
                        thousandSeparator={true}
                        allowNegative={false}
                        displayType="text"
                      />
                    </td>
                    <td>empty</td>
                  </tr>
                );
              })
            : ""}

          <tr>
            <th>empty</th>
            <th>TỔNG CỘNG</th>
            <th>
              <CurrencyFormat
                value={Number(totalMoney).toFixed(0)}
                thousandSeparator={true}
                allowNegative={false}
                displayType="text"
              />
            </th>
            <th>empty</th>
          </tr>
        </tbody>
      </Table>
    );
  }

  RenderSupplierDetailReport() {
    let totalMoney = 0;
    const ingredients = this.state.ingredients;

    return (
      <Table id="supplier-detail-report">
        <thead>
          <tr>
            <th className="text-center h4" colSpan={9}>
              Thống kê chi tiết mua hàng theo nhà cung cấp
            </th>
          </tr>
          <tr>
            <th colSpan={9}>
              Từ ngày{" "}
              {this.stringDateTime(this.state.startDate.getTime() / 1000)} đến
              ngày {this.stringDateTime(this.state.endDate.getTime() / 1000)}
            </th>
          </tr>
          <tr>
            <th>STT</th>
            <th>Nhà cung cấp</th>
            <th>Ngày hóa đơn</th>
            <th>Nhân viên</th>
            <th>Tên sản phẩm</th>
            <th>Đơn vị</th>
            <th>Số lượng</th>
            <th>Đơn giá</th>
            <th>Thành tiền</th>
          </tr>
        </thead>
        <tbody>
          {ingredients.length > 0 ? (
            ingredients.map((item, i) => {
              const ingredientQty = Number(item.ing_quantity).toFixed(4);
              const ingredientPrice = Number(item.ing_price).toFixed(0);
              const money = Number(ingredientPrice * ingredientQty).toFixed(0);

              totalMoney += parseInt(money);
              return (
                <tr key={`ingredient_excel_${i}`}>
                  <td>{i + 1}</td>
                  <td>{item.supplier_name}</td>
                  <td className="text-end">
                    {dateFormatter(new Date(item.vou_date * 1000))}
                  </td>
                  <td>{item.supplier_author}</td>
                  <td>{item.ingredent_name}</td>
                  <td>{item.unit_market_name}</td>
                  <td className="text-end">
                    <CurrencyFormat
                      value={parseFloat(ingredientQty)}
                      thousandSeparator={true}
                      allowNegative={false}
                      displayType="text"
                      decimalScale={4}
                    />
                  </td>
                  <td className="text-end">
                    <CurrencyFormat
                      value={parseInt(ingredientPrice)}
                      thousandSeparator={true}
                      allowNegative={false}
                      displayType="text"
                    />
                  </td>
                  <td className="text-end">
                    <CurrencyFormat
                      value={parseInt(money)}
                      thousandSeparator={true}
                      allowNegative={false}
                      displayType="text"
                    />
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td>empty</td>
            </tr>
          )}
          <tr>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>TỔNG CỘNG</th>
            <th>
              <CurrencyFormat
                value={parseInt(totalMoney)}
                thousandSeparator={true}
                allowNegative={false}
                displayType="text"
              />
            </th>
          </tr>
        </tbody>
      </Table>
    );
  }

  render() {
    return (
      <Container fluid>
        <Breadcrumb>
          <Breadcrumb.Item active>Báo cáo</Breadcrumb.Item>
          <Breadcrumb.Item active>Mua hàng theo nhà cung cấp</Breadcrumb.Item>
        </Breadcrumb>
        <Card>
          <Card.Header>
            <div className="d-block d-md-flex justify-content-between">
              <div className="d-block d-md-flex">
                <Card.Title>
                  <i className="fas fa-list me-1" />
                  Báo cáo mua hàng theo nhà cung cấp{" "}
                  {this.stringDate(
                    this.state.startDate.getTime() / 1000
                  )} - {this.stringDate(this.state.endDate.getTime() / 1000)}
                </Card.Title>

                <Button
                  size="sm"
                  className="ms-2 mt-3 mt-md-0"
                  onClick={() => {
                    this.setState({ show: true });
                  }}
                >
                  <i className="fa-solid fa-calendar-days"></i>
                </Button>
              </div>

              <Button
                variant="success"
                size="sm"
                onClick={() => this.xport()}
                className="mt-3 mt-md-0"
              >
                Xuất file <i className="fa-solid fa-file-export"></i>
              </Button>
            </div>
          </Card.Header>

          <Card.Body>
            {!this.state.isLoading ? (
              this.RenderSupplierReport()
            ) : (
              <div className="d-flex justify-content-center align-items-center mt-5">
                <div
                  className="spinner-border text-primary"
                  role="status"
                  style={{ width: "3rem", height: "3rem" }}
                >
                  <span className="sr-only">
                    Đang tải báo cáo mua hàng theo NCC...
                  </span>
                </div>
              </div>
            )}

            <div className="d-none">
              {this.RenderSupplierSumarizeReport()}
              {this.RenderSupplierDetailReport()}
            </div>
          </Card.Body>
        </Card>

        <Modal show={this.state.show} size={"sm"}>
          <Modal.Header>
            <Modal.Title>Chọn thời gian</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <center>
              <DatePicker
                size={{ height: "600px" }}
                onChange={(update) => {
                  this.setState({
                    tmpStartDate: update[0],
                    tmpEndDate: update[1],
                  });
                }}
                startDate={this.state.tmpStartDate}
                endDate={this.state.tmpEndDate}
                calendarStartDay={1}
                selectsRange
                inline
              />
            </center>
            <br />
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="sm"
              onClick={() => {
                this.setState({ show: false });
              }}
            >
              Hủy
            </Button>
            <Button
              size="sm"
              disabled={
                this.state.tmpStartDate === null ||
                this.state.tmpEndDate === null
              }
              onClick={() => {
                const start = this.state.tmpStartDate;
                start.setHours(0, 0, 0, 0);

                const end = this.state.tmpEndDate;
                end.setHours(23, 59, 59, 0);

                this.setState({
                  show: false,
                  startDate: start,
                  endDate: end,
                });
                this.getVouchers(start, end);
              }}
            >
              Lưu
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}

export default SupplierShoppingReportIndex;
