import * as XLSX from "xlsx-js-style";
import { isDecimal } from "../../../utils/helpers";

const fontStyle = {
  name: "Times New Roman",
};

const borderStyle = {
  top: { style: "thin" },
  bottom: { style: "thin" },
  left: { style: "thin" },
  right: { style: "thin" },
};

const exportMarketBillAll = (groupIngredientArray, listDays, groups) => {
  const findMoreMeal = (id_group) => {
    const classGroupItem = listDays?.costPerDays?.filter(
      (item) => item.id_group == id_group
    )[0];
    if (classGroupItem?.meal_quantity) {
      return classGroupItem.meal_quantity;
    } else {
      return 0;
    }
  };
  const table = document.getElementById(`market-bill-table-sum`);

  var ws = XLSX.utils.table_to_sheet(table);
  let ingredienthandle = [];
  let ingredientMap = new Map();
  listDays.times?.forEach((time) => {
    time?.foods?.forEach((food) => {
      food?.groups.forEach((group) => {
        group?.ingredents?.forEach((ingredent) => {
          let key = ingredent.id;
          let quantity = parseFloat(ingredent.ing_quantity);
          if (group.more_meal == null) {
            quantity *= parseFloat(findMoreMeal(group.id));
          } else {
            quantity *= group.more_meal;
          }
          if (ingredientMap.has(key)) {
            let existingIngredent = ingredientMap.get(key);
            existingIngredent.ing_quantity_sum += quantity;
          } else {
            ingredientMap.set(key, {
              ...ingredent,
              ing_quantity_sum: quantity,
            });
          }
        });
      });
    });
  });
  ingredienthandle = Array.from(ingredientMap.values());
  // Style cell
  const colAlpha = ["A", "B", "C", "D", "E"];

  ws["!cols"] = [
    { wch: 5 },
    { wch: 20 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
  ];

  const row = [{}, { hpt: 30 }, { hpt: 15 * (groups?.length + 1) }];
  ws["!rows"] = row;

  ws[`A1`].s = {
    font: fontStyle,
    alignment: {
      vertical: "top",
      horizontal: "left",
    },
  };

  ws[`A2`].s = {
    font: {
      ...fontStyle,
      bold: true,
      sz: 16,
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
    },
  };

  ws[`A3`].s = {
    font: fontStyle,
    alignment: {
      vertical: "top",
      horizontal: "left",
      wrapText: true,
    },
  };

  ws[`A4`].s = {
    font: fontStyle,
    alignment: {
      vertical: "center",
      horizontal: "left",
    },
  };

  ws[`D1`].s = {
    font: fontStyle,
    alignment: {
      vertical: "top",
      horizontal: "right",
    },
  };

  ws[`D3`].s = {
    font: fontStyle,
    alignment: {
      vertical: "top",
      horizontal: "left",
      wrapText: true,
    },
  };

  colAlpha.map((alpha) => {
    ws[`${alpha}4`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    return alpha;
  });

  let marketIngredientLength = ingredienthandle.filter(
    (groupIngredientItem) => groupIngredientItem.ingredent_type == 1
  ).length;
  let storeIngredientLength = ingredienthandle.filter(
    (groupIngredientItem) => groupIngredientItem.ingredent_type == 2
  ).length;

  colAlpha.map((alpha) => {
    if (ws[`${alpha}6`].v === "empty") {
      ws[`${alpha}6`].v = " ";
    }

    ws[`${alpha}6`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "left",
      },
    };
    return alpha;
  });

  const moneyFormat = "#,###,###,###";
  const numberFormat = "#,###,###,##0.00";
  colAlpha.map((alpha) => {
    if (ws[`${alpha}5`].v === "empty") {
      ws[`${alpha}5`].v = " ";
    }
    ws[`${alpha}5`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "left",
      },
    };
  });
  // Market table
  const firstLen = 6;

  for (let i = 0; i < marketIngredientLength; i++) {
    ws[`A${firstLen + i}`].s = {
      font: fontStyle,
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    ws[`B${firstLen + i}`].s = {
      font: fontStyle,
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "left",
        wrapText: true,
      },
    };

    ws[`C${firstLen + i}`].s = {
      font: fontStyle,
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "right",
      },
    };

    ws[`D${firstLen + i}`].s = {
      font: fontStyle,
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "right",
      },
    };

    ws[`E${firstLen + i}`].s = {
      font: fontStyle,
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "right",
      },
    };

    ws[`C${firstLen + i}`].z = isDecimal(ws[`C${firstLen + i}`].v)
      ? numberFormat
      : moneyFormat;
    //  numberFormat;
    delete ws[`C${firstLen + i}`].w;
    XLSX.utils.format_cell(ws[`C${firstLen + i}`]);

    ws[`D${firstLen + i}`].z = moneyFormat;
    delete ws[`D${firstLen + i}`].w;
    XLSX.utils.format_cell(ws[`D${firstLen + i}`]);

    ws[`E${firstLen + i}`].z = moneyFormat;
    delete ws[`E${firstLen + i}`].w;
    XLSX.utils.format_cell(ws[`E${firstLen + i}`]);
  }

  colAlpha.slice(0, colAlpha.length - 1).map((alpha) => {
    ws[`${alpha}${firstLen + marketIngredientLength}`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
      border: borderStyle,
    };
    return alpha;
  });

  ws[`E${firstLen + marketIngredientLength}`].s = {
    font: {
      ...fontStyle,
      bold: true,
    },
    alignment: {
      vertical: "center",
      horizontal: "right",
    },
    border: borderStyle,
  };

  ws[`E${firstLen + marketIngredientLength}`].z = moneyFormat;
  delete ws[`E${firstLen + marketIngredientLength}`].w;
  XLSX.utils.format_cell(ws[`E${firstLen + marketIngredientLength}`]);

  colAlpha.map((alpha) => {
    if (ws[`${alpha}${firstLen + marketIngredientLength + 1}`].v === "empty") {
      ws[`${alpha}${firstLen + marketIngredientLength + 1}`].v = " ";
    }

    ws[`${alpha}${firstLen + marketIngredientLength + 1}`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "left",
      },
    };
    return alpha;
  });

  // Store table
  const secondLen = firstLen + marketIngredientLength + 2;

  for (let i = 0; i < storeIngredientLength; i++) {
    ws[`A${secondLen + i}`].s = {
      font: fontStyle,
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    ws[`B${secondLen + i}`].s = {
      font: fontStyle,
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "left",
        wrapText: true,
      },
    };

    ws[`C${secondLen + i}`].s = {
      font: fontStyle,
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "right",
      },
    };

    ws[`D${secondLen + i}`].s = {
      font: fontStyle,
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "right",
      },
    };

    ws[`E${secondLen + i}`].s = {
      font: fontStyle,
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "right",
      },
    };

    ws[`C${secondLen + i}`].z = isDecimal(ws[`C${secondLen + i}`].v)
      ? numberFormat
      : moneyFormat;
    delete ws[`C${secondLen + i}`].w;
    XLSX.utils.format_cell(ws[`C${secondLen + i}`]);

    ws[`D${secondLen + i}`].z = moneyFormat;
    delete ws[`E${secondLen + i}`].w;
    XLSX.utils.format_cell(ws[`E${secondLen + i}`]);

    ws[`E${secondLen + i}`].z = moneyFormat;
    delete ws[`E${secondLen + i}`].w;
    XLSX.utils.format_cell(ws[`E${secondLen + i}`]);
  }

  colAlpha.slice(0, colAlpha.length - 1).map((alpha) => {
    ws[`${alpha}${secondLen + storeIngredientLength}`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
      border: borderStyle,
    };
    return alpha;
  });

  ws[`E${secondLen + storeIngredientLength}`].s = {
    font: {
      ...fontStyle,
      bold: true,
    },
    alignment: {
      vertical: "center",
      horizontal: "right",
    },
    border: borderStyle,
  };

  ws[`E${secondLen + storeIngredientLength}`].z = moneyFormat;
  delete ws[`E${secondLen + storeIngredientLength}`].w;
  XLSX.utils.format_cell(ws[`E${secondLen + storeIngredientLength}`]);

  // Sumary Statistic
  const thirdLen = secondLen + storeIngredientLength + 1;

  for (let i = 0; i < 8; i++) {
    ws[`A${thirdLen + i}`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "left",
      },
      border: {
        left: { style: "thin" },
      },
    };

    ws[`D${thirdLen + i}`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "right",
      },
    };

    ws[`E${thirdLen + i}`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "right",
      },
      border: {
        right: { style: "thin" },
      },
    };

    ws[`D${thirdLen + i}`].z = moneyFormat;
    delete ws[`D${thirdLen + i}`].w;
    XLSX.utils.format_cell(ws[`D${thirdLen + i}`]);

    if (i === 7) {
      ws[`A${thirdLen + i}`].s = {
        font: {
          ...fontStyle,
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "left",
        },
        border: {
          left: { style: "thin" },
          bottom: { style: "thin" },
        },
      };

      ws[`B${thirdLen + i}`].s = {
        font: {
          ...fontStyle,
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "left",
        },
        border: {
          bottom: { style: "thin" },
        },
      };

      ws[`C${thirdLen + i}`].s = {
        font: {
          ...fontStyle,
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "left",
        },
        border: {
          bottom: { style: "thin" },
        },
      };

      ws[`D${thirdLen + i}`].s = {
        font: {
          ...fontStyle,
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "right",
        },
        border: {
          bottom: { style: "thin" },
        },
      };

      ws[`E${thirdLen + i}`].s = {
        font: {
          ...fontStyle,
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "right",
        },
        border: {
          right: { style: "thin" },
          bottom: { style: "thin" },
        },
      };
    }
  }

  const merge = [
    // Title
    { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } },
    { s: { r: 0, c: 3 }, e: { r: 0, c: 4 } },

    { s: { r: 1, c: 0 }, e: { r: 1, c: 4 } },

    { s: { r: 2, c: 0 }, e: { r: 2, c: 2 } },
    { s: { r: 2, c: 3 }, e: { r: 2, c: 4 } },

    // Title

    // Body
    { s: { r: 4, c: 1 }, e: { r: 4, c: 4 } },
    {
      s: { r: firstLen + marketIngredientLength - 1, c: 0 },
      e: { r: firstLen + marketIngredientLength - 1, c: 3 },
    },

    {
      s: { r: firstLen + marketIngredientLength, c: 1 },
      e: { r: firstLen + marketIngredientLength, c: 4 },
    },
    {
      s: { r: secondLen + storeIngredientLength - 1, c: 0 },
      e: { r: secondLen + storeIngredientLength - 1, c: 3 },
    },
    // Body

    // Footer
    { s: { r: thirdLen - 1, c: 0 }, e: { r: thirdLen - 1, c: 2 } },
    { s: { r: thirdLen - 1, c: 3 }, e: { r: thirdLen - 1, c: 4 } },

    { s: { r: thirdLen, c: 0 }, e: { r: thirdLen, c: 2 } },
    { s: { r: thirdLen, c: 3 }, e: { r: thirdLen, c: 4 } },

    { s: { r: thirdLen + 1, c: 0 }, e: { r: thirdLen + 1, c: 2 } },
    { s: { r: thirdLen + 1, c: 3 }, e: { r: thirdLen + 1, c: 4 } },

    { s: { r: thirdLen + 2, c: 0 }, e: { r: thirdLen + 2, c: 2 } },
    { s: { r: thirdLen + 2, c: 3 }, e: { r: thirdLen + 2, c: 4 } },

    { s: { r: thirdLen + 3, c: 0 }, e: { r: thirdLen + 3, c: 2 } },
    { s: { r: thirdLen + 3, c: 3 }, e: { r: thirdLen + 3, c: 4 } },

    { s: { r: thirdLen + 4, c: 0 }, e: { r: thirdLen + 4, c: 2 } },
    { s: { r: thirdLen + 4, c: 3 }, e: { r: thirdLen + 4, c: 4 } },

    { s: { r: thirdLen + 5, c: 0 }, e: { r: thirdLen + 5, c: 2 } },
    { s: { r: thirdLen + 5, c: 3 }, e: { r: thirdLen + 5, c: 4 } },

    { s: { r: thirdLen + 6, c: 0 }, e: { r: thirdLen + 6, c: 2 } },
    { s: { r: thirdLen + 6, c: 3 }, e: { r: thirdLen + 6, c: 4 } },

    // Footer
  ];
  ws["!merges"] = merge;

  return ws;
};
const exportMarketBill = (groupIngredientArray, listDays) => {
  const table = document.getElementById(`market-bill-table`);

  let count = 0;

  listDays?.times?.map((item) => {
    item.foods?.length > 0 ? (count += item.foods?.length + 1) : count++;
    return item;
  });

  var ws = XLSX.utils.table_to_sheet(table);

  // Style cell
  const colAlpha = ["A", "B", "C", "D", "E"];

  ws["!cols"] = [
    { wch: 5 },
    { wch: 20 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
  ];

  const row = [{}, { hpt: 30 }, { hpt: 15 * (count + 1) }];
  ws["!rows"] = row;

  ws[`A1`].s = {
    font: fontStyle,
    alignment: {
      vertical: "top",
      horizontal: "left",
    },
  };

  ws[`A2`].s = {
    font: {
      ...fontStyle,
      bold: true,
      sz: 16,
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
    },
  };

  ws[`A3`].s = {
    font: fontStyle,
    alignment: {
      vertical: "top",
      horizontal: "left",
      wrapText: true,
    },
  };

  ws[`A4`].s = {
    font: fontStyle,
    alignment: {
      vertical: "center",
      horizontal: "left",
    },
  };

  ws[`D1`].s = {
    font: fontStyle,
    alignment: {
      vertical: "top",
      horizontal: "right",
    },
  };

  ws[`D3`].s = {
    font: fontStyle,
    alignment: {
      vertical: "top",
      horizontal: "left",
      wrapText: true,
    },
  };

  colAlpha.map((alpha) => {
    ws[`${alpha}5`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    return alpha;
  });

  let marketIngredientLength = groupIngredientArray.filter(
    (groupIngredientItem) => groupIngredientItem[0].ingredent_type === 1
  ).length;
  let storeIngredientLength = groupIngredientArray.filter(
    (groupIngredientItem) => groupIngredientItem[0].ingredent_type === 2
  ).length;

  colAlpha.map((alpha) => {
    if (ws[`${alpha}6`].v === "empty") {
      ws[`${alpha}6`].v = " ";
    }

    ws[`${alpha}6`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "left",
      },
    };
    return alpha;
  });

  const moneyFormat = "#,###,###,###";
  const numberFormat = "#,###,###,##0.00";

  // Market table
  const firstLen = 7;

  for (let i = 0; i < marketIngredientLength; i++) {
    ws[`A${firstLen + i}`].s = {
      font: fontStyle,
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    ws[`B${firstLen + i}`].s = {
      font: fontStyle,
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "left",
        wrapText: true,
      },
    };

    ws[`C${firstLen + i}`].s = {
      font: fontStyle,
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "right",
      },
    };

    ws[`D${firstLen + i}`].s = {
      font: fontStyle,
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "right",
      },
    };

    ws[`E${firstLen + i}`].s = {
      font: fontStyle,
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "right",
      },
    };

    ws[`C${firstLen + i}`].z = numberFormat;
    delete ws[`C${firstLen + i}`].w;
    XLSX.utils.format_cell(ws[`C${firstLen + i}`]);

    ws[`D${firstLen + i}`].z = moneyFormat;
    delete ws[`D${firstLen + i}`].w;
    XLSX.utils.format_cell(ws[`D${firstLen + i}`]);

    ws[`E${firstLen + i}`].z = moneyFormat;
    delete ws[`E${firstLen + i}`].w;
    XLSX.utils.format_cell(ws[`E${firstLen + i}`]);
  }

  colAlpha.slice(0, colAlpha.length - 1).map((alpha) => {
    ws[`${alpha}${firstLen + marketIngredientLength}`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
      border: borderStyle,
    };
    return alpha;
  });

  ws[`E${firstLen + marketIngredientLength}`].s = {
    font: {
      ...fontStyle,
      bold: true,
    },
    alignment: {
      vertical: "center",
      horizontal: "right",
    },
    border: borderStyle,
  };

  ws[`E${firstLen + marketIngredientLength}`].z = moneyFormat;
  delete ws[`E${firstLen + marketIngredientLength}`].w;
  XLSX.utils.format_cell(ws[`E${firstLen + marketIngredientLength}`]);

  colAlpha.map((alpha) => {
    if (ws[`${alpha}${firstLen + marketIngredientLength + 1}`].v === "empty") {
      ws[`${alpha}${firstLen + marketIngredientLength + 1}`].v = " ";
    }

    ws[`${alpha}${firstLen + marketIngredientLength + 1}`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "left",
      },
    };
    return alpha;
  });

  // Store table
  const secondLen = firstLen + marketIngredientLength + 2;

  for (let i = 0; i < storeIngredientLength; i++) {
    ws[`A${secondLen + i}`].s = {
      font: fontStyle,
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    ws[`B${secondLen + i}`].s = {
      font: fontStyle,
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "left",
        wrapText: true,
      },
    };

    ws[`C${secondLen + i}`].s = {
      font: fontStyle,
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "right",
      },
    };

    ws[`D${secondLen + i}`].s = {
      font: fontStyle,
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "right",
      },
    };

    ws[`E${secondLen + i}`].s = {
      font: fontStyle,
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "right",
      },
    };

    ws[`C${secondLen + i}`].z = numberFormat;
    delete ws[`C${secondLen + i}`].w;
    XLSX.utils.format_cell(ws[`C${secondLen + i}`]);

    ws[`D${secondLen + i}`].z = moneyFormat;
    delete ws[`E${secondLen + i}`].w;
    XLSX.utils.format_cell(ws[`E${secondLen + i}`]);

    ws[`E${secondLen + i}`].z = moneyFormat;
    delete ws[`E${secondLen + i}`].w;
    XLSX.utils.format_cell(ws[`E${secondLen + i}`]);
  }

  colAlpha.slice(0, colAlpha.length - 1).map((alpha) => {
    ws[`${alpha}${secondLen + storeIngredientLength}`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
      border: borderStyle,
    };
    return alpha;
  });

  ws[`E${secondLen + storeIngredientLength}`].s = {
    font: {
      ...fontStyle,
      bold: true,
    },
    alignment: {
      vertical: "center",
      horizontal: "right",
    },
    border: borderStyle,
  };

  ws[`E${secondLen + storeIngredientLength}`].z = moneyFormat;
  delete ws[`E${secondLen + storeIngredientLength}`].w;
  XLSX.utils.format_cell(ws[`E${secondLen + storeIngredientLength}`]);

  // Sumary Statistic
  const thirdLen = secondLen + storeIngredientLength + 1;

  for (let i = 0; i < 8; i++) {
    ws[`A${thirdLen + i}`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "left",
      },
      border: {
        left: { style: "thin" },
      },
    };

    ws[`D${thirdLen + i}`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "right",
      },
    };

    ws[`E${thirdLen + i}`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "right",
      },
      border: {
        right: { style: "thin" },
      },
    };

    ws[`D${thirdLen + i}`].z = moneyFormat;
    delete ws[`D${thirdLen + i}`].w;
    XLSX.utils.format_cell(ws[`D${thirdLen + i}`]);

    if (i === 7) {
      ws[`A${thirdLen + i}`].s = {
        font: {
          ...fontStyle,
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "left",
        },
        border: {
          left: { style: "thin" },
          bottom: { style: "thin" },
        },
      };

      ws[`B${thirdLen + i}`].s = {
        font: {
          ...fontStyle,
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "left",
        },
        border: {
          bottom: { style: "thin" },
        },
      };

      ws[`C${thirdLen + i}`].s = {
        font: {
          ...fontStyle,
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "left",
        },
        border: {
          bottom: { style: "thin" },
        },
      };

      ws[`D${thirdLen + i}`].s = {
        font: {
          ...fontStyle,
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "right",
        },
        border: {
          bottom: { style: "thin" },
        },
      };

      ws[`E${thirdLen + i}`].s = {
        font: {
          ...fontStyle,
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "right",
        },
        border: {
          right: { style: "thin" },
          bottom: { style: "thin" },
        },
      };
    }
  }

  const merge = [
    // Title
    { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } },
    { s: { r: 0, c: 3 }, e: { r: 0, c: 4 } },

    { s: { r: 1, c: 0 }, e: { r: 1, c: 4 } },

    { s: { r: 2, c: 0 }, e: { r: 2, c: 2 } },
    { s: { r: 2, c: 3 }, e: { r: 2, c: 4 } },

    { s: { r: 3, c: 0 }, e: { r: 3, c: 4 } },
    // Title

    // Body
    { s: { r: 5, c: 1 }, e: { r: 5, c: 4 } },
    {
      s: { r: firstLen + marketIngredientLength - 1, c: 0 },
      e: { r: firstLen + marketIngredientLength - 1, c: 3 },
    },

    {
      s: { r: firstLen + marketIngredientLength, c: 1 },
      e: { r: firstLen + marketIngredientLength, c: 4 },
    },
    {
      s: { r: secondLen + storeIngredientLength - 1, c: 0 },
      e: { r: secondLen + storeIngredientLength - 1, c: 3 },
    },
    // Body

    // Footer
    { s: { r: thirdLen - 1, c: 0 }, e: { r: thirdLen - 1, c: 2 } },
    { s: { r: thirdLen - 1, c: 3 }, e: { r: thirdLen - 1, c: 4 } },

    { s: { r: thirdLen, c: 0 }, e: { r: thirdLen, c: 2 } },
    { s: { r: thirdLen, c: 3 }, e: { r: thirdLen, c: 4 } },

    { s: { r: thirdLen + 1, c: 0 }, e: { r: thirdLen + 1, c: 2 } },
    { s: { r: thirdLen + 1, c: 3 }, e: { r: thirdLen + 1, c: 4 } },

    { s: { r: thirdLen + 2, c: 0 }, e: { r: thirdLen + 2, c: 2 } },
    { s: { r: thirdLen + 2, c: 3 }, e: { r: thirdLen + 2, c: 4 } },

    { s: { r: thirdLen + 3, c: 0 }, e: { r: thirdLen + 3, c: 2 } },
    { s: { r: thirdLen + 3, c: 3 }, e: { r: thirdLen + 3, c: 4 } },

    { s: { r: thirdLen + 4, c: 0 }, e: { r: thirdLen + 4, c: 2 } },
    { s: { r: thirdLen + 4, c: 3 }, e: { r: thirdLen + 4, c: 4 } },

    { s: { r: thirdLen + 5, c: 0 }, e: { r: thirdLen + 5, c: 2 } },
    { s: { r: thirdLen + 5, c: 3 }, e: { r: thirdLen + 5, c: 4 } },

    { s: { r: thirdLen + 6, c: 0 }, e: { r: thirdLen + 6, c: 2 } },
    { s: { r: thirdLen + 6, c: 3 }, e: { r: thirdLen + 6, c: 4 } },

    // Footer
  ];
  ws["!merges"] = merge;

  return ws;
};
const exportNutritionResult = (groupIngredientArray, listDays) => {
  const table = document.getElementById("nutrition-result-table");
  let count = 0;
  listDays?.times?.map((item) => {
    item.foods?.length > 0 ? (count += item.foods?.length + 1) : count++;
    return item;
  });
  var ws = XLSX.utils.table_to_sheet(table);

  // Style cell
  const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];
  const styleCol = ["B", "C", "D", "E", "F", "G", "H", "I", "J"];
  const numberColAlpha = ["C", "D", "E", "F", "G", "H", "I", "J"];
  // const amountColAlpha = ["J"];

  ws[`A1`].s = {
    font: fontStyle,
    alignment: {
      vertical: "top",
      horizontal: "left",
    },
  };

  ws[`F1`].s = {
    font: fontStyle,
    alignment: {
      vertical: "top",
      horizontal: "right",
    },
  };

  ws[`A2`].s = {
    font: {
      ...fontStyle,
      bold: true,
      sz: 16,
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
    },
  };

  ws[`A3`].s = {
    font: fontStyle,
    alignment: {
      vertical: "top",
      horizontal: "left",
      wrapText: true,
    },
  };

  ws[`F3`].s = {
    font: fontStyle,
    alignment: {
      vertical: "top",
      horizontal: "left",
      wrapText: true,
    },
  };

  colAlpha.map((alpha) => {
    ws[`${alpha}4`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: true,
      },
      border: borderStyle,
    };

    return alpha;
  });

  colAlpha.map((alpha) => {
    ws[`${alpha}5`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: true,
      },
      border: borderStyle,
    };
    return alpha;
  });

  let ingredientIndex = groupIngredientArray.length;

  let len = 6;

  const moneyFormat = "#,###,###,##";
  const numberFormat = "#,###,###,##0.00";

  for (let i = 0; i < ingredientIndex; i++) {
    ws[`A${len + i}`].s = {
      font: fontStyle,
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
      border: borderStyle,
    };

    styleCol.map((alpha) => {
      if (ws[`${alpha}${len + i}`].v === "empty") {
        ws[`${alpha}${len + i}`].v = " ";
      }

      ws[`${alpha}${len + i}`].s = {
        font: fontStyle,
        border: borderStyle,
        alignment: {
          wrapText: true,
        },
      };

      return alpha;
    });

    numberColAlpha.map((alpha) => {
      ws[`${alpha}${len + i}`].z = isDecimal(ws[`${alpha}${len + i}`].v)
        ? numberFormat
        : moneyFormat;

      delete ws[`${alpha}${len + i}`].w;
      XLSX.utils.format_cell(ws[`${alpha}${len + i}`]);
      return alpha;
    });
  }

  colAlpha.map((alpha) => {
    if (ws[`${alpha}${len + ingredientIndex}`].v === "empty") {
      ws[`${alpha}${len + ingredientIndex}`].v = " ";
    }

    ws[`${alpha}${len + ingredientIndex}`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: alpha == "A" ? "center" : "right",
      },
      border: borderStyle,
    };
    return alpha;
  });

  let lenOne = len + ingredientIndex + 1;

  for (let i = 0; i < 6; i++) {
    colAlpha.map((alpha) => {
      if (ws[`${alpha}${lenOne + i}`].v === "empty") {
        ws[`${alpha}${lenOne + i}`].v = " ";
      }

      ws[`${alpha}${lenOne + i}`].s = {
        font: {
          ...fontStyle,
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: alpha == "A" ? "left" : "right",
          wrapText: true,
        },
        border: borderStyle,
      };

      return alpha;
    });
  }

  ws["!cols"] = [
    { wch: 4 },
    { wch: 20 },
    { wch: 11 },
    { wch: 6 },
    { wch: 6 },
    { wch: 6 },
    { wch: 6 },
    { wch: 9 },
    { wch: 8.5 },
    { wch: 8.5 },
  ];

  const row = [{}, { hpt: 30 }, { hpt: 15 * (count + 1) }, { hpt: 30 }];

  for (let i = 0; i < lenOne - 3; i++) {
    row.push({});
  }

  row.push({ hpt: 30 });

  ws["!rows"] = row;

  const merge = [
    // Title
    { s: { r: 0, c: 0 }, e: { r: 0, c: 4 } },
    { s: { r: 0, c: 5 }, e: { r: 0, c: 9 } },

    { s: { r: 1, c: 0 }, e: { r: 1, c: 9 } },

    { s: { r: 2, c: 0 }, e: { r: 2, c: 4 } },
    { s: { r: 2, c: 5 }, e: { r: 2, c: 9 } },
    // Title

    // Header
    { s: { r: 3, c: 0 }, e: { r: 4, c: 0 } },
    { s: { r: 3, c: 1 }, e: { r: 4, c: 1 } },
    { s: { r: 3, c: 2 }, e: { r: 4, c: 2 } },

    { s: { r: 3, c: 3 }, e: { r: 3, c: 4 } },
    { s: { r: 3, c: 5 }, e: { r: 3, c: 6 } },

    { s: { r: 3, c: 7 }, e: { r: 4, c: 7 } },
    { s: { r: 3, c: 8 }, e: { r: 4, c: 8 } },
    { s: { r: 3, c: 9 }, e: { r: 4, c: 9 } },
    // Header

    // Footer
    {
      s: { r: len + ingredientIndex - 1, c: 0 },
      e: { r: len + ingredientIndex - 1, c: 2 },
    },
    {
      s: { r: len + ingredientIndex - 1, c: 7 },
      e: { r: len + ingredientIndex - 1, c: 9 },
    },

    {
      s: { r: len + ingredientIndex, c: 0 },
      e: { r: len + ingredientIndex, c: 1 },
    },
    {
      s: { r: len + ingredientIndex, c: 3 },
      e: { r: len + ingredientIndex, c: 4 },
    },
    {
      s: { r: len + ingredientIndex, c: 5 },
      e: { r: len + ingredientIndex, c: 6 },
    },
    {
      s: { r: len + ingredientIndex, c: 8 },
      e: { r: len + ingredientIndex, c: 9 },
    },

    {
      s: { r: len + ingredientIndex + 1, c: 0 },
      e: { r: len + ingredientIndex + 1, c: 1 },
    },
    {
      s: { r: len + ingredientIndex + 1, c: 3 },
      e: { r: len + ingredientIndex + 1, c: 4 },
    },
    {
      s: { r: len + ingredientIndex + 1, c: 5 },
      e: { r: len + ingredientIndex + 1, c: 6 },
    },
    {
      s: { r: len + ingredientIndex + 1, c: 8 },
      e: { r: len + ingredientIndex + 1, c: 9 },
    },

    {
      s: { r: len + ingredientIndex + 2, c: 0 },
      e: { r: len + ingredientIndex + 2, c: 1 },
    },
    {
      s: { r: len + ingredientIndex + 2, c: 3 },
      e: { r: len + ingredientIndex + 2, c: 4 },
    },
    {
      s: { r: len + ingredientIndex + 2, c: 5 },
      e: { r: len + ingredientIndex + 2, c: 6 },
    },
    {
      s: { r: len + ingredientIndex + 2, c: 8 },
      e: { r: len + ingredientIndex + 2, c: 9 },
    },

    {
      s: { r: len + ingredientIndex + 3, c: 0 },
      e: { r: len + ingredientIndex + 3, c: 1 },
    },
    {
      s: { r: len + ingredientIndex + 3, c: 3 },
      e: { r: len + ingredientIndex + 3, c: 4 },
    },
    {
      s: { r: len + ingredientIndex + 3, c: 5 },
      e: { r: len + ingredientIndex + 3, c: 6 },
    },
    {
      s: { r: len + ingredientIndex + 3, c: 8 },
      e: { r: len + ingredientIndex + 3, c: 9 },
    },

    {
      s: { r: len + ingredientIndex + 4, c: 0 },
      e: { r: len + ingredientIndex + 4, c: 1 },
    },
    {
      s: { r: len + ingredientIndex + 4, c: 3 },
      e: { r: len + ingredientIndex + 4, c: 4 },
    },
    {
      s: { r: len + ingredientIndex + 4, c: 5 },
      e: { r: len + ingredientIndex + 4, c: 6 },
    },
    {
      s: { r: len + ingredientIndex + 4, c: 8 },
      e: { r: len + ingredientIndex + 4, c: 9 },
    },

    {
      s: { r: len + ingredientIndex + 5, c: 0 },
      e: { r: len + ingredientIndex + 5, c: 1 },
    },
    {
      s: { r: len + ingredientIndex + 5, c: 3 },
      e: { r: len + ingredientIndex + 5, c: 4 },
    },
    {
      s: { r: len + ingredientIndex + 5, c: 5 },
      e: { r: len + ingredientIndex + 5, c: 6 },
    },
    {
      s: { r: len + ingredientIndex + 5, c: 8 },
      e: { r: len + ingredientIndex + 5, c: 9 },
    },
    // Footer
  ];
  ws["!merges"] = merge;

  return ws;
};

const exportNutritionSetupResult = (groupIngredientArray, listDays) => {
  const table = document.getElementById("nutrition-setup-result-table");
  let count = 0;
  listDays?.times?.map((item) => {
    item.foods?.length > 0 ? (count += item.foods?.length + 1) : count++;
    return item;
  });
  var ws = XLSX.utils.table_to_sheet(table);

  // Style cell
  const colAlpha = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
  ];

  const styleCol = ["B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M"];
  const amountColAlpha = ["I", "J", "K"];
  const moneyColAlpha = ["L", "M"];
  const sumaryColAlpha = ["C", "D", "F", "H", "M"];

  if (ws[`A1`].v === "empty") {
    ws[`A1`].v = " ";
  }

  ws[`A1`].s = {
    font: fontStyle,
    alignment: {
      vertical: "top",
      horizontal: "left",
    },
  };

  ws[`H1`].s = {
    font: fontStyle,
    alignment: {
      vertical: "top",
      horizontal: "right",
    },
  };

  ws[`A2`].s = {
    font: {
      ...fontStyle,
      bold: true,
      sz: 16,
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
    },
  };

  ws[`A3`].s = {
    font: fontStyle,
    alignment: {
      vertical: "top",
      horizontal: "left",
      wrapText: true,
    },
  };

  ws[`H3`].s = {
    font: fontStyle,
    alignment: {
      vertical: "top",
      horizontal: "left",
      wrapText: true,
    },
  };

  ws[`A4`].s = {
    font: fontStyle,
    alignment: {
      vertical: "center",
      horizontal: "left",
    },
  };

  colAlpha.map((alpha) => {
    ws[`${alpha}5`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: true,
      },
      border: borderStyle,
    };

    return alpha;
  });

  colAlpha.map((alpha) => {
    ws[`${alpha}6`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: true,
      },
      border: borderStyle,
    };
    return alpha;
  });

  let ingredientIndex = groupIngredientArray.length;

  let len = 7;
  const moneyFormat = "#,###,###,###";
  const numberFormat = "#,###,###,##0.00";

  for (let i = 0; i < ingredientIndex; i++) {
    ws[`A${len + i}`].s = {
      font: fontStyle,
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: true,
      },
      border: borderStyle,
    };

    styleCol.map((alpha) => {
      if (ws[`${alpha}${len + i}`].v === "empty") {
        ws[`${alpha}${len + i}`].v = " ";
      }

      ws[`${alpha}${len + i}`].s = {
        font: fontStyle,
        border: borderStyle,
        alignment: {
          vertical: "center",
          wrapText: true,
        },
      };
      return alpha;
    });

    amountColAlpha.map((alpha) => {
      ws[`${alpha}${len + i}`].z = isDecimal(ws[`${alpha}${len + i}`].v)
        ? numberFormat
        : moneyFormat;
      delete ws[`${alpha}${len + i}`].w;
      XLSX.utils.format_cell(ws[`${alpha}${len + i}`]);
      return alpha;
    });

    moneyColAlpha.map((alpha) => {
      ws[`${alpha}${len + i}`].z = moneyFormat;
      delete ws[`${alpha}${len + i}`].w;
      XLSX.utils.format_cell(ws[`${alpha}${len + i}`]);
      return alpha;
    });
  }

  ws[`A${len + ingredientIndex}`].s = {
    font: {
      ...fontStyle,
      bold: true,
    },
    alignment: {
      vertical: "center",
      horizontal: "left",
    },
    border: borderStyle,
  };

  colAlpha.slice(1, colAlpha.length).map((alpha) => {
    if (ws[`${alpha}${len + ingredientIndex}`].v === "empty") {
      ws[`${alpha}${len + ingredientIndex}`].v = " ";
    }

    ws[`${alpha}${len + ingredientIndex}`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "right",
        wrapText: true,
      },
      border: borderStyle,
    };
    return alpha;
  });

  let lenOne = len + ingredientIndex + 1;

  for (let i = 0; i < 6; i++) {
    ws[`A${lenOne + i}`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "left",
        wrapText: true,
      },
      border: {
        left: { style: "thin" },
        right: { style: "thin" },
      },
    };

    sumaryColAlpha.map((alpha) => {
      if (ws[`${alpha}${lenOne + i}`].v === "empty") {
        ws[`${alpha}${lenOne + i}`].v = " ";
      }

      ws[`${alpha}${lenOne + i}`].s = {
        font: {
          ...fontStyle,
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "right",
          wrapText: true,
        },
        border: {
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };
      return alpha;
    });

    if (ws[`I${lenOne + i}`].v === "empty") {
      ws[`I${lenOne + i}`].v = " ";
    }

    if (ws[`K${lenOne + i}`].v === "empty") {
      ws[`K${lenOne + i}`].v = " ";
    }

    ws[`I${lenOne + i}`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "left",
        wrapText: true,
      },
      border: {
        left: { style: "thin" },
        right: { style: "thin" },
      },
    };

    ws[`K${lenOne + i}`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "right",
        wrapText: true,
      },
      border: {
        left: { style: "thin" },
        right: { style: "thin" },
      },
    };

    if (i === 5) {
      ws[`A${lenOne + i}`].s = {
        font: {
          ...fontStyle,
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "left",
          wrapText: true,
        },
        border: {
          left: { style: "thin" },
          right: { style: "thin" },
          bottom: { style: "thin" },
        },
      };

      colAlpha.slice(1, colAlpha.length).map((alpha) => {
        if (ws[`${alpha}${lenOne + i}`].v === "empty") {
          ws[`${alpha}${lenOne + i}`].v = " ";
        }

        ws[`${alpha}${lenOne + i}`].s = {
          font: {
            ...fontStyle,
            bold: true,
          },
          alignment: {
            vertical: "center",
            horizontal: "right",
            wrapText: true,
          },
          border: {
            left: { style: "thin" },
            right: { style: "thin" },
            bottom: { style: "thin" },
          },
        };
        return alpha;
      });

      ws[`I${lenOne + i}`].s = {
        font: {
          ...fontStyle,
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "left",
          wrapText: true,
        },
        border: {
          left: { style: "thin" },
          right: { style: "thin" },
          bottom: { style: "thin" },
        },
      };

      ws[`K${lenOne + i}`].s = {
        font: {
          ...fontStyle,
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "right",
          wrapText: true,
        },
        border: {
          left: { style: "thin" },
          right: { style: "thin" },
          bottom: { style: "thin" },
        },
      };
    }
  }

  const row = [{}, { hpt: 30 }, { hpt: 15 * (count + 1) }, {}, { hpt: 45 }];

  for (let i = 0; i < lenOne - 6; i++) {
    row.push({});
  }

  ws["!cols"] = [
    { wch: 4 },
    { wch: 14 },
    { wch: 7 },
    { wch: 5 },
    { wch: 5 },
    { wch: 5 },
    { wch: 5 },
    { wch: 8 },
    { wch: 8 },
    { wch: 8 },
    { wch: 8 },
    { wch: 8 },
    { wch: 8 },
  ];

  row.push(
    { hpt: 30 },
    { hpt: 30 },
    { hpt: 45 },
    { hpt: 30 },
    { hpt: 30 },
    { hpt: 30 }
  );

  ws["!rows"] = row;

  const merge = [
    // Title
    { s: { r: 0, c: 0 }, e: { r: 0, c: 6 } },
    { s: { r: 0, c: 7 }, e: { r: 0, c: 12 } },

    { s: { r: 1, c: 0 }, e: { r: 1, c: 12 } },

    { s: { r: 2, c: 0 }, e: { r: 2, c: 6 } },
    { s: { r: 2, c: 7 }, e: { r: 2, c: 12 } },

    { s: { r: 3, c: 0 }, e: { r: 3, c: 12 } },
    // Title

    // Header
    { s: { r: 4, c: 0 }, e: { r: 5, c: 0 } },
    { s: { r: 4, c: 1 }, e: { r: 5, c: 1 } },
    { s: { r: 4, c: 2 }, e: { r: 5, c: 2 } },

    { s: { r: 4, c: 3 }, e: { r: 4, c: 4 } },
    { s: { r: 4, c: 5 }, e: { r: 4, c: 6 } },

    { s: { r: 4, c: 7 }, e: { r: 5, c: 7 } },
    { s: { r: 4, c: 8 }, e: { r: 5, c: 8 } },
    { s: { r: 4, c: 9 }, e: { r: 5, c: 9 } },
    { s: { r: 4, c: 10 }, e: { r: 5, c: 10 } },
    { s: { r: 4, c: 11 }, e: { r: 5, c: 11 } },
    { s: { r: 4, c: 12 }, e: { r: 5, c: 12 } },
    // Header

    // Footer
    {
      s: { r: len + ingredientIndex - 1, c: 0 },
      e: { r: len + ingredientIndex - 1, c: 2 },
    },
    {
      s: { r: len + ingredientIndex - 1, c: 7 },
      e: { r: len + ingredientIndex - 1, c: 12 },
    },

    {
      s: { r: len + ingredientIndex, c: 0 },
      e: { r: len + ingredientIndex, c: 1 },
    },
    {
      s: { r: len + ingredientIndex, c: 3 },
      e: { r: len + ingredientIndex, c: 4 },
    },
    {
      s: { r: len + ingredientIndex, c: 5 },
      e: { r: len + ingredientIndex, c: 6 },
    },
    {
      s: { r: len + ingredientIndex, c: 8 },
      e: { r: len + ingredientIndex, c: 9 },
    },
    {
      s: { r: len + ingredientIndex, c: 10 },
      e: { r: len + ingredientIndex, c: 12 },
    },

    {
      s: { r: len + ingredientIndex + 1, c: 0 },
      e: { r: len + ingredientIndex + 1, c: 1 },
    },
    {
      s: { r: len + ingredientIndex + 1, c: 3 },
      e: { r: len + ingredientIndex + 1, c: 4 },
    },
    {
      s: { r: len + ingredientIndex + 1, c: 5 },
      e: { r: len + ingredientIndex + 1, c: 6 },
    },
    {
      s: { r: len + ingredientIndex + 1, c: 8 },
      e: { r: len + ingredientIndex + 1, c: 9 },
    },
    {
      s: { r: len + ingredientIndex + 1, c: 10 },
      e: { r: len + ingredientIndex + 1, c: 12 },
    },

    {
      s: { r: len + ingredientIndex + 2, c: 0 },
      e: { r: len + ingredientIndex + 2, c: 1 },
    },
    {
      s: { r: len + ingredientIndex + 2, c: 3 },
      e: { r: len + ingredientIndex + 2, c: 4 },
    },
    {
      s: { r: len + ingredientIndex + 2, c: 5 },
      e: { r: len + ingredientIndex + 2, c: 6 },
    },
    {
      s: { r: len + ingredientIndex + 2, c: 8 },
      e: { r: len + ingredientIndex + 2, c: 9 },
    },
    {
      s: { r: len + ingredientIndex + 2, c: 10 },
      e: { r: len + ingredientIndex + 2, c: 12 },
    },

    {
      s: { r: len + ingredientIndex + 3, c: 0 },
      e: { r: len + ingredientIndex + 3, c: 1 },
    },
    {
      s: { r: len + ingredientIndex + 3, c: 3 },
      e: { r: len + ingredientIndex + 3, c: 4 },
    },
    {
      s: { r: len + ingredientIndex + 3, c: 5 },
      e: { r: len + ingredientIndex + 3, c: 6 },
    },
    {
      s: { r: len + ingredientIndex + 3, c: 8 },
      e: { r: len + ingredientIndex + 3, c: 9 },
    },
    {
      s: { r: len + ingredientIndex + 3, c: 10 },
      e: { r: len + ingredientIndex + 3, c: 12 },
    },

    {
      s: { r: len + ingredientIndex + 4, c: 0 },
      e: { r: len + ingredientIndex + 4, c: 1 },
    },
    {
      s: { r: len + ingredientIndex + 4, c: 3 },
      e: { r: len + ingredientIndex + 4, c: 4 },
    },
    {
      s: { r: len + ingredientIndex + 4, c: 5 },
      e: { r: len + ingredientIndex + 4, c: 6 },
    },
    {
      s: { r: len + ingredientIndex + 4, c: 8 },
      e: { r: len + ingredientIndex + 4, c: 9 },
    },
    {
      s: { r: len + ingredientIndex + 4, c: 10 },
      e: { r: len + ingredientIndex + 4, c: 12 },
    },

    {
      s: { r: len + ingredientIndex + 5, c: 0 },
      e: { r: len + ingredientIndex + 5, c: 1 },
    },
    {
      s: { r: len + ingredientIndex + 5, c: 3 },
      e: { r: len + ingredientIndex + 5, c: 4 },
    },
    {
      s: { r: len + ingredientIndex + 5, c: 5 },
      e: { r: len + ingredientIndex + 5, c: 6 },
    },
    {
      s: { r: len + ingredientIndex + 5, c: 8 },
      e: { r: len + ingredientIndex + 5, c: 9 },
    },
    {
      s: { r: len + ingredientIndex + 5, c: 10 },
      e: { r: len + ingredientIndex + 5, c: 12 },
    },
    // Footer
  ];
  ws["!merges"] = merge;

  return ws;
};

export {
  exportMarketBill,
  exportNutritionResult,
  exportNutritionSetupResult,
  exportMarketBillAll,
};
