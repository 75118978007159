import React, { useState } from "react";
import { Form, InputGroup, Button } from "react-bootstrap";

const PasswordInput = ({
  label = "",
  name = "",
  value = "",
  placeholder = "",
  className = "",
  onChange,
  required = false,
  errMessage = "",
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Form.Floating>
      <InputGroup className="has-validation w-100 is-invalid">
        <Form.Floating
          style={{ width: "calc(100% - 46px)" }}
          className={`border-end-0 rounded-end-0`}
        >
          <Form.Control
            onChange={onChange}
            value={value}
            type={showPassword ? "text" : "password"}
            name={name}
            className={`${className} ${errMessage ? "is-invalid" : ""}`}
            placeholder={placeholder}
            required={required}
          />
          <Form.Label>
            {label}
            {required ? <span className="text-danger">*</span> : <></>}
          </Form.Label>
        </Form.Floating>

        <InputGroup.Text
          onClick={togglePasswordVisibility}
          style={{ width: "46px" }}
          className={errMessage !== "" ? "is-invalid" : ""}
        >
          <i
            className={
              !showPassword ? "fa-regular fa-eye-slash" : "fa-regular fa-eye"
            }
          />
        </InputGroup.Text>
      </InputGroup>
      <span className="text-danger">{errMessage}</span>
    </Form.Floating>
  );
};

export default PasswordInput;
