import React, { useEffect, useState } from "react";
import { Button, Card, Modal, Tabs, Tab } from "react-bootstrap";
import { toast } from "react-toastify";
import Check from "../other/Check";
import DataTables from "../datatable/DataTables";
import { dateFormatter } from "../utils/helpers";
import swal from "sweetalert";
import ChildGrowthModal from "./ChildGrowthAdd.";
import api from "../../../helper/axiosInstance";
import GrowthChartHeight from "./GrowthChartHeight";
import GrowthChartWeight from "./GrowthChartWeight";

const ChildGrowth = ({ show, onHide, studentChoice }) => {
  const [listChildGrowth, setListChildGrowth] = useState([]);
  const [showChildAdd, setShowChildAdd] = useState(false);
  const [showChildEdit, setShowChildEdit] = useState(false);
  const [data, setData] = useState([]);
  const [dataChart, setDataChart] = useState([]);
  const [key, setKey] = useState("0");

  useEffect(() => {
    getChildGrowth();
    getDataChart(studentChoice.gender_name);
  }, []);

  //#region API
  const getDataChart = (gender_name) => {
    let gender = 0;
    if (gender_name == "Nam") {
      gender = "WFH_BOYS066";
    } else {
      if (gender_name == "Nữ") {
        gender = "WFH_GIRLS066";
      }
    }
    if (gender != 0)
      api
        .get(`/child-growth-standard?fileName=${gender}`)
        .then((res) => {
          setDataChart(res.data.data);
        })
        .catch((err) => {
          toast("Đã có lỗi xảy ra vui lòng thử lại", {
            type: "error",
            autoClose: 1000,
          });
        });
    else setDataChart([]);
  };
  const getChildGrowth = async () => {
    await api
      .get(`/child-growth-standard-shows?id_student=${studentChoice?.id}`)
      .then((res) => {
        setListChildGrowth(res.data.data);
      })
      .catch((err) => {
        toast("Đã có lỗi xảy ra vui lòng thử lại", {
          type: "error",
          autoClose: 1000,
        });
      });
  };

  const deleteChildGrowth = (id) => {
    swal({
      title: `Bạn chắc muốn xóa sổ sức khỏe?`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then(async (ok) => {
      if (ok) {
        await api
          .delete(`/child-growth-standard-delete?id=${id}`)
          .then((res) => {
            toast(res.data.messages, { type: "success" });
            getChildGrowth();
          })
          .catch((err) => {
            toast(err.message, { type: "error" });
          });
      }
    });
  };
  const dataTableColumns = [
    {
      name: "Tuổi (tháng)",
      selector: (row) => row.month,
      sortable: true,
      wrap: true,
    },
    {
      name: "Chiều cao (cm)",
      selector: (row) => row.height,
      wrap: true,
      right: true,
    },
    {
      name: "Cân nặng (kg)",
      selector: (row) => row.weight,
      sortable: true,
      right: true,

      wrap: true,
    },
    {
      name: "Tiêu chí khác",
      selector: (row) => row.other,
      cell: (row) =>
        row.other?.length > 0
          ? row?.other?.map((item, index) => (
              <React.Fragment key={index}>
                {Object.entries(item)
                  .map(([key, value]) => `${key}: ${value}`)
                  .join(", ")}
                <br />
              </React.Fragment>
            ))
          : null,

      wrap: true,
    },
    {
      name: "Phương thức",
      selector: (row) => row,
      cell: (row) => (
        <div div style={{ display: "flex" }}>
          {new Check().permission(["17", "18", "20"], "or") ? (
            <Button
              variant="warning me-2"
              size="sm"
              type="button"
              onClick={() => {
                setData(row);
                setShowChildEdit(true);
              }}
              title="Chi tiết"
            >
              <i className="fa-solid fa-pencil"></i>
            </Button>
          ) : null}

          {new Check().permission(["21"]) ? (
            <Button
              variant="danger"
              type="button"
              size="sm"
              onClick={() => {
                deleteChildGrowth(row.id);
              }}
              title="Xóa"
            >
              <i className="fas fa-trash"></i>
            </Button>
          ) : null}
        </div>
      ),
      center: true,
    },
  ];

  return (
    <>
      <Modal
        keyboard={false}
        show={show}
        onHide={() => onHide()}
        size="lg"
        // fullscreen
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="fa-solid fa-heart-pulse me-2"></i>
            Sổ theo dõi sức khỏe - {studentChoice?.student_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
            <Tab eventKey="0" title="Bảng thống kê">
              <Card className="mb-4">
                <Card.Header>
                  <Card.Title>
                    <i className="fas fa-list me-1" /> Danh sách
                    {new Check().permission([143]) ? (
                      <Button
                        variant="success"
                        size="sm"
                        className="float-end ms-2"
                        onClick={() => {
                          setShowChildAdd(true);
                        }}
                        title="Thêm"
                      >
                        + Thêm
                      </Button>
                    ) : null}
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <DataTables
                    data={listChildGrowth}
                    columns={dataTableColumns}
                  />
                </Card.Body>
              </Card>
            </Tab>
            <Tab eventKey="1" title="Biểu đồ tăng trưởng">
              {studentChoice?.gender_name == "Khác" ? (
                <p className="text-center">
                  Cần xác định giới tính của{" "}
                  <strong> {studentChoice?.student_name}</strong> để xem biểu đồ
                </p>
              ) : dataChart ? (
                <div>
                  <GrowthChartHeight
                    dataChart={dataChart}
                    listChildGrowth={listChildGrowth}
                  />
                  <GrowthChartWeight
                    dataChart={dataChart}
                    listChildGrowth={listChildGrowth}
                  />
                </div>
              ) : (
                "Đã thấy gì đâu"
              )}
            </Tab>
          </Tabs>

          {showChildAdd ? (
            <ChildGrowthModal
              show={showChildAdd}
              onHide={() => {
                setShowChildAdd(false);
              }}
              data={null}
              onSubmit={() => {
                setShowChildAdd(false);
                getChildGrowth();
              }}
              studentChoice={studentChoice}
            />
          ) : null}
          {showChildEdit ? (
            <ChildGrowthModal
              show={showChildEdit}
              onHide={() => {
                setShowChildEdit(false);
              }}
              data={data}
              onSubmit={() => {
                setShowChildEdit(false);
                getChildGrowth();
              }}
              studentChoice={studentChoice}
            />
          ) : null}
        </Modal.Body>
      </Modal>
    </>
  );
  //#endregion Render
};
export default ChildGrowth;
