import React, { Component } from "react";
import DataTables from "../datatable/DataTables";
import DatePicker from "react-datepicker";

import {
  Button,
  Col,
  Card,
  Container,
  Breadcrumb,
  Alert,
  Tabs,
  Tab,
  Form,
  Modal,
} from "react-bootstrap";
import api from "../../../helper/axiosInstance";

class Storeinventory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listinventory: [],
      ingredents_search: [],
      ingredentTextSearch: "",
      tabkey: "2",
      checkload: true,
      show: false,
      startDate: new Date(),
      endDate: new Date(),
      monthOptions: [],
      selectedMonth: { value: 0, label: "Tất cả tháng" },

      yearOptions: [],
      selectedYear: { value: 0, label: "Tất cả năm" },
    };
  }

  componentDidMount() {
    document.title = "Lịch sử kho";

    const date = new Date();

    const start = new Date(date.getFullYear(), date.getMonth(), 1);
    start.setHours(0, 0, 0, 0);

    const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    end.setHours(23, 59, 59, 0);

    this.setState(
      {
        startDate: start,
        endDate: end,
      },
      () => {
        this.getlistinventory(2, this.state.startDate, this.state.endDate);
      }
    );

    // Init month options
    const initialMonths = [];
    initialMonths.push({ value: 0, label: "Tất cả tháng" });
    for (let i = 1; i <= 12; i++) {
      initialMonths.push({ value: i, label: "Tháng " + i });
    }

    // Init year options
    const initialYears = [];
    initialYears.push({ value: 0, label: "Tất cả năm" });
    const currentDate = new Date();
    for (
      let i = currentDate.getFullYear();
      i >= currentDate.getFullYear() - 10;
      i--
    ) {
      initialYears.push({ value: i, label: i });
    }

    this.setState({
      monthOptions: initialMonths,
      yearOptions: initialYears,
    });
  }

  loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };

  formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  changeTab = (key) => {
    this.setState({ tabkey: key, checkload: true });
    this.getIngredentsData(key);
    this.setState({ ingredentTextSearch: "" });
  };

  getlistinventory = (type, start, end) => {
    api
      .get(
        `store-history?start_at=${start / 1000}&term_at=${
          end / 1000
        }&ingredent_type=${type}`
      )
      .then((res) => {
        if (res.data.errCode === 0) {
          this.setState({
            listinventory: res.data.ReceiptDetails,
            checkload: false,
          });

          this.handleIngredentSearch(
            res.data.ReceiptDetails,
            this.state.ingredentTextSearch,
            this.state.selectedMonth,
            this.state.selectedYear
          );
        }
      })
      .catch((error) => {});
  };

  formatNumber(num) {
    if (num !== null && num !== "")
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  getDate = (data) => {
    const date = new Date(data * 1000);
    // date.setHours(0, 0, 0, 0);
    return (
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear() +
      " " +
      ("0" + date.getHours()).slice(-2) +
      ":" +
      ("0" + date.getMinutes()).slice(-2) +
      ":" +
      ("0" + date.getSeconds()).slice(-2)
    );
    // ${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getHours()}
    // let date = new Date(data * 1000);
    // return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };
  getDateNoTime = (data) => {
    const date = new Date(data * 1000);
    // date.setHours(0, 0, 0, 0);
    return (
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear()
    );
  };

  dataTable() {
    const columns = [
      {
        name: "Nguyên liệu",
        selector: (row) => row.ingredent_name,
        sortable: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap ">
            {row.ingredent_name}
          </div>
        ),
      },
      {
        name: "Loại",
        selector: (row) => row.type,
        sortable: true,
      },

      {
        name: "ĐVT",
        sortable: true,
        selector: (row) => row.unit_name,
      },
      {
        name: "Số lượng",
        sortable: true,
        selector: (row) => row.ing_quantity,
        right: true,
      },
      {
        name: "Giá tiền",
        sortable: true,
        selector: (row) => row.ing_price,
        right: true,
      },
      {
        name: "Ngày nhập",
        selector: (row) => row.rec_date,
        sortable: true,
        right: true,
        cell: (row) => this.getDateNoTime(row.rec_date),
      },
      {
        name: "Cập nhật",

        sortable: true,
        selector: (row) => this.getDate(row.created_at),
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap ">
            {this.getDate(row.created_at)}
          </div>
        ),
      },
      {
        name: "Nhập bởi",
        selector: (row) => row.updater,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap ">
            {row.updater}
          </div>
        ),
      },
    ];

    const data = [];

    const selectedRows = (items) => {
      this.setState({ selectedRows: items, alertShow: true });
    };

    if (this.state.ingredents_search.length > 0) {
      this.state.ingredents_search.map((item, i) => {
        data.push({
          id: item.id,
          ingredent_name: (
            <div style={{ width: "100%" }} className="text-wrap">
              {item.ingredent_name}
            </div>
          ),
          type: item.is_type === 1 ? "Nhập" : "Xuất",

          unit_name: item.unit_name,
          ing_quantity: this.formatter.format(item.ing_quantity),
          ing_price: this.formatNumber(item.ing_price),
          rec_date: item.rec_date,
          created_at: item.updated_at,

          updater: (
            <div style={{ width: "100%" }} className="text-wrap">
              {item.updater}
            </div>
          ),
        });

        return item;
      });
    }

    // const filteredItems = data.filter((item) => {

    //   if (
    //     (new FormatData().toSlug(item.student_name)).includes(new FormatData().toSlug(this.state.filterText.trim()))

    //   ) {

    //     return item;

    //   }
    //   return false
    // }

    // );

    return (
      <>
        <DataTables data={data} columns={columns} selectedRows={selectedRows} />
      </>
    );
  }
  renderTableIngedent = () => {
    return <>{this.dataTable()}</>;
  };

  RenderStoreList = () => {
    return (
      <>
        <Card.Header className="d-block d-md-flex">
          <Card.Title>
            <i className="fas fa-list me-1"></i> Lịch sử kho{" "}
            {this.getDateNoTime(this.state.startDate / 1000)} -{" "}
            {this.getDateNoTime(this.state.endDate / 1000)}
          </Card.Title>
          <div style={{ marginLeft: "10px" }}>
            <Button
              size="sm"
              onClick={() => {
                this.setState({ show: true });
              }}
            >
              <i className="fa-solid fa-calendar-days"> </i>{" "}
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          {this.RenderFilterDate()}

          <Tabs
            activeKey={this.state.tabkey}
            onSelect={(k) => {
              // settabkey(k);
              this.setState({ tabkey: k, checkload: true });
              this.getlistinventory(
                k,
                this.state.startDate,
                this.state.endDate
              );
              this.setState({ ingredentTextSearch: "" });
            }}
          >
            {/* <Tab key={1} title="Chợ" eventKey={1}>
              {this.state.checkload
                ? this.loading()
                : this.renderTableIngedent()}
            </Tab> */}
            <Tab key={2} title="Kho" eventKey={2}>
              {this.state.checkload
                ? this.loading()
                : this.renderTableIngedent()}
            </Tab>
            <Tab key={3} title="Vật tư" eventKey={3}>
              {this.state.checkload
                ? this.loading()
                : this.renderTableIngedent()}
            </Tab>
          </Tabs>
        </Card.Body>
      </>
    );
  };

  renderSelectedRows() {
    let num = this.state.selectedRows.length;
    if (num > 0 && this.state.alertShow === true) {
      return (
        <Alert
          variant="info mb-0 py-2"
          onClose={() => this.setState({ alertShow: false })}
          dismissible
        >
          {num} mục đã chọn.
        </Alert>
      );
    }
  }

  toSlug(str) {
    str = str.toLowerCase(); // Chuyển hết sang chữ thường
    // xóa dấu
    str = str
      .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
      .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp
    str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ
    str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt
    return str;
  }

  handleIngredentSearch(root, text, month, year) {
    let root_ = root;
    this.setState({
      ingredentTextSearch: text,
    });

    if (text !== "") {
      root_ = this.state.listinventory.filter((e) =>
        this.toSlug(e.ingredent_name).includes(this.toSlug(text.trim()))
      );
    }

    if (month.value !== 0) {
      root_ = root_.filter(
        (e) =>
          new Date(e.created_at * 1000).getMonth() + 1 === Number(month.value)
      );
    }

    if (year.value !== 0) {
      root_ = root_.filter(
        (e) =>
          new Date(e.created_at * 1000).getFullYear() === Number(year.value)
      );
    }

    this.setState({
      ingredents_search: root_,
    });
  }

  RenderFilterDate() {
    return (
      <div className="d-flex mb-2">
        <Col lg={3} md={4}>
          <Form.Control
            style={{ height: 39 }}
            type="text"
            placeholder="Tìm nguyên liệu"
            value={this.state.ingredentTextSearch}
            onChange={(e) => {
              this.handleIngredentSearch(
                this.state.listinventory,
                e.target.value,
                this.state.selectedMonth,
                this.state.selectedYear
              );
            }}
          />
        </Col>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Container fluid>
          <Breadcrumb className="mb-2 mt-2">
            <Breadcrumb.Item active>Kho hàng</Breadcrumb.Item>
            <Breadcrumb.Item active>Lịch sử</Breadcrumb.Item>
          </Breadcrumb>
          <Card>{this.RenderStoreList()}</Card>

          <Modal
            show={this.state.show}
            onHide={() => this.setState({ show: false })}
            size={"sm"}
          >
            <Modal.Header closeButton>
              <Modal.Title>Chọn thời gian</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <center>
                <DatePicker
                  size={{ height: "600px" }}
                  onChange={(update) => {
                    this.setState({
                      startDate: update[0],
                      endDate: update[1],
                    });
                  }}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  calendarStartDay={1}
                  selectsRange
                  inline
                />
              </center>
              <br />
            </Modal.Body>
            <Modal.Footer>
              <Button
                size="sm"
                variant="secondary"
                onClick={() => {
                  this.setState({ show: false });
                }}
                disabled={
                  this.state.startDate === null || this.state.endDate === null
                }
              >
                <i className="fas fa-times" /> Đóng
              </Button>
              <Button
                size="sm"
                disabled={
                  this.state.startDate === null || this.state.endDate === null
                }
                onClick={() => {
                  const start = this.state.startDate;
                  start.setHours(0, 0, 0, 0);

                  const end = this.state.endDate;
                  end.setHours(23, 59, 59, 0);

                  this.setState({
                    show: false,
                    startDate: start,
                    endDate: end,
                  });
                  this.getlistinventory(this.state.tabkey, start, end);
                }}
              >
                <i className="fas fa-check" /> Lưu
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </div>
    );
  }
}

export default Storeinventory;
