import React from "react";
import { Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { groupArrayByField, priceFormatter } from "../../../utils/helpers";
import { dateFormatter } from "../../../utils/helpers";

const NutritionResult = ({ quantitative, selectedClassGroup }) => {
  if (!quantitative) {
    return "";
  }

  // Get init data
  const currentCostPerDays = quantitative.costPerDays?.find(
    (costPerDay) => costPerDay.id_group === selectedClassGroup.id
  );

  const numberChildren = currentCostPerDays?.meal_quantity || 0;

  // Nutrition variables
  let energyStat = 0;
  let proteinStat = 0;
  let lipidStat = 0;
  let glucidStat = 0;

  let animalProteinStat = 0;
  let animalLipidStat = 0;

  // Change data structure of quantitative to ingredient array
  let ingredientArray = [];

  quantitative.times?.map((timeItem) => {
    return timeItem.foods.map((foodItem) => {
      foodItem.groups
        .find(
          (groupItem) =>
            groupItem.id === selectedClassGroup.id &&
            groupItem.more_meal === null
        )
        ?.ingredents?.map((ingredientItem) => {
          ingredientArray.push(ingredientItem);

          const gramEatable = Number(
            ((ingredientItem.ing_quantity * ingredientItem.volume_market) /
              ingredientItem.volume_cook) *
              ((100 - ingredientItem.ingredent_ratio) / 100) *
              numberChildren
          );

          if (ingredientItem.id_subspecies === 1) {
            animalProteinStat +=
              (gramEatable * Number(ingredientItem.ingredent_protein)) / 100;
            animalLipidStat +=
              (gramEatable * Number(ingredientItem.ingredent_lipid)) / 100;
          }

          energyStat +=
            (gramEatable * Number(ingredientItem.ingredent_energy)) / 100;
          proteinStat +=
            (gramEatable * Number(ingredientItem.ingredent_protein)) / 100;
          lipidStat +=
            (gramEatable * Number(ingredientItem.ingredent_lipid)) / 100;
          glucidStat +=
            (gramEatable * Number(ingredientItem.ingredent_glucid)) / 100;

          return ingredientItem;
        });

      return foodItem;
    });
  });

  const groupIngredientArray = groupArrayByField(ingredientArray, "id").sort(
    (a, b) => {
      let amountA = a.reduce((accumulator, currentValue) => {
        return Number(
          accumulator +
            (currentValue.ing_quantity * currentValue.volume_market) /
              currentValue.volume_cook
        );
      }, 0);

      let amountB = b.reduce((accumulator, currentValue) => {
        return Number(
          accumulator +
            (currentValue.ing_quantity * currentValue.volume_market) /
              currentValue.volume_cook
        );
      }, 0);

      return a[0].ingredent_type - b[0].ingredent_type || amountB - amountA;
    }
  );

  const plgTotal = proteinStat * 4 + lipidStat * 9 + glucidStat * 4;

  return (
    <Table bordered responsive id="nutrition-result-table">
      <thead>
        <tr>
          <td colSpan={5}></td>
          <td className="text-end align-top">{`${
            quantitative.day_name
          }, ngày ${dateFormatter(
            new Date(quantitative.menu_date * 1000)
          )}`}</td>
          <td colSpan={4}></td>
        </tr>
        <tr className="text-center align-middle">
          <th>KẾT QUẢ DƯỠNG CHẤT</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
        </tr>
        <tr>
          <td>
            Độ tuổi: {currentCostPerDays?.group_name}
            <br />
            Tổng số trẻ: {numberChildren}
          </td>
          <td>empty</td>
          <td>empty</td>
          <td>empty</td>
          <td>empty</td>
          {/* <td>
						{quantitative.times?.map((timeItem) => {
							return (
								<div key={timeItem.id}>
									Ăn {timeItem.time_name}:
									<br />
									{timeItem.foods.map((foodItem) => {
										return (
											<div key={foodItem.id}>
												- {foodItem.food_name}
												<br />
											</div>
										);
									})}
								</div>
							);
						})}
					</td> */}
          <td>
            {quantitative.times?.map((timeItem, timeIndex) => {
              const foods =
                timeItem.foods.filter((food) => {
                  return food.groups.some(
                    (group) => group.id === selectedClassGroup.id
                  );
                }) || [];

              return (
                <div key={timeItem.id}>
                  Ăn {timeItem.time_name}:
                  <br />
                  {foods.length > 0 ? (
                    foods.map((foodItem, foodIndex) => {
                      return (
                        <div key={`food_${timeIndex}_ ${foodIndex}`}>
                          - {foodItem.food_name}
                          <br />
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
          </td>
          <td>empty</td>
          <td>empty</td>
          <td>empty</td>
          <td>empty</td>
        </tr>
        <tr className="text-center align-middle">
          <th>STT</th>
          <th>Tên thực phẩm</th>
          <th>Năng lượng (kcal)</th>
          <th>Đạm (g)</th>
          <th>empty</th>
          <th>Béo (g)</th>
          <th>empty</th>
          <th>Bột đường (g)</th>
          <th>Ăn được (g)</th>
          <th>Đi chợ (g)</th>
        </tr>
        <tr className="text-center align-middle">
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>

          <th>ĐV</th>
          <th>TV</th>
          <th>ĐV</th>
          <th>TV</th>

          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
        </tr>
      </thead>
      <tbody>
        {groupIngredientArray.map((groupIngredientItem, ingredientIndex) => {
          // Gram
          let gramEatable = 0;
          let gramMarket = 0;

          // Nutrition
          let energy = 0;
          let animalProtein = 0;
          let plantProtein = 0;
          let animalLipid = 0;
          let plantLipid = 0;
          let glucid = 0;

          groupIngredientItem.map((ingredientItem) => {
            gramEatable += Number(
              ((ingredientItem.ing_quantity *
                numberChildren *
                ingredientItem.volume_market) /
                ingredientItem.volume_cook) *
                ((100 - ingredientItem.ingredent_ratio) / 100)
            );
            gramMarket += Number(
              (ingredientItem.ing_quantity *
                numberChildren *
                ingredientItem.volume_market) /
                ingredientItem.volume_cook
            );
            return ingredientItem;
          });

          gramEatable = Number(gramEatable).toFixed(2);
          gramMarket = Number(gramMarket).toFixed(2);

          if (groupIngredientItem[0].id_subspecies === 1) {
            animalProtein +=
              (gramEatable * Number(groupIngredientItem[0].ingredent_protein)) /
              100;
            animalLipid +=
              (gramEatable * Number(groupIngredientItem[0].ingredent_lipid)) /
              100;
          } else {
            plantProtein =
              (gramEatable * Number(groupIngredientItem[0].ingredent_protein)) /
              100;
            plantLipid =
              (gramEatable * Number(groupIngredientItem[0].ingredent_lipid)) /
              100;
          }

          energy +=
            (gramEatable * Number(groupIngredientItem[0].ingredent_energy)) /
            100;
          glucid +=
            (gramEatable * Number(groupIngredientItem[0].ingredent_glucid)) /
            100;

          return (
            <tr
              key={groupIngredientItem[0].id}
              className="text-end align-middle"
            >
              <td className="text-center">{ingredientIndex + 1}</td>
              <td className="text-start">
                {groupIngredientItem[0].ingredent_name}
              </td>
              <td>{Number(energy).toFixed(2)}</td>
              <td>
                {animalProtein !== 0
                  ? Number(animalProtein).toFixed(2)
                  : "empty"}
              </td>
              <td>
                {plantProtein !== 0 ? Number(plantProtein).toFixed(2) : "empty"}
              </td>
              <td>
                {animalLipid !== 0 ? Number(animalLipid).toFixed(2) : "empty"}
              </td>
              <td>
                {plantLipid !== 0 ? Number(plantLipid).toFixed(2) : "empty"}
              </td>
              <td>{Number(glucid).toFixed(2)} </td>
              <td>{Number(gramEatable).toFixed(2)} </td>
              <td>{Number(gramMarket).toFixed(2)} </td>
            </tr>
          );
        })}

        <tr className="text-center align-middle">
          <th>Động vật/Thực vật</th>
          <th>empty</th>
          <th>empty</th>

          <th>
            {proteinStat !== 0
              ? priceFormatter.format((animalProteinStat * 100) / proteinStat)
              : ""}
          </th>
          <th>
            {proteinStat !== 0
              ? priceFormatter.format(
                  100 - (animalProteinStat * 100) / proteinStat
                )
              : ""}
          </th>
          <th>
            {lipidStat !== 0
              ? priceFormatter.format((animalLipidStat * 100) / lipidStat)
              : ""}
          </th>
          <th>
            {lipidStat !== 0
              ? priceFormatter.format(100 - (animalLipidStat * 100) / lipidStat)
              : ""}
          </th>

          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
        </tr>

        <tr className="text-center align-middle">
          <th>Tổng kết quả</th>
          <th>empty</th>

          <th>{priceFormatter.format(energyStat)}</th>

          <th>{priceFormatter.format(proteinStat)}</th>
          <th>empty</th>

          <th>{priceFormatter.format(lipidStat)}</th>
          <th>empty</th>

          <th>{priceFormatter.format(glucidStat)}</th>

          <th></th>
          <th>empty</th>
        </tr>

        <tr className="text-center align-middle">
          <th>Tổng yêu cầu</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
        </tr>

        <tr className="text-center align-middle">
          <th>Định mức trung bình cho một trẻ trong ngày</th>
          <th>empty</th>

          <th>
            {numberChildren !== 0
              ? priceFormatter.format(energyStat / numberChildren)
              : "empty"}
          </th>
          <th>
            {numberChildren !== 0
              ? priceFormatter.format(proteinStat / numberChildren)
              : "empty"}
          </th>
          <th>empty</th>

          <th>
            {numberChildren !== 0
              ? priceFormatter.format(lipidStat / numberChildren)
              : "empty"}
          </th>
          <th>empty</th>

          <th>
            {numberChildren !== 0
              ? priceFormatter.format(glucidStat / numberChildren)
              : "empty"}
          </th>
          <th>empty</th>
          <th>empty</th>
        </tr>

        <tr className="text-center align-middle">
          <th>Tỉ lệ đạt trong ngày</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
        </tr>

        <tr className="text-center align-middle">
          <th>Tỉ lệ đạt ở trường</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
        </tr>

        <tr className="text-center align-middle">
          <th>P : L : G</th>
          <th>empty</th>
          <th>empty</th>
          <th>
            {plgTotal !== 0
              ? priceFormatter.format((proteinStat * 100 * 4) / plgTotal)
              : "empty"}
          </th>
          <th>empty</th>
          <th>
            {plgTotal !== 0
              ? priceFormatter.format((lipidStat * 100 * 9) / plgTotal)
              : "empty"}
          </th>
          <th>empty</th>
          <th>
            {plgTotal !== 0
              ? priceFormatter.format(
                  100 -
                    (Number(
                      priceFormatter.format((proteinStat * 100 * 4) / plgTotal)
                    ) +
                      Number(
                        priceFormatter.format((lipidStat * 100 * 9) / plgTotal)
                      ))
                )
              : "empty"}
          </th>
          <th>empty</th>
          <th>empty</th>
        </tr>
      </tbody>
    </Table>
  );
};

export default NutritionResult;
