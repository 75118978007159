import React, { Component } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Container,
  Form,
  Table,
  Row,
  Col,
  InputGroup,
} from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import swal from "sweetalert";
import DataTables from "../datatable/DataTables";
import StudentCreate from "./StudentCreate";
import StudentEdit from "./StudentEdit";
import { useNavigate, useParams } from "react-router-dom";
import * as XLSX from "xlsx-js-style";
import Check from "../other/Check";
import { toastErrorMessage } from "../utils/helpers";
import { dateFormatter } from "../utils/helpers";
import ParentAccountsCreate from "../parentAccount/ParentAccountsCreate";
import ChangeClassModal from "./ChangeClassModal";
import Loading from "../other/LoadingSpinner";
import { OPTION_SELETE_MONTH } from "../../../constants";
import { convertDate } from "../../../helper/times";
import AddStudentsToClassModal from "./AddStudentsToClassModal";
import ChildGrowth from "./ChildGrowth";
import api from "../../../helper/axiosInstance";

class StudentIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      yearId: "",
      schoolYears: [],
      student: [],
      students: [],
      idYearNow: "",
      editModalShow: "",
      isTableSelectable: false,
      isCreateParents: false,
      isAddStudentsToClass: false,
      addStudentsToClassModalShow: false,
      createParentAccountModalShow: false,
      selectedStudents: [],
      changeClassShow: false,
      classrooms: [],
      idStudentChangeClass: {},
      studentListFile: null,
      dataExport: [],
      choiceClass: { value: "", label: "Tất cả lớp" },
      classSelect: [],
      filterText: "",
      filterMonthOfBirth: { value: "", label: "Tháng sinh" },
      dataSearch: [],
      ingredents: [],
      noIngredent: [],
      loadingStudents: false,
      medication: [],
      optionSchoolYears: [{ value: "", label: "Tất cả năm" }],
      showChildGrowth: false,
      studentChoice: {},
    };
  }

  async componentDidMount() {
    document.title = "Danh sách học sinh";
    this.getIngrdent();
    await this.getSchoolYears();
  }

  changeEmpty = (element) => {
    return element == "" ||
      element == null ||
      element == "null" ||
      element == undefined ||
      element == "undefined" ||
      element == "aN/aN/NaN"
      ? "empty"
      : element;
  };

  getIngrdent = () => {
    api.get(`ingredent-show?ingredent_type=1,2`).then((res) => {
      if (res.data.errCode == 0) {
        this.setState({
          ingredents: res.data.ingredents.map((ingredent) => {
            return {
              value: ingredent.id,
              label: ingredent.ingredent_name,
            };
          }),
        });
      }
    });
  };

  getSchoolYears = async () => {
    await api
      .get("/school-year-shows")
      .then((res) => {
        if (res.status === 200) {
          const schoolYears = res.data.data?.schoolYears || [];
          const currentTime = Math.floor(Date.now() / 1000);

          const filteredData = schoolYears.filter(
            (item) =>
              item.start_date <= currentTime && item.end_date >= currentTime
          );

          const yearId = filteredData.length > 0 ? filteredData[0].id : "";

          this.setState({
            schoolYears: schoolYears,
            optionSchoolYears: [
              { value: "", label: "Tất cả năm" },
              ...schoolYears?.map((item) => ({
                value: item.id,
                label: item.year_name,
              })),
            ],
            yearId: yearId,
            idYearNow: yearId,
          });

          this.getClassRoom(yearId);
          this.getStudents(yearId);
        }
      })
      .catch((err) => {
        toastErrorMessage(err);
      });
  };

  getDate = (data) => {
    const date = new Date(data * 1000);
    date.setHours(0, 0, 0, 0);
    return (
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear()
    );
  };

  handleExport = () => {
    swal({
      title: `Xác nhận`,
      text: `Xuất danh sách học sinh   `,
      icon: "info",
      buttons: ["Đóng", "Xuất"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        this.export();
      }
    });
  };

  export = () => {
    const wb = XLSX.utils.book_new();
    this.state.classrooms?.map((item, index) => {
      if (item != null)
        XLSX.utils.book_append_sheet(
          wb,
          this.book1(item.class_name),
          item.class_name
        );
      return item;
    });

    XLSX.writeFile(wb, "Danh sách học sinh.xlsx");
  };
  book1 = (class_name) => {
    if (class_name !== "") {
      const table1 = document.getElementById(`ds-${class_name}`);
      var ws1 = XLSX.utils.table_to_sheet(table1, { raw: true });

      ws1["!cols"] = [
        { wch: 5 },
        { wch: 25 },
        { wch: 25 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 11 },
        { wch: 25 },
        { wch: 15 },
        { wch: 10 },
        { wch: 10 },
        { wch: 25 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 25 },
        { wch: 15 },
        { wch: 11 },
        { wch: 25 },
        { wch: 15 },
        { wch: 11 },
        { wch: 15 },
        { wch: 25 },
      ];

      const row = [];
      ws1[`!merges`] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 25 } }];
      ws1["!merges"].push({ s: { r: 1, c: 0 }, e: { r: 1, c: 25 } });

      const colAlpha = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      ws1["A1"].s = {
        font: { name: "Times New Roman", bold: true },
        alignment: { horizontal: "center" },
      };
      ws1["A2"].s = {
        font: { name: "Times New Roman", bold: true },
        alignment: { horizontal: "center" },
      };
      for (var i = 0; i < colAlpha.length; i++) {
        ws1[`${colAlpha[i]}3`].s = {
          font: { name: "Times New Roman", bold: true },
          alignment: { horizontal: "center" },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        };
      }

      const borderStyle = {
        font: { name: "Times New Roman" },
        alignment: { wrapText: true },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };
      const range = XLSX.utils.decode_range(ws1["!ref"]);

      const startRow = 3; // Dòng bắt đầu từ A3
      const endRow = range.e.r; // Dòng kết thúc
      // XLSX.utils.sheet_set_range_style(ws1, range, borderStyle);
      for (let row = startRow; row <= endRow; row++) {
        for (let col = range.s.c; col <= range.e.c; col++) {
          const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
          const cell = ws1[cellAddress];
          if (cell.s == undefined) cell.s = {};

          cell.s = { ...cell.s, ...borderStyle };
          if (cell.v === "empty") {
            cell.v = "";
          }
        }
      }

      ws1["!rows"] = row;
      return ws1;
    }
  };

  getStudents = async (yearId) => {
    this.setState({ loadingStudents: true });
    await api
      .get(`/student-shows?id_year=${yearId == "--Tất cả--" ? "" : yearId}`)
      .then((res) => {
        if (res.status === 200) {
          this.setState(
            {
              students: res.data.data.students,
            },
            () => {
              this.filterStudent(
                this.state.choiceClass?.value,
                this.state.filterText
              );
            }
          );

          this.setState({
            dataExport: this.state.classrooms?.map((item) => {
              return {
                id_class: item.id,
                class_name: item.class_name,
                user_name: item.teacher_name,
                group_name: item.group_name,
                students: res.data.data.students.filter(
                  (e) => e.id_class == item.id
                ),
              };
            }),
            loadingStudents: false,
          });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
        this.setState({ loadingStudents: false });
      });
  };

  getClassRoom = (yearId) => {
    api
      .get(`/classroom-shows?id_year=${yearId}`)
      .then((res) => {
        this.setState({ classrooms: res.data.data?.classrooms });
        let arr = [];
        arr.push({ value: "", label: "Tất cả lớp" });
        res.data.data?.classrooms?.map((item) => {
          arr.push({ value: item.id, label: item.class_name });
          return item;
        });
        this.setState({ classSelect: arr });
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  getStudent = (id) => {
    api
      .get(`/student-show?id=${id}`)
      .then((res) => {
        if (res.status === 200) {
          this.setState({ student: res.data.data });

          this.getIngredent(id);
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  getIngredent = (id) => {
    api
      .get(`/not-eat-ingredient-shows?id_student=${id}`)
      .then((res) => {
        if (res.status === 200) {
          this.setState({ noIngredent: res.data.data });
          this.setState({
            editModalShow: true,
          });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  deleteStudent = (id) => {
    swal({
      title: "Bạn muốn xóa học sinh?",
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        api
          .delete(`/student-destroy?id=${id}`)
          .then((res) => {
            if (res.status === 200) {
              toast(res.data.messages, { type: "success" });
              this.getStudents(this.state.yearId);
            }
          })
          .catch((err) => {
            toast(err.message, { type: "error" });
          });
      }
    });
  };

  onHideModal = () => {
    this.setState({
      addModalShow: false,
      editModalShow: false,
    });
  };

  onSaveModal = () => {
    this.setState({
      addModalShow: false,
      editModalShow: false,
      changeClassShow: false,
    });

    this.getStudents(this.state.yearId);
  };

  onSaveClass = () => {
    this.getClassRoom(this.state.yearId);
  };

  onHide = () => {
    this.setState({
      createParentAccountModalShow: false,
      addStudentsToClassModalShow: false,
      isTableSelectable: false,
      selectedStudents: [],
      isCreateParents: false,
      isAddStudentsToClass: false,
    });
  };

  dataTable() {
    const today = Math.floor(Date.now() / 1000);

    const columns = [
      {
        name: "Lớp",
        selector: (row) => row.class_name,
        sortable: true,
        wrap: true,
        style: {
          div: {
            width: "100px",
          },
        },
      },
      {
        name: "Họ và tên",
        selector: (row) => row.student_name,
        sortable: true,
        style: {
          div: {
            overflow: "inherit !important ",
            width: "200px",
          },
        },
        wrap: true,
      },

      {
        name: "Giới tính",
        selector: (row) => row.gender_name,
        sortable: true,
        center: true,
      },
      {
        name: "Ngày sinh",
        selector: (row) => row.student_birthday,
        sortable: true,
        right: true,
        cell: (row) => dateFormatter(new Date(row.student_birthday * 1000)),
      },
      {
        name: "Trạng thái",
        selector: (row) => row.student_status,
        sortable: true,
        center: true,
        cell: (row) =>
          row.student_status == 1 ? (
            today < row.start_at ? (
              <span className="text-primary">Sắp vào</span>
            ) : (
              <span className="text-success">Đang học</span>
            )
          ) : (
            <span>
              <span className="text-danger">Tạm nghỉ</span>
              <br />
              <span>
                {`(${dateFormatter(new Date((row.end_at || 0) * 1000))})`}
              </span>
            </span>
          ),
      },
      {
        name: "Phương thức",
        selector: (row) => row.setting,
        center: true,
      },
    ];

    const data = [];
    this.state.dataSearch?.map((item) => {
      data.push({
        ...item,
        setting: (
          <div div style={{ display: "flex" }}>
            {/* tạm ẩn mở lại sau khi test */}
            {/* <Button
              variant="outline-danger me-2"
              size="sm"
              type="button"
              onClick={() => {
                // this.getStudent(item.id);
                this.setState({ showChildGrowth: true, studentChoice: item });
              }}
              title="Sổ sức khỏe"
            >
              <i className="fa-solid fa-heart-pulse"></i>
            </Button> */}
            {new Check().permission(["17", "18", "20"], "or") ? (
              <Button
                variant="warning me-2"
                size="sm"
                type="button"
                onClick={() => {
                  this.getStudent(item.id);
                }}
                title="Chi tiết học sinh"
              >
                <i className="fa-solid fa-pencil"></i>
              </Button>
            ) : null}

            {new Check().permission(["21"]) ? (
              <Button
                variant="danger"
                type="button"
                size="sm"
                onClick={() => {
                  this.deleteStudent(item.id);
                }}
                title="Xóa"
              >
                <i className="fas fa-trash"></i>
              </Button>
            ) : null}
          </div>
        ),
      });

      return item;
    });
    const selectedRows = (items) => {
      this.setState({ selectedRows: items, alertShow: true });
    };

    const onSelectedRowsChange = (rows) => {
      this.setState({ selectedStudents: rows });
    };

    return (
      <DataTables
        data={data}
        columns={columns}
        selectedRows={selectedRows}
        selectableRows={this.state.isTableSelectable}
        onSelectedRowsChange={(item) => onSelectedRowsChange(item.selectedRows)}
        clearSelectedRows={this.state.isTableSelectable}
      />
    );
  }

  toSlug(str) {
    if (str !== "undefined") {
      str = str.toLowerCase(); // Chuyển hết sang chữ thường
      str = str
        .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
        .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp
      str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ
      str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt
    }

    return str;
  }

  handleSelectFile = (e) => {
    const fileExtention = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    if (!fileExtention.some((ex) => ex === e.target.files[0]?.type)) {
      toast("Sai định dạng tệp Excel !!!", {
        type: "error",
        autoClose: 3000,
      });
      return;
    }

    this.setState({
      studentListFile: e.target.files[0],
    });
  };
  handleImportStudentListExcel = () => {
    var bodyFormData = new FormData();
    bodyFormData.append("file_import", this.state.studentListFile);
    bodyFormData.append("id_year", this.state.yearId);

    this.setState({
      isAddStudentListLoading: true,
    });

    api
      .post(`/student-import-excel`, bodyFormData)
      .then((res) => {
        toast("Lưu thành công", {
          type: "success",
          autoClose: 2000,
        });
        this.getStudents(this.state.yearId);
        this.setState({
          isAddStudentListLoading: false,
          studentListFile: null,
        });

        document.getElementById("studentlistFile").value = "";
      })
      .catch((error) => {
        document.getElementById("studentlistFile").value = "";
        this.setState({
          isAddStudentListLoading: false,
          studentListFile: null,
        });

        toast(error.response.data.messages || "Xảy ra lỗi", {
          type: "error",
          autoClose: 2000,
        });
      });
  };

  filterStudent = (idclass, student_name, monthBirth = "") => {
    const filteredStudents = this.state.students?.filter(
      (student) =>
        (idclass == 0 || student.id_class == idclass) &&
        this.toSlug(student?.student_name)?.includes(
          this.toSlug(student_name?.trim())
        ) &&
        (monthBirth == "" ||
          parseInt(convertDate(student.student_birthday).month) == monthBirth)
    );

    this.setState({ dataSearch: filteredStudents });
  };

  renderStudentList() {
    return (
      <>
        <Card.Header>
          <div className="d-block d-xl-flex justify-content-between">
            <Card.Title>
              <i className="fas fa-list me-1"></i> Danh sách học sinh
            </Card.Title>

            {this.state.isTableSelectable ? (
              <div className="d-block d-md-flex my-xl-0 align-items-center">
                <Button
                  type="button"
                  variant="secondary"
                  size="sm"
                  className="me-2"
                  onClick={() =>
                    this.setState({
                      isTableSelectable: false,
                      isCreateParents: false,
                      isAddStudentsToClass: false,
                    })
                  }
                >
                  <i className="fa-solid fa-times"></i> Hủy
                </Button>

                <Button
                  type="button"
                  variant="primary"
                  size="sm"
                  className="me-2"
                  onClick={() =>
                    this.setState({
                      createParentAccountModalShow: this.state.isCreateParents,
                      addStudentsToClassModalShow:
                        this.state.isAddStudentsToClass,
                    })
                  }
                  disabled={!(this.state.selectedStudents?.length > 0)}
                >
                  <i className="fa-solid fa-check"></i> Tạo
                </Button>
              </div>
            ) : (
              <div className="d-block d-md-flex my-xl-0 align-items-center">
                {new Check().permission(["18"]) ? (
                  <Button
                    type="button"
                    variant="success"
                    size="sm"
                    className="me-2 mb-2 mb-md-0"
                    href="/File mẫu DSHS.xlsx"
                    target="_blank"
                    download
                  >
                    <i className="fa-solid fa-download"></i> Tải file mẫu
                  </Button>
                ) : (
                  <></>
                )}

                {new Check().permission(["19"]) ? (
                  <>
                    <label
                      className="btn btn-sm btn-primary me-2 mb-2 mb-md-0"
                      style={{ cursor: "pointer" }}
                      htmlFor="studentlistFile"
                      hidden={this.state.yearId == ""}
                    >
                      <i className="fa-solid fa-file-import"></i> Nhập DSHS
                    </label>

                    <input
                      size="sm"
                      type="file"
                      id="studentlistFile"
                      className="d-none "
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      multiple={false}
                      onChange={(e) => this.handleSelectFile(e)}
                      disabled={this.state.isAddStudentListLoading}
                    />
                  </>
                ) : (
                  <></>
                )}

                <Button
                  type="button"
                  variant="success "
                  size="sm"
                  className="me-2 mb-2 mb-md-0"
                  onClick={() => this.handleExport()}
                >
                  <i className="fa-solid fa-file-export"></i> Xuất DSHS
                </Button>

                {this.state.studentListFile ? (
                  this.state.isAddStudentListLoading ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="me-3">
                        <div>Đang thêm mới học sinh...</div>
                      </div>
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex mt-3 mt-md-0">
                      <div
                        style={{ minWidth: 100 }}
                        className="d-flex align-items-center text-truncate me-3"
                      >
                        Đã chọn: {this.state.studentListFile.name}
                      </div>

                      <Button
                        type="button"
                        variant="success"
                        size="sm"
                        className="me-2 mb-2 mb-md-0"
                        onClick={() => this.handleImportStudentListExcel()}
                        disabled={this.state.isAddStudentListLoading}
                      >
                        Lưu
                      </Button>

                      <Button
                        type="button"
                        variant="secondary"
                        size="sm"
                        className="me-2 mb-2 mb-md-0"
                        onClick={() => {
                          document.getElementById("studentlistFile").value = "";
                          this.setState({
                            studentListFile: null,
                            isAddStudentListLoading: false,
                          });
                        }}
                      >
                        Hủy
                      </Button>
                    </div>
                  )
                ) : (
                  <>
                    {new Check().permission(["125"]) ? (
                      <Button
                        type="button"
                        variant="success"
                        size="sm"
                        className="me-2 mb-2 mb-md-0"
                        onClick={() =>
                          this.setState({
                            isTableSelectable: true,
                            isCreateParents: true,
                          })
                        }
                      >
                        <i className="fa-solid fa-user-shield" /> Tạo TK phụ
                        huynh
                      </Button>
                    ) : (
                      <></>
                    )}

                    {new Check().permission([20]) ? (
                      <Button
                        type="button"
                        variant="primary"
                        size="sm"
                        className="me-2 mb-2 mb-md-0"
                        onClick={() =>
                          this.setState({
                            isTableSelectable: true,
                            isAddStudentsToClass: true,
                          })
                        }
                      >
                        <i className="fa-solid fa-person-walking-luggage" />{" "}
                        Chuyển lớp
                      </Button>
                    ) : (
                      <></>
                    )}

                    {new Check().permission(["19"]) ? (
                      <Button
                        type="button"
                        variant="success"
                        size="sm"
                        className="mb-2 mb-md-0"
                        onClick={() => this.setState({ addModalShow: true })}
                        title="Thêm học sinh"
                      >
                        <i className="fa-solid fa-plus"></i> Thêm
                      </Button>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </Card.Header>

        <Card.Body>
          <Row style={{ justifyContent: "flex-start" }}>
            <Col md={2} xs={6} style={{ zIndex: 3 }} className="mb-2">
              <Select
                options={this.state.optionSchoolYears}
                value={this.state.optionSchoolYears.find(
                  (option) => option.value === this.state.yearId
                )}
                onChange={(choice) => {
                  const yearId = choice?.value;
                  this.setState(
                    {
                      yearId: yearId,
                      choiceClass: { value: "", label: "Tất cả lớp" },
                    },
                    () => {
                      this.getClassRoom(yearId);
                      this.getStudents(yearId);
                    }
                  );
                }}
              />
            </Col>

            <Col md={2} xs={6} style={{ zIndex: 3 }} className="mb-2">
              <Select
                options={this.state.classSelect}
                value={this.state.choiceClass}
                onChange={(choice) => {
                  this.setState({ choiceClass: choice });
                  this.filterStudent(
                    choice?.value,
                    this.state.filterText,
                    this.state.filterMonthOfBirth?.value
                  );
                }}
              ></Select>
            </Col>

            <Col md={3} xs={6} className="mb-2">
              <InputGroup style={{ height: "38px" }}>
                <Form.Control
                  onChange={(e) => {
                    this.setState({ filterText: e.target.value });

                    this.filterStudent(
                      this.state.choiceClass?.value,
                      e.target.value,
                      this.state.filterMonthOfBirth?.value
                    );
                  }}
                  value={this.state.filterText}
                  placeholder="Nhập tên học sinh"
                />
              </InputGroup>
            </Col>

            <Col md={2} xs={6} style={{ zIndex: 3 }} className="mb-2">
              <Select
                options={OPTION_SELETE_MONTH}
                value={this.state.filterMonthOfBirth}
                onChange={(choice) => {
                  this.setState({
                    filterMonthOfBirth: choice,
                  });
                  this.filterStudent(
                    this.state.choiceClass?.value,
                    this.state.filterText,
                    choice.value
                  );
                }}
              ></Select>
            </Col>
          </Row>

          {this.state.loadingStudents ? <Loading /> : this.dataTable()}
        </Card.Body>
      </>
    );
  }

  onChangeClassModal = (studentData) => {
    return this.setState({
      changeClassShow: true,
      idStudentChangeClass: studentData,
    });
  };

  render() {
    return (
      <>
        <Container fluid>
          <Breadcrumb className="mb-2 mt-2">
            <Breadcrumb.Item active>Học sinh</Breadcrumb.Item>
            <Breadcrumb.Item active>Danh sách</Breadcrumb.Item>
          </Breadcrumb>
          <Card>
            {this.renderStudentList()}

            {this.state.dataExport?.map((student) => {
              return (
                <Table
                  bordered
                  id={`ds-${student.class_name}`}
                  className="d-none"
                >
                  <tr>
                    <th> Danh sách học sinh lớp {student.class_name}</th>
                  </tr>
                  <tr>
                    <th>Năm học: 2022 - 2023 </th>
                  </tr>

                  <tr>
                    <th className="align-middle text-center">STT</th>
                    <th className="align-middle text-center">
                      Mã định danh Bộ GD&ĐT
                    </th>

                    <th
                      className="align-middle text-center"
                      style={{ width: "50px" }}
                    >
                      Họ và tên
                    </th>
                    <th
                      className="align-middle text-center"
                      style={{ width: "80px" }}
                    >
                      Ngày sinh
                    </th>
                    <th className="align-middle text-center">Giới tính</th>
                    <th className="align-middle text-center"> Dân tộc</th>
                    <th className="align-middle text-center"> Ngày nhập học</th>
                    <th className="align-middle text-center"> Ngày nghỉ học</th>
                    <th className="align-middle text-center">SĐT liên hệ</th>
                    <th className="align-middle text-center"> Tên giáo viên</th>
                    <th className="align-middle text-center"> Nhóm học sinh</th>
                    <th className="align-middle text-center"> Số nhà</th>
                    <th className="align-middle text-center"> Tổ</th>
                    <th className="align-middle text-center"> Khu vực </th>
                    <th className="align-middle text-center">Đường </th>
                    <th className="align-middle text-center"> Phường</th>
                    <th className="align-middle text-center"> Quận</th>
                    <th className="align-middle text-center">Tỉnh, TP </th>
                    <th className="align-middle text-center">Họ và tên cha</th>
                    <th className="align-middle text-center">
                      Nghề nghiệp cha
                    </th>
                    <th className="align-middle text-center"> Điện thoại</th>
                    <th className="align-middle text-center">Họ và tên mẹ</th>
                    <th className="align-middle text-center">Nghề nghiệp mẹ</th>
                    <th className="align-middle text-center"> Điện thoại</th>
                    <th className="align-middle text-center"> Ghi chú</th>
                    <th className="align-middle text-center">
                      Mã định danh dân cư
                    </th>
                  </tr>
                  {student?.students?.map((item, index) => {
                    return (
                      <tr key={`student_${index}`}>
                        <td className="text-center">{index + 1}</td>
                        <td>{this.changeEmpty(item.student_identifier)}</td>
                        <td style={{ width: "250px" }}>{item.student_name}</td>
                        <td>{this.getDate(item.student_birthday)}</td>
                        <td>{this.changeEmpty(item.gender_name)}</td>
                        <td>{this.changeEmpty()}</td>
                        <td>
                          {this.changeEmpty(this.getDate(item?.start_at))}
                        </td>
                        <td>
                          {this.changeEmpty(
                            this.getDate(item?.end_at) == "null"
                              ? ""
                              : this.getDate(item?.end_at)
                          )}{" "}
                        </td>
                        <td>
                          {this.changeEmpty(
                            item.student_contact == "null"
                              ? ""
                              : item.student_contact
                          )}
                        </td>
                        <td>{this.changeEmpty(student.user_name)}</td>
                        <td>{this.changeEmpty(student.group_name)}</td>
                        <td>
                          {" "}
                          {this.changeEmpty(item.student_address?.number)}{" "}
                        </td>
                        <td>
                          {" "}
                          {this.changeEmpty(item.student_address?.group)}{" "}
                        </td>
                        <td>
                          {" "}
                          {this.changeEmpty(item.student_address?.zone)}{" "}
                        </td>
                        <td>
                          {" "}
                          {this.changeEmpty(item.student_address?.street)}{" "}
                        </td>
                        <td>
                          {" "}
                          {this.changeEmpty(item.student_address?.ward)}{" "}
                        </td>
                        <td>
                          {" "}
                          {this.changeEmpty(
                            item.student_address?.district
                          )}{" "}
                        </td>
                        <td>
                          {" "}
                          {this.changeEmpty(item.student_address?.city)}{" "}
                        </td>
                        <td> {this.changeEmpty(item.student_father?.name)} </td>
                        <td> {this.changeEmpty(item.student_father?.job)} </td>
                        <td>
                          {" "}
                          {this.changeEmpty(item.student_father?.phone)}{" "}
                        </td>
                        <td> {this.changeEmpty(item.student_mother?.name)} </td>
                        <td> {this.changeEmpty(item.student_mother?.job)} </td>
                        <td>
                          {" "}
                          {this.changeEmpty(item.student_mother?.phone)}{" "}
                        </td>
                        <td>
                          {this.changeEmpty(
                            item.address == null
                              ? ""
                              : item.student_note == "null"
                              ? ""
                              : item.student_note
                          )}
                        </td>
                        <td>
                          {this.changeEmpty(
                            item.student_population == null
                              ? ""
                              : item.student_population == "null"
                              ? ""
                              : item.student_population
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </Table>
              );
            })}
          </Card>
        </Container>

        {this.state.addModalShow ? (
          <StudentCreate
            show={this.state.addModalShow}
            onHide={this.onHideModal}
            onSave={this.onSaveModal}
            idYearNow={this.state.idYearNow}
            onSaveClass={this.onSaveClass}
          />
        ) : null}

        {this.state.editModalShow ? (
          <StudentEdit
            show={this.state.editModalShow}
            onHide={this.onHideModal}
            onSave={this.onSaveModal}
            onChangeClass={(item) => this.onChangeClassModal(item)}
            data={this.state.student}
            idYearNow={this.state.idYearNow}
            ingredents={this.state.ingredents}
            classrooms={this.state.classrooms}
            noIngredent={this.state.noIngredent}
            medication={this.state.medication}
            onSaveClass={this.onSaveClass}
          />
        ) : null}

        {this.state.createParentAccountModalShow &&
        this.state.isCreateParents ? (
          <ParentAccountsCreate
            show={this.state.createParentAccountModalShow}
            onHide={this.onHide}
            listStudents={this.state.selectedStudents}
          />
        ) : null}

        {this.state.addStudentsToClassModalShow &&
        this.state.isAddStudentsToClass ? (
          <AddStudentsToClassModal
            show={this.state.addStudentsToClassModalShow}
            onHide={this.onHide}
            onSave={() => {
              this.onSaveModal();
              this.onHide();
            }}
            listStudents={this.state.selectedStudents}
            yearIdNow={this.state.idYearNow}
            schoolYears={this.state.schoolYears}
            classroomsNow={this.state.classrooms}
          />
        ) : null}

        {this.state.changeClassShow ? (
          <ChangeClassModal
            show={this.state.changeClassShow}
            onHide={() => this.setState({ changeClassShow: false })}
            onSave={this.onSaveModal}
            classrooms={this.state.classrooms}
            schoolYears={this.state.schoolYears}
            yearId={this.state.yearId}
            idStudentChangeClass={this.state.idStudentChangeClass}
          />
        ) : null}
        {this.state.showChildGrowth ? (
          <ChildGrowth
            show={this.state.showChildGrowth}
            onHide={() => this.setState({ showChildGrowth: false })}
            onSave={this.onSaveModal}
            classrooms={this.state.classrooms}
            schoolYears={this.state.schoolYears}
            yearId={this.state.yearId}
            studentChoice={this.state.studentChoice}
          />
        ) : null}
      </>
    );
  }
}

function myParams(Component) {
  //Back link AND use "params"
  return (props) => (
    <Component navHook={useNavigate()} {...props} params={useParams()} />
  );
}

export default myParams(StudentIndex);
