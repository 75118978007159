import axios from "axios";
import React, { useState, useEffect } from "react";
import { message } from "../constants/message";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { STATUS_CODE } from "../constants";
import { useNavigate } from "react-router-dom";

const ReturnLogin = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/admin-v2");
  }, [navigate]);

  navigate("/admin-v2");
};

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  timeout: 60000,
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      const statusCode = error.response.status;

      const removeLocalStorage = () => {
        localStorage.removeItem("auth_id_shool");
        localStorage.removeItem("auth_id_user");
        localStorage.removeItem("auth_name");
        localStorage.removeItem("role_rank");
        localStorage.removeItem("role_id");
        localStorage.removeItem("role_name");
        localStorage.removeItem("permission");
        localStorage.removeItem("token_type");
        localStorage.removeItem("access_token");
        localStorage.removeItem("auth_phone");
        localStorage.removeItem("auth_account");
        localStorage.removeItem("vip");
        localStorage.removeItem("version");
      };

      if (statusCode === STATUS_CODE.FORBIDDEN) {
        toast(message.forbiddenError, { type: "error" });
      } else if (statusCode === STATUS_CODE.INTERNAL_SERVER_ERROR) {
        toast(message.internalServerError, { type: "error" });
      } else if (statusCode === STATUS_CODE.UNAUTHORIZED) {
        return swal({
          title: "Lỗi 401!",
          text: error.response?.data?.messages || message.unauthorizedError,
          icon: "error",
          successMode: true,
        }).then((ok) => {
          if (ok) {
            removeLocalStorage();
          }
        });
      } else {
        console.log("Error: ", error.message);
      }
    } else {
      toast(message.networkError, { type: "error" });
    }

    return Promise.reject(error);
  }
);

export default api;
