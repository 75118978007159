import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx-js-style";
import { toast } from "react-toastify";
import swal from "sweetalert";

import {
  Card,
  Container,
  Breadcrumb,
  Button,
  Tabs,
  Tab,
  Row,
  Col,
  Spinner,
  Form,
} from "react-bootstrap";
import Book1 from "./books/Book1";
import Book2 from "./books/Book2";
import Book3 from "./books/Book3";

import { exportBook1, exportBook2, exportBook3 } from "./books/exportFunctions";
import { dateFormatter, groupArrayByField } from "../utils/helpers";
import { INGREDIENT_TYPE, SHOW_INVENTORY } from "../../../constants";
import api from "../../../helper/axiosInstance";

const ThreeStepBook = () => {
  const [school, setSchool] = useState({});

  const [listQuantitatives, setListQuantitatives] = useState([]);
  const [quantitative, setQuantitative] = useState({
    menu_date: new Date().getTime() / 1000,
  });
  const [formSetting, setFormSetting] = useState({
    // Sample book
    book_check_address: "",
    book_3: [],
    book_6: "",
    book_7: "",
    book_8: [],
    book_9: [],
    book_11: "",
    book_12: "",

    // Step 1
    step1_checker: "",
    step1_check_address: "",
    step1_show_inventory: 1,
    step1_m1_3: new Date(),
    step1_m2_5: new Date(),
    step1_m2_11: "",

    // Step 2
    step2_checker: "",
    step2_check_address: "",
    step2_show_inventory: 1,
    step2_6: [],
    step2_7: [],
    step2_8: [],
    step2_9: "",
    step2_10: "",

    // Step 3
    step3_checker: "",
    step3_check_address: "",
    step3_2: [],
    step3_5: [],
    step3_6: [],
    step3_7: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const [listDays, setListDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState({});
  const [times, setTimes] = useState([]);

  useEffect(() => {
    document.title = "Sổ 3 bước";

    let initialWeek = datesOfWeek(new Date());

    let todayIs = new Date().getDay();
    if (todayIs === 0) {
      todayIs = 7;
    }

    getListQuantitatives(initialWeek[0], initialWeek[6], todayIs);
    getDetailschool();
    getSettingFormData();
    getTimes();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //#region  API
  const getDetailschool = async () => {
    await api
      .get(`/detailSchool`)
      .then((res) => {
        if (res.data.status === true) {
          setSchool(res.data.data[0]);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((error) => {});
  };

  const getListQuantitatives = async (startDate, endDate, dayId) => {
    setIsLoading(true);
    setListQuantitatives([]);
    setQuantitative({
      menu_date: startDate.getTime() / 1000,
    });

    await api
      .get(`menu-show`, {
        params: {
          start_at: startDate.getTime() / 1000,
          term_at: endDate.getTime() / 1000,
        },
      })
      .then((res) => {
        setIsLoading(false);

        if (res.data.errCode === 0) {
          setListDays(
            res.data.days.map((dayItem) => {
              return {
                id_day: dayItem.id_day,
                day_name: dayItem.day_name,
                date: new Date(dayItem.menu_date * 1000),
              };
            })
          );

          setListQuantitatives(res.data.days);

          setQuantitative(
            res.data.days.find(
              (listQuantitativesItem) => listQuantitativesItem.id_day === dayId
            )
              ? res.data.days.find(
                  (listQuantitativesItem) =>
                    listQuantitativesItem.id_day === dayId
                )
              : res.data.days[0]
          );

          setSelectedDay(
            res.data.days.find(
              (listQuantitativesItem) => listQuantitativesItem.id_day === dayId
            )
              ? res.data.days.find(
                  (listQuantitativesItem) =>
                    listQuantitativesItem.id_day === dayId
                )?.id_day
              : res.data.days[0].id_day
          );
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((error) => {});
  };

  const getSettingFormData = async () => {
    await api
      .get("/setting-form-show")
      .then((res) => {
        if (res.data.errCode === 0) {
          setFormSetting(res.data.settingForm);
        }
      })
      .catch((error) => {});
  };

  const getTimes = async () => {
    await api
      .get("/getTimeOfDays")
      .then((res) => {
        if (res.data.errCode === 0) {
          setTimes(res.data.times);
        }
      })
      .catch((error) => {});
  };
  // #endregion

  //#region Logic
  const exportExcel = () => {
    const wb = XLSX.utils.book_new();

    let ingredientArray = [];

    quantitative.times?.map((timeItem) => {
      timeItem.foods?.map((foodItem) => {
        return foodItem.groups?.map((groupItem) => {
          const ingredients =
            formSetting.step1_show_inventory === 1
              ? groupItem.ingredents
              : groupItem.ingredents.filter(
                  (ingredient) => ingredient.ingredent_type === 1
                );

          return ingredients?.map((ingredientItem) => {
            ingredientArray.push(ingredientItem);
            return ingredientItem;
          });
        });
      });

      return timeItem;
    });

    const groupIngredientArray = groupArrayByField(ingredientArray, "id");

    let ingredientLen = 0;

    quantitative.times?.map((timeItem) => {
      ingredientLen += timeItem.foods.length;
      return timeItem;
    });

    const ws1 = exportBook1(
      groupIngredientArray,
      formSetting.step1_show_inventory
    );
    const ws2 = exportBook2(ingredientLen);
    const ws3 = exportBook3(ingredientLen);

    XLSX.utils.book_append_sheet(wb, ws1, "Sổ bước 1");
    XLSX.utils.book_append_sheet(wb, ws2, "Sổ bước 2");
    XLSX.utils.book_append_sheet(wb, ws3, "Sổ bước 3");

    XLSX.writeFile(
      wb,
      `Sổ 3 bước (${dateFormatter(
        new Date(quantitative.menu_date * 1000)
      )}).xlsx`
    );
  };

  const handleXport3StepBook = () => {
    swal({
      title: `Bạn muốn xuất file sổ 3 bước`,
      text: `Ngày ${dateFormatter(new Date(quantitative.menu_date * 1000))}`,
      icon: "info",
      buttons: ["Đóng", "Xuất"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        exportExcel();
      }
    });
  };

  const datesOfWeek = (current) => {
    var week = [];
    // Starting Monday not Sunday
    current.setDate(current.getDate() - current.getDay() + 1);
    current.setHours(0, 0, 0, 0);
    for (var i = 0; i < 7; i++) {
      week.push(new Date(current));
      current.setDate(current.getDate() + 1);
      current.setHours(0, 0, 0, 0);
    }
    return week;
  };
  // #endregion

  // Render
  const RenderOption = () => {
    return (
      <section className="mb-3">
        <Card.Title>Chọn ngày điền sổ 3 bước</Card.Title>

        <Row className="my-3">
          {listDays.map((dayItem) => {
            return (
              <Col key={dayItem.id_day} md="auto" xs={6} className="d-flex">
                <Form.Check
                  type="radio"
                  value={dayItem.id_day}
                  checked={selectedDay === dayItem.id_day}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedDay(Number(e.target.value));
                      setQuantitative(
                        listQuantitatives.find(
                          (quantitativeItem) =>
                            quantitativeItem.id_day === Number(e.target.value)
                        )
                      );
                    }
                  }}
                  name="day"
                  className="me-2"
                />
                {dayItem.day_name}
              </Col>
            );
          })}
        </Row>
      </section>
    );
  };

  return (
    <Container fluid>
      <Breadcrumb>
        <Breadcrumb.Item active>Biểu mẫu</Breadcrumb.Item>
        <Breadcrumb.Item active>Sổ 3 bước</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <Card.Header>
          <div className="d-block d-sm-flex justify-content-between">
            <div className="d-block d-sm-flex align-items-center mb-3 mb-sm-0">
              <Card.Title className="me-3 pt-1">
                <i className="fas fa-list me-1"></i> Sổ 3 bước{" "}
                {dateFormatter(new Date(quantitative?.menu_date * 1000))}
              </Card.Title>

              <input
                type="week"
                name="week"
                style={{
                  border: "none",
                  width: 39,
                  backgroundColor: "rgb(16,108,252)",
                  borderRadius: 5,
                }}
                onChange={(e) => {
                  const today = e.target.value
                    ? new Date(e.target.valueAsNumber)
                    : new Date();
                  const week = datesOfWeek(today);

                  getListQuantitatives(week[0], week[6], selectedDay);
                }}
              />
            </div>

            <Button
              variant="success"
              size="sm"
              onClick={() => handleXport3StepBook()}
            >
              Xuất file <i className="fa-solid fa-file-export"></i>
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          {RenderOption()}

          {isLoading ? (
            <div className="d-flex justify-content-center my-5">
              <Spinner
                animation="border"
                role="status"
                variant="primary"
                style={{ width: "3rem", height: "3rem" }}
              >
                <span className="visually-hidden">Đang tải...</span>
              </Spinner>
            </div>
          ) : (
            <Tabs defaultActiveKey="1">
              <Tab eventKey="1" title="Bước 1">
                <Book1
                  school={school}
                  quantitative={{
                    ...quantitative,
                    times: quantitative?.times?.map((time) => ({
                      ...time,
                      foods: time?.foods?.map((food) => ({
                        ...food,
                        groups: food?.groups?.map((group) => ({
                          ...group,
                          ingredents: group?.ingredents?.filter((ingredent) => {
                            if (
                              formSetting.step1_show_inventory ===
                              SHOW_INVENTORY.FALSE
                            )
                              return (
                                ingredent.ingredent_type ===
                                INGREDIENT_TYPE.MARKET
                              );
                            else return true;
                          }),
                        })),
                      })),
                    })),
                  }}
                  formSetting={formSetting}
                />
              </Tab>

              <Tab eventKey="2" title="Bước 2">
                <Book2
                  school={school}
                  quantitative={{
                    ...quantitative,
                    times: quantitative?.times?.map((time) => ({
                      ...time,
                      foods: time?.foods?.map((food) => ({
                        ...food,
                        groups: food?.groups?.map((group) => ({
                          ...group,
                          ingredents: group?.ingredents?.filter((ingredent) => {
                            if (
                              formSetting.step2_show_inventory ===
                              SHOW_INVENTORY.FALSE
                            )
                              return (
                                ingredent.ingredent_type ===
                                INGREDIENT_TYPE.MARKET
                              );
                            else return true;
                          }),
                        })),
                      })),
                    })),
                  }}
                  formSetting={formSetting}
                  times={times}
                />
              </Tab>

              <Tab eventKey="3" title="Bước 3">
                <Book3
                  school={school}
                  quantitative={quantitative}
                  formSetting={formSetting}
                  times={times}
                />
              </Tab>
            </Tabs>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ThreeStepBook;
