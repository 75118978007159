exports.message = {
  notFound: "Không tìm thấy tài nguyên",

  notEmpty: "Không được để trống",

  isInt: (min = null, max = null) =>
    `Phải là kiểu số nguyên${
      min && max && min !== null && max !== null && isNaN(min) && isNaN(max)
        ? ` từ ${min} đến ${max}`
        : min && min !== null && isNaN(min)
        ? ` lớn hơn hoặc bằng ${min}`
        : max && min !== null && isNaN(max)
        ? ` nhỏ hơn hoặc bằng ${max}`
        : ""
    }`,

  isLength: (min, max) =>
    `${
      min && max
        ? `Từ ${min} - ${max} kí tự`
        : min
        ? `Lớn hơn ${min} kí tự`
        : max
        ? `Nhỏ hơn ${max} kí tự`
        : ""
    }`,

  isIn: (values) => `Chỉ cho phép các giá trị ${values.join(", ")}`,

  isString: "Phải là kiểu kí tự",

  isNumeric: "Phải là kiểu số",

  isArray: "Phải là kiểu mảng",

  isInvalidLink: (invalidLinks) =>
    `Đường dẫn không hợp lệ: ${invalidLinks.join(", ")}`,

  isExisted: "Dữ liệu đã tồn tại",

  isEmail: "Sai định dạng email",

  isInvalidPhoneNumber: "Sai định dạng số điện thoại",

  isDate: (format) => `Định dạng: ${format}`,

  confirmPassword: "Xác nhận lại mật khẩu",

  passwordMismatch: "Không trùng khớp với mật khẩu",

  unauthorized: "Không được phép",

  unauthenticated: "Chưa được xác thực",

  invalidToken: "Token không hợp lệ",

  invalid: "Không hợp lệ",

  duplicate: "Trùng lặp giá trị",

  requiredNote: "Vui lòng thêm ghi chú",

  notExist: "Dữ liệu không tồn tại",

  isObject: "Dữ liệu phải là kiểu Object.",

  invalidPhone: "Số điện thoại không hợp lệ",

  createdSuccessfully: "Thêm thành công",
  createdFailure: "Thêm thất bại",
  updatedSuccessfully: "Cập nhật thành công",
  updatedFailure: "Cập nhật thất bại",
  deletedSuccessfully: "Xóa thành công",
  deletedFailure: "Xóa thất bại",
  ingredientStoreNotFound: "Không tìm thấy nguyên liệu kho",
  exportStoreToKitchen: "Xuất kho cho bếp",
  internalServerError: "Lỗi 500: Lỗi máy chủ nội bộ. Vui lòng thử lại sau",
  forbiddenError: "Lỗi 403: Bạn không có quyền truy cập vào tài nguyên này",
  unauthorizedError: "Lỗi 401: Truy cập trái phép. Vui lòng thử lại sau",
  networkError: "Lỗi mạng. Vui lòng kiểm tra kết nối của bạn.",
  pleaseCompleteTheReCAPTCHA: "Vui lòng hoàn thành reCAPTCHA",
};
