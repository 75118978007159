import React from "react";
import { Table } from "react-bootstrap";
import moment from "moment";
import CurrencyFormat from "react-currency-format";

const Book2 = ({ school, quantitative, formSetting, times }) => {
  const costPerDays = quantitative?.costPerDays || [];
  let bookIndex = 0;
  let bookIndexreport = 0;

  return (
    <div style={{ overflowX: "scroll" }}>
      <Table bordered style={{ minWidth: 999 }}>
        <thead>
          <tr>
            <td className="align-top" colSpan={6}>
              Tên cơ sở: {school.school_name}
              <br />
              Người kiểm tra: {formSetting.step2_checker}
              <br />
              Thời gian kiểm tra: ngày{" "}
              {new Date(quantitative?.menu_date * 1000).getDate()} tháng{" "}
              {new Date(quantitative?.menu_date * 1000).getMonth() + 1} năm{" "}
              {new Date(quantitative?.menu_date * 1000).getFullYear()}
              <br />
              Địa điểm kiểm tra: {formSetting.step2_check_address}
            </td>
            <th className="align-top" colSpan={7}>
              Mẫu số 2: Kiểm tra khi chế biến thức ăn (Bước 2)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="text-center">
            <td className="align-middle" rowSpan={2}>
              TT
            </td>
            <td className="align-middle" rowSpan={2}>
              Ca/bữa ăn (Bữa ăn, giờ ăn)
            </td>
            <td className="align-middle" rowSpan={2}>
              Tên món ăn
            </td>
            <td className="align-middle" rowSpan={2}>
              Nguyên liệu chính để chế biến (tên, số lượng...)
            </td>
            <td className="align-middle" rowSpan={2}>
              Số lượng / Số suất ăn
            </td>
            <td className="align-middle" rowSpan={2}>
              Thời gian sơ chế xong (ngày, giờ)
            </td>
            <td className="align-middle" rowSpan={2}>
              Thời gian chế biến xong (ngày, giờ)
            </td>
            <td className="align-middle" colSpan={3}>
              Kiểm tra điều kiện vệ sinh (từ thời điểm bắt đầu sơ chế, chế biến
              cho đến khi thức ăn được chế biến xong)
            </td>
            <td className="align-middle" colSpan={2}>
              Kiểm tra cảm quan (màu, mùi, vị, trạng thái, bảo quản...)
            </td>
            <td className="align-middle" rowSpan={2}>
              Biện pháp xử lý / Ghi chú
            </td>
          </tr>
          <tr className="text-center">
            <td className="align-middle">Người tham gia chế biến</td>
            <td className="align-middle">Trang thiết bị dụng cụ</td>
            <td className="align-middle">Khu vực chế biến và phụ trợ</td>
            <td className="align-middle">Đạt</td>
            <td className="align-middle">Không đạt</td>
          </tr>
          <tr className="text-center">
            <td className="align-middle">(1)</td>
            <td className="align-middle">(2)</td>
            <td className="align-middle">(3)</td>
            <td className="align-middle">(4)</td>
            <td className="align-middle">(5)</td>
            <td className="align-middle">(6)</td>
            <td className="align-middle">(7)</td>
            <td className="align-middle">(8)</td>
            <td className="align-middle">(9)</td>
            <td className="align-middle">(10)</td>
            <td className="align-middle">(11)</td>
            <td className="align-middle">(12)</td>
            <td className="align-middle">(13)</td>
          </tr>

          {quantitative?.times?.map((timeItem) => {
            return (
              <React.Fragment key={timeItem.id_time}>
                {timeItem?.foods.map((foodItem, foodIndex) => {
                  let mealQuantity = 0;

                  foodItem.groups.map((groupItem) => {
                    mealQuantity += groupItem.more_meal
                      ? groupItem.more_meal
                      : Number(
                          costPerDays.find(
                            (costPerDay) => costPerDay.id_group === groupItem.id
                          )?.meal_quantity
                        );
                    return groupItem;
                  });

                  bookIndex++;
                  return (
                    <tr key={`food2-${foodIndex}`} className="align-middle">
                      <td className="text-center">{bookIndex}</td>
                      <td>
                        {foodIndex !== 0
                          ? ""
                          : times.find(
                              (timeObj) => timeObj.id === timeItem.id_time
                            )?.time_name_now}
                      </td>
                      <td>{foodItem.food_name}</td>
                      <td>
                        {foodItem.groups.map((groupItem, groupIndex) => {
                          const ingredients =
                            formSetting.step1_show_inventory === 1
                              ? groupItem.ingredents
                              : groupItem.ingredents.filter(
                                  (ingredient) =>
                                    ingredient.ingredent_type === 1
                                );

                          return (
                            <div key={`group2-${foodIndex}-${groupIndex}`}>
                              <div className="fw-bold">
                                {groupItem.group_name}:
                                <br />
                              </div>

                              {ingredients?.map((ingredientItem, ingIndex) => {
                                return (
                                  <div
                                    key={`ingredient2-${foodIndex}-${groupIndex}-${ingIndex}`}
                                  >
                                    {ingredientItem.ingredent_name}&nbsp;
                                    <CurrencyFormat
                                      value={parseFloat(
                                        ingredientItem.ing_quantity
                                      )}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      decimalScale={2}
                                    />
                                    &nbsp;
                                    {ingredientItem.unit_cook_name}
                                    <br />
                                  </div>
                                );
                              })}
                              <br />
                            </div>
                          );
                        })}
                      </td>
                      <td className="text-center">{mealQuantity}</td>
                      <td className="text-center">
                        {moment(
                          Number(
                            formSetting.step2_6?.find(
                              (item, timeObjIndex) =>
                                parseInt(item.time) === timeItem.id_time
                            )?.val
                          )
                        ).format("HH:mm")}
                      </td>
                      <td className="text-center">
                        {moment(
                          Number(
                            formSetting.step2_7?.find(
                              (item, timeObjIndex) =>
                                parseInt(item.time) === timeItem.id_time
                            )?.val
                          )
                        ).format("HH:mm")}
                      </td>
                      <td>
                        {formSetting.step2_8?.map((user) => {
                          return (
                            <div key={user.value}>
                              {user.label}
                              <br />
                              <br className="d-none" />
                            </div>
                          );
                        })}
                      </td>
                      <td className="text-center">{formSetting.step2_9}</td>
                      <td className="text-center">{formSetting.step2_10}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  );
                })}
              </React.Fragment>
            );
          })}
        </tbody>
        <tfoot>
          <tr className="text-center" style={{ height: 50 }}>
            <td colSpan={4}>Ban giám hiệu</td>
            <td colSpan={5}>Tổ trưởng kiểm thực</td>
            <td colSpan={4}>Người kiểm tra</td>
          </tr>
        </tfoot>
      </Table>

      <Table bordered id="book-step-2" className="d-none">
        <thead>
          <tr>
            <td className="align-top">
              Tên cơ sở: {school.school_name}
              <br />
              Người kiểm tra: {formSetting.step2_checker}
              <br />
              Thời gian kiểm tra: ngày{" "}
              {new Date(quantitative?.menu_date * 1000).getDate()} tháng{" "}
              {new Date(quantitative?.menu_date * 1000).getMonth() + 1} năm{" "}
              {new Date(quantitative?.menu_date * 1000).getFullYear()}
              <br />
              Địa điểm kiểm tra: {formSetting.step2_check_address}
            </td>
            <td>empty</td>
            <td>empty</td>
            <td>empty</td>
            <td>empty</td>
            <td>empty</td>

            <th className="align-top">
              Mẫu số 2: Kiểm tra khi chế biến thức ăn (Bước 2)
            </th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
          </tr>
        </thead>

        <tbody>
          <tr className="text-center">
            <td className="align-middle">TT</td>
            <td className="align-middle">Ca/bữa ăn (Bữa ăn, giờ ăn)</td>
            <td className="align-middle">Tên món ăn</td>
            <td className="align-middle">
              Nguyên liệu chính để chế biến (tên, số lượng...)
            </td>
            <td className="align-middle">Số lượng / Số suất ăn</td>
            <td className="align-middle">Thời gian sơ chế xong (ngày, giờ)</td>
            <td className="align-middle">
              Thời gian chế biến xong (ngày, giờ)
            </td>
            <td className="align-middle">
              Kiểm tra điều kiện vệ sinh (từ thời điểm bắt đầu sơ chế, chế biến
              cho đến khi thức ăn được chế biến xong)
            </td>
            <td>empty</td>
            <td>empty</td>

            <td className="align-middle">
              Kiểm tra cảm quan (màu, mùi, vị, trạng thái, bảo quản...)
            </td>
            <td>empty</td>

            <td className="align-middle">Biện pháp xử lý / Ghi chú</td>
          </tr>
          <tr className="text-center">
            <td>empty</td>
            <td>empty</td>
            <td>empty</td>
            <td>empty</td>
            <td>empty</td>
            <td>empty</td>
            <td>empty</td>

            <td className="align-middle">Người tham gia chế biến</td>
            <td className="align-middle">Trang thiết bị dụng cụ</td>
            <td className="align-middle">Khu vực chế biến và phụ trợ</td>
            <td className="align-middle">Đạt</td>
            <td className="align-middle">Không đạt</td>
            <td>empty</td>
          </tr>
          <tr className="text-center">
            <td className="align-middle">(1)</td>
            <td className="align-middle">(2)</td>
            <td className="align-middle">(3)</td>
            <td className="align-middle">(4)</td>
            <td className="align-middle">(5)</td>
            <td className="align-middle">(6)</td>
            <td className="align-middle">(7)</td>
            <td className="align-middle">(8)</td>
            <td className="align-middle">(9)</td>
            <td className="align-middle">(10)</td>
            <td className="align-middle">(11)</td>
            <td className="align-middle">(12)</td>
            <td className="align-middle">(13)</td>
          </tr>

          {quantitative?.times?.map((timeItem) => {
            return (
              <React.Fragment key={timeItem.id_time}>
                {timeItem?.foods.map((foodItem, foodIndex) => {
                  let mealQuantity = 0;

                  foodItem.groups.map((groupItem) => {
                    mealQuantity += groupItem.more_meal
                      ? groupItem.more_meal
                      : Number(
                          costPerDays.find(
                            (costPerDay) => costPerDay.id_group === groupItem.id
                          )?.meal_quantity
                        );
                    return groupItem;
                  });

                  bookIndexreport++;
                  return (
                    <tr key={`food-${foodIndex}`} className="align-middle">
                      <td className="text-center">{bookIndexreport}</td>
                      <td>
                        {foodIndex !== 0
                          ? "empty"
                          : times.find(
                              (timeObj) => timeObj.id === timeItem.id_time
                            )?.time_name_now}
                      </td>
                      <td>{foodItem.food_name}</td>
                      <td>
                        {foodItem.groups.map((groupItem, groupIndex) => {
                          return (
                            <>
                              {groupIndex > 0 ? (
                                <>
                                  {" "}
                                  <br /> <br />
                                </>
                              ) : (
                                <></>
                              )}

                              <div key={`group-${foodIndex}-${groupIndex}`}>
                                <strong> {groupItem.group_name}: </strong>

                                {groupItem.ingredents.map(
                                  (ingredientItem, ingIndex) => {
                                    return (
                                      <>
                                        <br />
                                        <div
                                          key={`ingredient-${foodIndex}-${groupIndex}-${ingIndex}`}
                                        >
                                          {ingredientItem.ingredent_name}&nbsp;
                                          <CurrencyFormat
                                            value={parseFloat(
                                              ingredientItem.ing_quantity
                                            )}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            decimalScale={2}
                                          />
                                          &nbsp;
                                          {ingredientItem.unit_cook_name}
                                        </div>
                                      </>
                                    );
                                  }
                                )}
                              </div>
                            </>
                          );
                        })}
                      </td>
                      <td className="text-center">{mealQuantity}</td>
                      <td className="text-center">
                        {moment(
                          Number(
                            formSetting.step2_6?.find(
                              (item, timeObjIndex) =>
                                parseInt(item.time) === timeItem.id_time
                            )?.val
                          )
                        ).format("HH:mm")}
                      </td>
                      <td className="text-center">
                        {moment(
                          Number(
                            formSetting.step2_7?.find(
                              (item, timeObjIndex) =>
                                parseInt(item.time) === timeItem.id_time
                            )?.val
                          )
                        ).format("HH:mm")}
                      </td>
                      <td>
                        {formSetting.step2_8?.map((user) => {
                          return (
                            <div key={user.value}>
                              {user.label}
                              <br />
                              <br className="d-none" />
                            </div>
                          );
                        })}
                      </td>
                      <td className="text-center">{formSetting.step2_9}</td>
                      <td className="text-center">{formSetting.step2_10}</td>
                      <td>empty</td>
                      <td>empty</td>
                      <td>empty</td>
                    </tr>
                  );
                })}
              </React.Fragment>
            );
          })}
        </tbody>
        <tfoot>
          <tr className="text-center" style={{ height: 50 }}>
            <td>Ban giám hiệu</td>
            <td>empty</td>
            <td>empty</td>
            <td>empty</td>

            <td>Tổ trưởng kiểm thực</td>
            <td>empty</td>
            <td>empty</td>
            <td>empty</td>
            <td>empty</td>

            <td>Người kiểm tra</td>
            <td>empty</td>
            <td>empty</td>
            <td>empty</td>
          </tr>
        </tfoot>
      </Table>
    </div>
  );
};

export default Book2;
