import React, { Component } from "react";
import {
  Button,
  Breadcrumb,
  Card,
  Container,
  Table,
  Tabs,
  Tab,
  Modal,
} from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import DatePicker, { registerLocale } from "react-datepicker";
import vi from "date-fns/locale/vi";
import * as XLSX from "xlsx-js-style";
import { toast } from "react-toastify";
import swal from "sweetalert";
import DataTables from "../datatable/DataTables";
import { IS_ACTIVE } from "../../../constants";
import api from "../../../helper/axiosInstance";

registerLocale("vi", vi);

class Receipt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classifies: [],
      isLoading: false,

      selectedTab: 1,

      storeMaterialList: [],

      startDate: new Date(),
      endDate: new Date(),
      tmpStartDate: new Date(),
      tmpEndDate: new Date(),
      dataExcel: [],
      show: false,
      schoolInfo: [],
      showReceipt: false,
      dateExport: {},
      selectedRows: [],
    };
  }

  async componentDidMount() {
    document.title = "Phiếu Nhập-Xuất";

    const date = new Date();

    const start = new Date(date.getFullYear(), date.getMonth(), 1);
    start.setHours(0, 0, 0, 0);

    const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    end.setHours(23, 59, 59, 0);

    this.setState({
      startDate: start,
      endDate: end,

      tmpStartDate: start,
      tmpEndDate: end,
    });

    this.getlistinventory(1, 100, start, end);
    this.getDetailschool();
  }
  getDetailschool = () => {
    api.get(`/detailSchool`).then((res) => {
      if (res.data.status === true) {
        this.setState({
          schoolInfo: res.data.data[0],
        });
      }
    });
  };
  getlistinventory = (type, limit, startDate, endDate) => {
    api.get(`receipt-show?is_type=${type}`).then((res) => {
      if (res.data.errCode === 0) {
        this.setState({
          storeMaterialList: res.data.receipts,
        });
      }
    });
  };

  getDetail = (id) => {
    api
      .get(`receipt-show?id=${id}`)
      .then((res) => {
        if (res.data.errCode === 0) {
          this.setState(
            {
              dataExcel: res.data,
            },
            () => this.handleExport()
          );
        }
      })
      .catch((error) => {});
  };

  getData = (item) => {
    api
      .get(`receipt-show?id=${item.id}`)
      .then((res) => {
        if (res.data.errCode === 0) {
          this.setState(
            {
              dataExcel: res.data,
              dateExport: item,
            },
            () => this.setState({ showReceipt: true })
          );
        }
      })
      .catch((error) => {});
  };

  destroyReceipt = (id, is_status) => {
    swal({
      title: `Bạn muốn xóa Phiếu ${
        this.state.selectedTab ? "nhập kho" : "xuất kho"
      }?`,
      text:
        is_status === IS_ACTIVE.TRUE && this.state.selectedTab
          ? "**Lưu ý: Hệ thống sẽ hoàn lại số lượng tồn kho nếu có lô hàng liên quan bị xuất kho trước đó."
          : "",
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    })
      .then((ok) => {
        if (ok) {
          api
            .delete(`receipt-destroy?id=${id}`)
            .then((res) => {
              if (res.data.errCode === 0) {
                toast(res.data.message, { type: "success", autoClose: 1000 });

                this.getlistinventory(
                  this.state.selectedTab,
                  100,
                  this.state.startDate,
                  this.state.endDate
                );
              }
            })
            .catch((error) => {});
        }
      })
      .catch((error) => {});
  };

  activeReceipt = async (id = null) => {
    let ids = "";
    if (id === null && this.state.selectedRows.length == 0)
      toast("Vui lòng chọn phiếu.", { type: "error", autoClose: 1000 });
    else if (id === null && this.state.selectedRows.length > 0)
      ids = JSON.stringify(
        this.state.selectedRows.map((recItem) => {
          return recItem.id;
        })
      );
    else ids = JSON.stringify([id]);

    if (ids !== "") {
      await swal({
        title: `Bạn muốn xác nhận Phiếu nhập kho?`,
        text: `**Lưu ý: Hệ thống sẽ xuất kho bổ sung dành cho các mặc hàng trước đó bị xuất kho âm.`,
        icon: "warning",
        buttons: ["Đóng", "Xác nhận"],
        successMode: true,
      })
        .then(async (ok) => {
          if (ok) {
            let bodyFormData = new FormData();
            bodyFormData.append("ids", ids);

            await api({
              method: "put",
              url: "receipt-confirm",
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
            })
              .then((res) => {
                if (res.data.errCode === 0) {
                  toast(res.data.message, { type: "success", autoClose: 1000 });

                  this.getlistinventory(
                    1,
                    100,
                    this.state.startDate,
                    this.state.endDate
                  );
                }
              })
              .catch((error) => {});
          }
        })
        .catch((error) => {});
    }
  };

  changeEmpty = (element) => {
    return element == "" ||
      element == null ||
      element == "null" ||
      element == undefined ||
      element == "undefined"
      ? "empty"
      : element;
  };
  formatNumber(num) {
    if (num != null && num != "")
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  soThanhChu(so) {
    const donVi = [
      "",
      "một",
      "hai",
      "ba",
      "bốn",
      "năm",
      "sáu",
      "bảy",
      "tám",
      "chín",
    ];
    const chuc = [
      "",
      "mười",
      "hai mươi",
      "ba mươi",
      "bốn mươi",
      "năm mươi",
      "sáu mươi",
      "bảy mươi",
      "tám mươi",
      "chín mươi",
    ];
    const hang = ["", "nghìn", "triệu", "tỷ", "nghìn tỷ", "triệu tỷ", "tỷ tỷ"];

    if (so === 0) return "Không";

    let chuoi = "";
    let i = 0;

    while (so > 0) {
      let donViHangNghin = so % 1000;
      if (donViHangNghin !== 0) {
        chuoi =
          this.soThanhChuHangNghin(donViHangNghin) +
          " " +
          hang[i] +
          " " +
          chuoi;
      }
      so = Math.floor(so / 1000);
      i++;
    }

    return chuoi.trim() + " đồng";
  }

  soThanhChuHangNghin(so) {
    const donVi = [
      "",
      "một",
      "hai",
      "ba",
      "bốn",
      "năm",
      "sáu",
      "bảy",
      "tám",
      "chín",
    ];
    const chuc = [
      "",
      "mười",
      "hai mươi",
      "ba mươi",
      "bốn mươi",
      "năm mươi",
      "sáu mươi",
      "bảy mươi",
      "tám mươi",
      "chín mươi",
    ];

    let chuoi = "";

    if (so < 10) {
      chuoi = donVi[so];
    } else if (so < 100) {
      chuoi =
        chuc[Math.floor(so / 10)] + (so % 10 !== 0 ? " " + donVi[so % 10] : "");
    } else if (so < 1000) {
      chuoi =
        donVi[Math.floor(so / 100)] +
        " trăm " +
        chuc[Math.floor((so % 100) / 10)] +
        (so % 10 !== 0 ? " " + donVi[so % 10] : "");
    }

    return chuoi.trim();
  }
  formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  handleExport = () => {
    const table = document.getElementById("tableImportStore");
    const wb = XLSX.utils.table_to_book(table);
    var ws = wb.Sheets["Sheet1"];

    // header
    const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H"];
    ws[`A1`].s = {
      font: {
        name: "Times New Roman",

        bold: true,
      },
      alignment: {
        wrapText: true,
        vertical: "center",
        horizontal: "center",
      },
    };
    ws[`A2`].s = {
      font: {
        name: "Times New Roman",
      },
    };
    ws[`C3`].s = {
      font: {
        name: "Times New Roman",
      },
    };
    ws[`D3`].s = {
      font: {
        name: "Times New Roman",
      },
    };
    ws[`E3`].s = {
      font: {
        name: "Times New Roman",
      },
    };
    ws[`D4`].s = {
      font: {
        name: "Times New Roman",
      },
    };
    ws[`B5`].s = {
      font: {
        name: "Times New Roman",
      },
    };
    ws[`B6`].s = {
      font: {
        name: "Times New Roman",
      },
    };
    ws[`B7`].s = {
      font: {
        name: "Times New Roman",
      },
    };
    ws[`B${12 + this.state.dataExcel?.ingredients?.length}`].s = {
      font: {
        name: "Times New Roman",
      },
    };
    ws[`B${13 + this.state.dataExcel?.ingredients?.length}`].s = {
      font: {
        name: "Times New Roman",
      },
    };
    //
    ws[`G1`].s = {
      font: {
        name: "Times New Roman",
      },
      alignment: {
        wrapText: true,
        vertical: "top",
        horizontal: "center",
      },
    };
    ws[`C1`].s = {
      font: {
        name: "Times New Roman",
        sz: 15,
        bold: true,
      },
      alignment: {
        wrapText: true,
        vertical: "center",
        horizontal: "center",
      },
    };

    ws[`G2`].s = {
      font: {
        name: "Times New Roman",
      },
      alignment: {
        wrapText: true,
        vertical: "center",
        horizontal: "center",
      },
    };
    //  Ký tên
    ws[`G${14 + this.state.dataExcel?.ingredients?.length}`].s = {
      font: {
        name: "Times New Roman",
      },
      alignment: {
        wrapText: true,
        vertical: "center",
        horizontal: "center",
      },
    };
    if (this.state.selectedTab) {
      ws[`A${15 + this.state.dataExcel?.ingredients?.length}`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
        },
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: "center",
        },
      };
      ws[`A${16 + this.state.dataExcel?.ingredients?.length}`].s = {
        font: {
          name: "Times New Roman",
          italic: true,
        },
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: "center",
        },
      };
      ws[`C${15 + this.state.dataExcel?.ingredients?.length}`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
        },
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: "center",
        },
      };
      ws[`C${16 + this.state.dataExcel?.ingredients?.length}`].s = {
        font: {
          name: "Times New Roman",
          italic: true,
        },
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: "center",
        },
      };
      ws[`E${15 + this.state.dataExcel?.ingredients?.length}`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
        },
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: "center",
        },
      };
      ws[`E${16 + this.state.dataExcel?.ingredients?.length}`].s = {
        font: {
          name: "Times New Roman",
          italic: true,
        },
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: "center",
        },
      };
      ws[`G${15 + this.state.dataExcel?.ingredients?.length}`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
        },
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: "center",
        },
      };
      ws[`G${16 + this.state.dataExcel?.ingredients?.length}`].s = {
        font: {
          name: "Times New Roman",
          italic: true,
        },
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: "center",
        },
      };
    } else {
      ws[`A${15 + this.state.dataExcel?.ingredients?.length}`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
      };
    }
    for (let i = 8; i < 11; i++) {
      colAlpha.map((alpha) => {
        if (ws[`${alpha}${i}`].v === "empty") {
          ws[`${alpha}${i}`].v = " ";
        }

        ws[`${alpha}${i}`].s = {
          font: {
            name: "Times New Roman",
            sz: 11,
            bold: true,
          },
          alignment: {
            wrapText: true,
            vertical: "center",
            horizontal: "center",
          },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        };

        return alpha;
      });
    }
    for (let i = 11; i < 12 + this.state.dataExcel?.ingredients?.length; i++) {
      colAlpha.map((alpha) => {
        if (ws[`${alpha}${i}`].v === "empty") {
          ws[`${alpha}${i}`].v = " ";
        }

        ws[`${alpha}${i}`].s = {
          font: {
            name: "Times New Roman",
            sz: 11,
            bold: i == 11 + this.state.dataExcel?.ingredients?.length,
          },
          alignment: {
            wrapText: true,
            vertical: "center",
            horizontal:
              alpha == "B"
                ? "left"
                : alpha == "F" || alpha == "G" || alpha == "H"
                ? "right"
                : "center",
          },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        };
        ws[`G${i}`].z = "#,###,###,###";
        delete ws[`G${i}`].w;
        XLSX.utils.format_cell(ws[`G${i}`]);

        ws[`H${i}`].z = "#,###,###,###";
        delete ws[`H${i}`].w;
        XLSX.utils.format_cell(ws[`H${i}`]);

        // ws[`F${i}`].z = "#,###,###,###.##";
        // delete ws[`F${i}`].w;
        // XLSX.utils.format_cell(ws[`F${i}`]);
        return alpha;
      });
    }
    ws["!cols"] = [
      { wch: 4.7 },
      { wch: 21.4 },
      { wch: 8 },

      { wch: 7 },
      { wch: 8.5 },
      { wch: 8.5 },
      { wch: 11.5 },
      { wch: 11.5 },
    ];
    ws["!rows"] = [{ hpt: 32 }];
    const merge = [
      { s: { r: 7, c: 0 }, e: { r: 8, c: 0 } },
      { s: { r: 7, c: 1 }, e: { r: 8, c: 1 } },
      { s: { r: 7, c: 2 }, e: { r: 8, c: 2 } },
      { s: { r: 7, c: 3 }, e: { r: 8, c: 3 } },
      { s: { r: 7, c: 4 }, e: { r: 7, c: 5 } },
      { s: { r: 7, c: 6 }, e: { r: 8, c: 6 } },
      { s: { r: 7, c: 7 }, e: { r: 8, c: 7 } },
      { s: { r: 0, c: 0 }, e: { r: 0, c: 1 } },
      { s: { r: 0, c: 2 }, e: { r: 1, c: 4 } },
      { s: { r: 0, c: 6 }, e: { r: 0, c: 7 } },
      { s: { r: 1, c: 0 }, e: { r: 1, c: 1 } },
      { s: { r: 1, c: 6 }, e: { r: 3, c: 7 } },
      {
        s: { r: 13 + this.state.dataExcel?.ingredients?.length, c: 6 },
        e: { r: 13 + this.state.dataExcel?.ingredients?.length, c: 7 },
      },
    ];
    if (this.state.selectedTab)
      merge?.push(
        {
          s: { r: 14 + this.state.dataExcel?.ingredients?.length, c: 0 },
          e: { r: 14 + this.state.dataExcel?.ingredients?.length, c: 1 },
        },
        {
          s: { r: 14 + this.state.dataExcel?.ingredients?.length, c: 2 },
          e: { r: 14 + this.state.dataExcel?.ingredients?.length, c: 3 },
        },

        {
          s: { r: 14 + this.state.dataExcel?.ingredients?.length, c: 4 },
          e: { r: 14 + this.state.dataExcel?.ingredients?.length, c: 5 },
        },

        {
          s: { r: 14 + this.state.dataExcel?.ingredients?.length, c: 6 },
          e: { r: 14 + this.state.dataExcel?.ingredients?.length, c: 7 },
        },

        {
          s: { r: 15 + this.state.dataExcel?.ingredients?.length, c: 0 },
          e: { r: 15 + this.state.dataExcel?.ingredients?.length, c: 1 },
        },
        {
          s: { r: 15 + this.state.dataExcel?.ingredients?.length, c: 2 },
          e: { r: 15 + this.state.dataExcel?.ingredients?.length, c: 3 },
        },

        {
          s: { r: 15 + this.state.dataExcel?.ingredients?.length, c: 4 },
          e: { r: 15 + this.state.dataExcel?.ingredients?.length, c: 5 },
        },

        {
          s: { r: 15 + this.state.dataExcel?.ingredients?.length, c: 6 },
          e: { r: 15 + this.state.dataExcel?.ingredients?.length, c: 7 },
        }
      );
    else
      merge.push({
        s: { r: 14 + this.state.dataExcel?.ingredients?.length, c: 0 },
        e: { r: 14 + this.state.dataExcel?.ingredients?.length, c: 7 },
      });
    ws["!merges"] = merge;
    XLSX.writeFile(
      wb,
      this.state.selectedTab ? "Phiếu nhập kho.xlsx" : "Phiếu xuất kho.xlsx"
    );
  };
  stringDate = (dateNumber) => {
    const date = new Date(dateNumber * 1000);
    return (
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear()
    );
  };

  sumKey = (key, data) => {
    let sum = 0;
    data?.map((e) => {
      sum += Number(e[key] ?? 0);

      return e;
    });

    return sum;
  };

  RenderStoreMaterialIngredientPrice() {
    const data = [];

    const columns = [
      {
        name: "STT",
        selector: (row) => row.no,
        sortable: true,
        center: true,
      },

      {
        name: "Mã số",
        selector: (row) => row.rec_code,
        sortable: true,
      },
      {
        name: "Ngày",
        selector: (row) => row.supplierName,
        sortable: true,
        cell: (row) => this.stringDate(row.rec_date),
      },
      {
        name: "Người thực hiện",
        selector: (row) => row.updater_name,
        sortable: true,
      },
      {
        name: "Tổng tiền",
        selector: (row) => row.updatedAt,
        sortable: true,
        right: true,
        cell: (row) => (
          <CurrencyFormat
            value={Number(row.total_value)}
            decimalScale={0}
            thousandSeparator={true}
            allowNegative={false}
            isNumericString
            displayType="text"
          />
        ),
      },
      {
        name: "Trạng thái",
        selector: (row) => row.is_status,
        sortable: true,
        cell: (row) =>
          row.is_status == 1 ? (
            <span className="text-success">Đã xác nhận</span>
          ) : (
            <Button
              variant="warning"
              title="Bấm xác nhận"
              size="sm"
              onClick={() => this.activeReceipt(row.id)}
            >
              Chờ xác nhận
            </Button>
          ),
      },
      {
        name: "Phương thức",
        selector: (row) => row.setting,
        cell: (row) => (
          <>
            <Button
              title="Chi tiết"
              size="sm"
              className="me-1"
              onClick={() => this.getData(row)}
            >
              <i className="fa-solid fa-circle-info"></i>
            </Button>
            <Button
              size="sm"
              variant="success"
              className="me-1"
              title={
                this.state.selectedTab
                  ? "Xuất phiếu nhập kho"
                  : "Xuất phiếu xuất kho"
              }
              onClick={() => this.getDetail(row.id)}
            >
              <i className="fa-solid fa-file-export"></i>
            </Button>
            <Button
              variant="danger"
              title="Xóa"
              size="sm"
              onClick={() => this.destroyReceipt(row.id, Number(row.is_status))}
            >
              <i className="fa-solid fa-trash"></i>
            </Button>
          </>
        ),
      },
    ];

    const selectedRows = (items) => {
      this.setState({
        selectedRows: items,
      });
    };

    if (this.state.storeMaterialList?.length > 0) {
      this.state.storeMaterialList
        .sort(function (a, b) {
          return b.created_at - a.created_at;
        })
        .map((item, i) => {
          data.push({
            no: i + 1,
            id: item.id,
            rec_code: item.rec_code,
            updater_name: item.updater_name,
            rec_date: item.rec_date,
            total_value: item.total_value,
            is_status: item.is_status,
            created_at: item.created_at,
          });
          return item;
        });
    }

    return (
      <DataTables
        data={data}
        columns={columns}
        selectableRows={this.state.selectedTab ? true : false}
        selectedRows={selectedRows}
        onSelectedRowsChange={(item) => selectedRows(item.selectedRows)}
        selectableRowDisabled={(item) => (item.is_status == 1 ? true : false)}
      />
    );
  }

  render() {
    return (
      <Container fluid>
        <Breadcrumb>
          <Breadcrumb.Item active>Kho hàng</Breadcrumb.Item>
          <Breadcrumb.Item active>Phiếu Nhập-Xuất kho</Breadcrumb.Item>
        </Breadcrumb>
        <Card>
          <Card.Header className="d-block d-md-flex">
            <Card.Title>
              <i className="fas fa-list me-1" />
              Danh sách phiếu Nhập-Xuất kho{" "}
              {/* {this.stringDate(this.state.startDate / 1000)} */}
              {/* -{" "}
              {this.stringDate(this.state.endDate / 1000)} */}
            </Card.Title>

            {/* <Button
              size="sm"
              className="ms-2"
              onClick={() => {
                this.setState({ show: true });
              }}
            >
              <i className="fa-solid fa-calendar-days" />
            </Button> */}
          </Card.Header>

          <Card.Body>
            {!this.state.isLoading ? (
              <>
                <Tabs
                  unmountOnExit={true}
                  transition={false}
                  onSelect={(key) => {
                    this.getlistinventory(
                      Number(key),
                      100,
                      this.state.startDate,
                      this.state.endDate
                    );
                    this.setState({
                      selectedTab: Number(key),
                    });
                  }}
                  activeKey={this.state.selectedTab}
                  className="mb-3"
                >
                  <Tab key={2} title="Nhập kho" eventKey={1}>
                    <Button
                      variant="success"
                      className="mb-2 float-end"
                      title="Xóa"
                      size="sm"
                      onClick={() => this.activeReceipt()}
                    >
                      <i className="fa-solid fa-check" /> Xác nhận
                    </Button>
                    {this.RenderStoreMaterialIngredientPrice()}
                  </Tab>

                  <Tab key={3} title="Xuất kho" eventKey={0}>
                    {this.RenderStoreMaterialIngredientPrice()}
                  </Tab>
                </Tabs>
              </>
            ) : (
              <div className="d-flex justify-content-center align-items-center mt-5">
                <div
                  className="spinner-border text-primary"
                  role="status"
                  style={{ width: "3rem", height: "3rem" }}
                >
                  <span className="sr-only">Đang tải tổng hợp kho/vật tư</span>
                </div>
              </div>
            )}
          </Card.Body>
        </Card>

        <Modal show={this.state.show} size={"sm"}>
          <Modal.Header>
            <Modal.Title>Chọn thời gian</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <center>
              <DatePicker
                size={{ height: "600px" }}
                onChange={(update) => {
                  this.setState({
                    tmpStartDate: update[0],
                    tmpEndDate: update[1],
                  });
                }}
                startDate={this.state.tmpStartDate}
                endDate={this.state.tmpEndDate}
                calendarStartDay={1}
                selectsRange
                inline
              />
            </center>
            <br />
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="sm"
              onClick={() => {
                this.setState({ show: false });
              }}
            >
              Hủy
            </Button>
            <Button
              size="sm"
              disabled={
                this.state.tmpStartDate === null ||
                this.state.tmpEndDate === null
              }
              onClick={() => {
                const start = this.state.tmpStartDate;
                start.setHours(0, 0, 0, 0);

                const end = this.state.tmpEndDate;
                end.setHours(23, 59, 59, 0);

                this.setState({
                  show: false,
                  startDate: start,
                  endDate: end,
                });

                this.getlistinventory(this.state.selectedTab, 100, start, end);
              }}
            >
              Lưu
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.showReceipt}
          onHide={() => this.setState({ showReceipt: false })}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Chi tiết phiếu {this.state.selectedTab ? "nhập kho" : "xuất kho"}{" "}
              {this.stringDate(this.state.dateExport?.rec_date)}{" "}
              <Button
                size="sm"
                variant="success"
                title={
                  this.state.selectedTab
                    ? "Xuất phiếu nhập kho"
                    : "Xuất phiếu xuất kho"
                }
                onClick={() => this.handleExport()}
              >
                {/* Xuất */}
                <i className="fa-solid fa-file-export"></i>
              </Button>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table bordered>
              <thead>
                <tr className="text-center align-middle ">
                  <th rowSpan={2}>STT</th>
                  <th rowSpan={2}>Tên, nhãn hiệu, quy cách, phẩm chất</th>
                  <th rowSpan={2}>Mã số</th>
                  <th rowSpan={2}>Đơn vị tính</th>
                  <th colSpan={2}>Số lượng</th>
                  <th rowSpan={2}>Đơn giá</th>
                  <th rowSpan={2}>Thành tiền</th>
                </tr>
                <tr className="text-center align-middle ">
                  <th>Theo chứng từ</th>
                  <th>{this.state.selectedTab ? "Thực nhập" : "Thực xuất"}</th>
                </tr>
                <tr className="text-center">
                  <th>A</th>
                  <th>B</th>
                  <th>C</th>
                  <th>D</th>
                  <th>1</th>
                  <th>1</th>
                  <th>2</th>
                  <th>3</th>
                </tr>
              </thead>
              <tbody>
                {this.state.dataExcel?.ingredients?.map((item, index) => {
                  return (
                    <tr>
                      <td className="text-center">{index + 1}</td>
                      <td>{item.ingredent_name}</td>
                      <td>{}</td>
                      <td>{item.unit_name}</td>
                      <td>{}</td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={parseFloat(item.ing_quantity)}
                          displayType={"text"}
                          thousandSeparator={true}
                          isNumericString
                          decimalScale={4}
                        />
                      </td>
                      <td className="text-end">
                        {this.formatNumber(item.ing_price)}
                      </td>
                      <td className="text-end">
                        {this.formatNumber(
                          Math.round(
                            Number(item.ing_quantity ?? 0) *
                              Number(item.ing_price ?? 0)
                          )
                        )}
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  {" "}
                  <td>{}</td>
                  <th className="text-center">Cộng</th>
                  <th className="text-center">x</th>
                  <th className="text-center">x</th>
                  <th className="text-center">x</th>
                  <th className="text-center">x</th>
                  <th className="text-center">x</th>
                  <th className="text-end">
                    {this.formatNumber(
                      parseInt(this.state.dataExcel?.receipt?.total_value)
                    )}
                  </th>
                </tr>
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="sm"
              variant="secondary"
              onClick={() => {
                this.setState({ showReceipt: false });
              }}
            >
              <i className="fa-solid fa-times" /> Đóng
            </Button>
          </Modal.Footer>
        </Modal>

        <Table bordered id="tableImportStore" className="d-none">
          <thead>
            <tr>
              <th colSpan={2}>Đơn vị: {this.state.schoolInfo?.school_name} </th>
              <th rowSpan={2} colSpan={3} className="text-center">
                {this.state.selectedTab ? "PHIẾU NHẬP KHO" : "PHIẾU XUẤT KHO"}
              </th>
              <th></th>
              <th colSpan={2}>
                Mẫu số {this.state.selectedTab ? "C30" : "C31"} - HD
              </th>
            </tr>

            <tr>
              <th colSpan={2}>Mã QHNS:....................................</th>
              <th></th>
              <th rowSpan={2} colSpan={2}>
                (Ban hành kèm theo thông tư 107/2017TT-BTC ngày 24/11/2017)
              </th>
            </tr>
            <tr>
              <th></th>
              <th></th>
              <th>
                Ngày{" "}
                {new Date(
                  this.state.dataExcel?.receipt?.rec_date * 1000
                ).getDate()}
              </th>
              <th>
                tháng{" "}
                {new Date(
                  this.state.dataExcel?.receipt?.rec_date * 1000
                ).getMonth() + 1}
              </th>
              <th>
                năm{" "}
                {new Date(
                  this.state.dataExcel?.receipt?.rec_date * 1000
                ).getFullYear()}
              </th>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td>Số:..................</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              {this.state.selectedTab ? (
                <td colSpan={7}>
                  Họ và tên người
                  giao:............................................................................................................
                </td>
              ) : (
                <td colSpan={7}>
                  Họ và tên người nhận
                  hàng:..................................... Địa chỉ (bộ
                  phận):....................................
                </td>
              )}
            </tr>
            <tr>
              <td></td>
              {this.state.selectedTab ? (
                <td colSpan={7}>
                  Theo:..................................số....... ngày.....
                  tháng..... năm.........
                  của......................................
                </td>
              ) : (
                <td colSpan={7}>
                  Lý do xuất
                  kho:......................................................................................................................
                </td>
              )}
            </tr>
            <tr>
              <td></td>
              {this.state.selectedTab ? (
                <td>
                  Nhập tại kho:.................................................
                  Địa
                  điểm:........................................................
                </td>
              ) : (
                <td>
                  Xuất kho tại (ngăn lô):.....................................
                  Địa
                  điểm:........................................................
                </td>
              )}
            </tr>
            <tr className="text-center">
              <th>STT</th>
              <th>Tên, nhãn hiệu, quy cách, phẩm chất</th>
              <th>Mã số</th>
              <th>Đơn vị tính</th>
              <th>Số lượng</th> <td>empty</td>
              <th>Đơn giá</th>
              <th>Thành tiền</th>
            </tr>
            <tr>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>

              <th>Theo chứng từ</th>
              <th>{this.state.selectedTab ? "Thực nhập" : "Thực xuất"}</th>
              <td>empty</td>
              <td>empty</td>
            </tr>
            <tr>
              <th>A</th>
              <th>B</th>
              <th>C</th>
              <th>D</th>
              <th>1</th>
              <th>1</th>
              <th>2</th>
              <th>3</th>
            </tr>
          </thead>
          <tbody>
            {this.state.dataExcel?.ingredients?.map((item, index) => {
              return (
                <tr>
                  <td>{this.changeEmpty(index + 1)}</td>
                  <td>{this.changeEmpty(item.ingredent_name)}</td>
                  <td>{this.changeEmpty("")}</td>
                  <td>{this.changeEmpty(item.unit_name)}</td>
                  <td>{this.changeEmpty("")}</td>
                  <td>
                    {this.changeEmpty(this.formatter.format(item.ing_quantity))}
                  </td>
                  <td>{this.changeEmpty(this.formatNumber(item.ing_price))}</td>
                  <td>
                    {this.changeEmpty(
                      this.formatNumber(
                        Number(item.ing_quantity ?? 0) *
                          Number(item.ing_price ?? 0)
                      )
                    )}
                  </td>
                </tr>
              );
            })}
            <tr>
              <td>{this.changeEmpty("")}</td>
              <td>Cộng</td>
              <td>x</td>
              <td>x</td>
              <td>x</td>
              <td>x</td>
              <td>x</td>
              <td>
                {this.changeEmpty(
                  this.formatNumber(
                    parseInt(this.state.dataExcel?.receipt?.total_value)
                  )
                )}
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                Tổng số tiền (viết bằng chữ):{" "}
                {this.capitalizeFirstLetter(
                  this.soThanhChu(
                    parseInt(this.state.dataExcel?.receipt?.total_value)
                  )
                )}
                .
              </td>
            </tr>
            <tr>
              <td></td>
              <td colSpan={7}>Số chứng từ gốc kèm theo:...... </td>
            </tr>

            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>Ngày.... tháng.... năm......</td>
              <td></td>
            </tr>
            {this.state.selectedTab ? (
              <>
                <tr>
                  <td>Người lập</td>
                  <td></td>
                  <td>Người giao hàng</td>
                  <td></td>
                  <td>Thủ kho</td>
                  <td></td>
                  <td>Kế toán trưởng</td>
                </tr>
                <tr>
                  <td>(Ký, họ tên)</td>
                  <td></td>
                  <td>(Ký, họ tên)</td>
                  <td></td>
                  <td>(Ký, họ tên)</td>
                  <td></td>
                  <td>(Ký, họ tên)</td>
                  <td></td>
                </tr>
              </>
            ) : (
              <>
                <tr>
                  <td>
                    Người lập &nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Người nhận hàng
                    &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Thủ kho
                    &nbsp; &nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Kế toán trưởng
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Thủ
                    trưởng đơn vị
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr></tr>
              </>
            )}
          </tbody>
        </Table>
      </Container>
    );
  }
}

export default Receipt;
