import React, { Component } from "react";
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Container,
  Col,
  Form,
  InputGroup,
  Row,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert";
import DataTables from "../datatable/DataTables";
import FormatData from "../FormatData";
import StudentCreate from "./StudentCreate";
import StudentEdit from "./StudentEdit";
import { useNavigate, useParams } from "react-router-dom";
import * as XLSX from "xlsx-js-style";
import Check from "../other/Check";
import Select from "react-select";
import { Checkbox } from "@mui/material";
import api from "../../../helper/axiosInstance";
class StudentIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      students: [],
      createmodalshow: false,
      editModalShow: false,
      alertShow: false,
      selectedRows: [],
      student: [],
      listclass: [],
      filterText: "",
      studentListFile: null,
      studentitem: [],
      isAddStudentListLoading: false,
      checkload: true,
      dataExport: [],
      dataSelectclass: [],
      month: {
        value: 0,
        label: "Tất cả",
      },
      classS: {
        value: -1,
        label: "Tất cả lớp",
      },
      dataselectMonth: [],
      dataSearch: [],
      classN: [],
      bntClick: false,
      isDelete: false,
      arrid: "",
      dataFilterStudent: [],
      listEthnic: [],
    };
  }

  async componentDidMount() {
    document.title = "Quản lý học sinh";
    this.getStudentsData();
    this.getlistclass();
    this.getStudentExport();
    this.selectMonth();
    this.getEthnic();
  }
  componentDidUpdate() {}
  getEthnic = () => {
    api
      .get("/viewEthnic")
      .then((res) => this.setState({ listEthnic: res.data.data }));
  };
  selectMonth = () => {
    let viewSelectMonth = [];
    // viewSelectMonth.push({ value: 0, label: "Tất cả" })
    viewSelectMonth.push({ value: 0, label: "Tất cả" });
    for (let i = 1; i <= 12; i++) {
      viewSelectMonth.push({ value: i, label: i });
    }
    this.setState({ dataselectMonth: viewSelectMonth });
  };
  // Xuất không chuyển trang
  getStudentsData = () => {
    api.get(`/viewStudent`).then((res) => {
      if (res.data.status === true) {
        this.setState({ checkload: false });
        this.setState({ students: res.data.data });
        // this.setState({ dataSearch: res.data.data });
        const choice = this.state.month;

        if (choice.value > 0) {
          const dateChoice = res.data.data.filter(
            (item) =>
              new Date(item.student_birthday * 1000).getMonth() + 1 ==
              choice.value
          );
          if (this.state.classS.value >= 0) {
            this.setState({
              dataSearch: dateChoice.filter(
                (item) => this.state.classS.value == item.id_class
              ),
            });
            this.getStudentsDataExport(
              dateChoice.filter(
                (item) => this.state.classS.value == item.id_class
              )
            );
          } else {
            this.setState({
              dataSearch: dateChoice,
            });
            this.getStudentsDataExport(dateChoice);
          }
        } else {
          if (this.state.classS.value >= 0) {
            this.setState({
              dataSearch: res.data.data.filter(
                (item) => this.state.classS.value == item.id_class
              ),
            });
            this.getStudentExport(
              this.state.students.filter(
                (item) => this.state.classS.value == item.id_class
              )
            );
          } else {
            this.setState({
              dataSearch: res.data.data,
            });
            this.getStudentExport(res.data.data);
          }
        }
      }
    });
  };

  getStudentExport = () => {
    api.get(`/viewStudent`).then((res) => {
      if (res.data.status) {
        this.getStudentsDataExport(res.data.data);
        this.setState({ checkload: false });
      }
    });
  };

  getStudentsDataExport = (students) => {
    api.get(`/viewClassRoom`).then((res) => {
      if (res.data.status) {
        let rs = res.data.data.map((class_) => {
          return {
            value: class_.id,
            label: class_.class_name,
          };
        });

        var dataT = [];

        dataT = res.data.data.map((class_) => {
          return {
            value: class_.id,
            label: class_.class_name,
          };
        });
        dataT.push({ value: -1, label: "Tất cả lớp" });
        dataT.push({ value: 0, label: "Không có lớp" });
        dataT.sort(function (a, b) {
          if (a.value <= b.value) return -1;
          if (a.value > b.value) return 1;
          return 0;
        });
        this.setState({
          dataSelectclass: dataT,
        });

        // const dataClass = res.data.data
        let arr_ = [];
        var arrStu = [];
        arrStu =
          this.state.filterText.trim() != ""
            ? this.state.dataFilterStudent
            : this.state.dataSearch;
        arrStu.map((stu) => {
          arr_.push(stu.class_name);
        });
        const dataClass = Array.from(new Set(arr_));
        this.setState({ classN: dataClass });

        // var arrT=dataClass
        this.setState({
          dataExport: dataClass?.map((item) => {
            return {
              id_class: arrStu.filter((e) => e.class_name == item)[0]?.id_class,
              class_name: item,
              user_name: this.state.listclass.filter(
                (e) => e.class_name == item
              )[0]?.user_name,
              group_name: this.state.listclass.filter(
                (e) => e.class_name == item
              )[0]?.group_name,
              students: arrStu.filter((e) => e.class_name === item),
            };
          }),
        });
      }
    });
  };
  handleExport_ = () => {
    swal({
      title: `Xác nhận`,
      text: `Xuất danh sách học sinh   `,
      icon: "info",
      buttons: ["Đóng", "Xuất"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        this.export_();
      }
    });
  };
  export_ = () => {
    const wb = XLSX.utils.book_new();

    // this.state.listclass?.map((item, index) => {
    //   if (item.class_name != null)
    //     XLSX.utils.book_append_sheet(wb, this.book1(item.class_name), item.class_name);

    // })
    this.state.classN?.map((item, index) => {
      if (item != null)
        XLSX.utils.book_append_sheet(wb, this.book1(item), item);
    });

    XLSX.writeFile(wb, "Danh sách học sinh.xlsx");
  };
  book1 = (class_name) => {
    if (class_name !== "") {
      const table1 = document.getElementById(`ds-${class_name}`);
      var ws1 = XLSX.utils.table_to_sheet(table1, { raw: true });

      ws1["!cols"] = [
        { wch: 5 },
        { wch: 25 },
        { wch: 25 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 11 },
        { wch: 25 },
        { wch: 15 },
        { wch: 10 },
        { wch: 10 },
        { wch: 25 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 25 },
        { wch: 15 },
        { wch: 11 },
        { wch: 25 },
        { wch: 15 },
        { wch: 11 },
        { wch: 15 },
        { wch: 25 },
      ];

      const row = [];
      ws1[`!merges`] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 25 } }];
      ws1["!merges"].push({ s: { r: 1, c: 0 }, e: { r: 1, c: 25 } });

      const colAlpha = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      ws1["A1"].s = {
        font: { name: "Times New Roman", bold: true },
        alignment: { horizontal: "center" },
      };
      ws1["A2"].s = {
        font: { name: "Times New Roman", bold: true },
        alignment: { horizontal: "center" },
      };
      for (var i = 0; i < colAlpha.length; i++) {
        ws1[`${colAlpha[i]}3`].s = {
          font: { name: "Times New Roman", bold: true },
          alignment: { horizontal: "center" },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        };
      }

      // const borderStyle = { border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } } };
      // const range = XLSX.utils.decode_range(ws1['!ref']);
      // for (let row = range.s.r; row <= range.e.r; row++) {
      //   for (let col = range.s.c; col <= range.e.c; col++) {
      //     const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
      //     const cell = ws1[cellAddress];
      //     if (!cell.s) cell.s = {};
      //     cell.s = { ...cell.s, ...borderStyle };
      //     if (cell.v === '' && cell.t === 's') {
      //       cell.s.fill = { fgColor: { rgb: 'FFFF0000' } }; // Tô màu đỏ cho ô có giá trị rỗng
      //     }
      //   }
      // }
      const borderStyle = {
        font: { name: "Times New Roman" },
        alignment: { wrapText: true },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };
      const range = XLSX.utils.decode_range(ws1["!ref"]);

      const startRow = 3; // Dòng bắt đầu từ A3
      const endRow = range.e.r; // Dòng kết thúc
      // XLSX.utils.sheet_set_range_style(ws1, range, borderStyle);
      for (let row = startRow; row <= endRow; row++) {
        for (let col = range.s.c; col <= range.e.c; col++) {
          const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
          const cell = ws1[cellAddress];
          if (cell.s == undefined) cell.s = {};
          // if(cell.v=='')
          // console.log(cell.v=='')
          cell.s = { ...cell.s, ...borderStyle };
          if (cell.v === "empty") {
            cell.v = "";
            // console.log(cellAddress)
            // ws1[cellAddress].s
            // cell.s.fill = { fgColor: { rgb: 'FFFF0000' } }; // Tô màu đỏ cho ô có giá trị rỗng
          }
        }
      }

      ws1["!rows"] = row;
      return ws1;
    }
  };
  changeEmpty = (element) => {
    return element == "" ||
      element == null ||
      element == "null" ||
      element == undefined ||
      element == "undefined" ||
      element == "aN/aN/NaN"
      ? "empty"
      : element;
  };
  getStudentItem = (id) => {
    api
      .post(`/detailStudent?id_student=${id}`)
      .then((res) => {
        if (res.data.status) {
          this.setState({ editModalShow: true });
          this.setState({ studentitem: res.data.data });
        }
      })
      .catch((error) => {});
  };

  handleOpenEdit = (id) => {
    this.getStudentItem(id);
  };
  getlistclass = () => {
    api
      .get(`/viewClassRoom`)
      .then((res) => {
        if (res.data.status) {
          this.setState({
            listclass: res.data.data,
          });
        }
      })
      .catch((error) => {});
  };

  deleteStudent = (id, event) => {
    swal({
      title: "Xác nhận xóa học sinh",
      icon: "error",
      buttons: true,
      successMode: true,
    }).then((ok) => {
      if (ok) {
        api
          .post(`/deleteStudent?id_student=${id}`)
          .then((res) => {
            if (res.data.status) {
              this.getStudentsData();
              toast(`${res.data.data.mess}`, {
                type: "success",
                autoClose: 1000,
              });
            }
          })
          .catch((error) => {});
      }
    });
  };

  handleImportStudentListExcel = () => {
    var bodyFormData = new FormData();
    bodyFormData.append("student_excel", this.state.studentListFile);

    this.setState({
      isAddStudentListLoading: true,
    });

    api
      .post(`/importStudent`, bodyFormData)
      .then((res) => {
        if (res.data.status === true) {
          this.getStudentsData();

          toast("Lưu thành công", {
            type: "success",
            autoClose: 2000,
          });

          this.setState({
            isAddStudentListLoading: false,
            studentListFile: null,
          });

          document.getElementById("studentlistFile").value = "";
        } else {
          toast(res.data.data.mess, {
            type: "error",
            autoClose: 2000,
          });

          this.setState({
            isAddStudentListLoading: false,
          });
        }
      })
      .catch((error) => {});
  };

  handleSelectFile = (e) => {
    const fileExtention = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    if (!fileExtention.some((ex) => ex === e.target.files[0].type)) {
      toast("Sai định dạng tệp Excel !!!", {
        type: "error",
        autoClose: 3000,
      });
      return;
    }

    this.setState({
      studentListFile: e.target.files[0],
    });
  };

  getDate = (data) => {
    const date = new Date(data * 1000);
    date.setHours(0, 0, 0, 0);
    return (
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear()
    );

    // let date = new Date(data * 1000);
    // return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };

  deleteMore = (arrStudent) => {
    var arr1 = [];
    for (var i = 0; i < arrStudent.length; i++) {
      arr1.push(arrStudent[i].id);
    }
    var arr2 = "";
    arr2 = arr1.join(",");
    this.setState({ arrid: arr2 });
  };

  deleteMoreByArrId = (arrID) => {
    swal({
      title: "Xác nhận xóa những học sinh đã chọn",
      icon: "error",
      buttons: true,
      successMode: true,
    }).then((ok) => {
      if (ok) {
        api
          .post(`/deleteMoreStudent?id_student=${arrID}`)
          .then((res) => {
            if (res.data.status) {
              this.setState({ isDelete: false });
              this.getStudentsData();
              toast(`${res.data.data.mess}`, {
                type: "success",
                autoClose: 1000,
              });
            }
          })
          .catch((error) => {});
      }
    });
  };

  dataTable() {
    const columns = [
      {
        name: "Lớp",
        selector: (row) => row.class_name,
        sortable: true,
        style: {
          div: {
            width: "100px",
          },
        },
      },
      {
        name: "Họ và tên",
        selector: (row) => row.student_name,
        sortable: true,
        style: {
          div: {
            // background:'red',

            overflow: "inherit !important ",
            width: "200px",
          },
        },
      },

      {
        name: "Giới tính",
        selector: (row) => row.gender_name,
        sortable: true,
        center: true,
      },
      {
        name: "Ngày sinh",
        selector: (row) => row.student_birthday,

        // sortable: true,
        // right: true,
      },
      {
        name: "Trạng thái",
        selector: (row) => row.student_status,
        sortable: true,
        // right: true,
      },
      {
        name: "Phương thức",
        selector: (row) => row.setting,
        center: true,
      },
    ];

    const data = [];

    const selectedRows = (items) => {
      this.setState({ selectedRows: items, alertShow: true });
    };

    if (this.state.dataSearch.length > 0) {
      this.state.dataSearch.map((item, i) => {
        // const editModalShow = async () => {
        //   this.setState({ editModalShow: true });
        //   this.setState({
        //     student: item,
        //   });
        // };

        // let updated_at = new FormatData().date(item.updated_at);

        data.push({
          id: item.id,
          class_name: item.class_name,
          gender_name: item.gender_name,
          student_name: item.student_name,
          student_birthday: this.getDate(item.student_birthday),
          student_status:
            item.student_status == 1
              ? "Đang học"
              : `Tạm nghỉ (${this.getDate(
                  item.process[item.process.length - 1]?.end_at
                )})`,
          setting: (
            <div div style={{ display: "flex" }}>
              {new Check().permission(["17", "7", "12"]) ? (
                <Button
                  variant="warning me-2"
                  size="sm"
                  type="button"
                  // style={{color:'white'}}
                  onClick={() => {
                    // this.loading()
                    this.handleOpenEdit(item.id);
                  }}
                  title="Cập nhật"
                >
                  <i className="fa-solid fa-pencil"></i>
                </Button>
              ) : null}
              {new Check().permission(["21"]) ? (
                <Button
                  variant="danger"
                  type="button"
                  size="sm"
                  onClick={() => {
                    this.deleteStudent(item.id);
                  }}
                  title="Xóa "
                >
                  <i className="fas fa-trash"></i>
                </Button>
              ) : null}
            </div>
          ),
        });

        return item;
      });
    }

    const filteredItems = data.filter((item) => {
      if (
        new FormatData()
          .toSlug(item.student_name)
          .includes(new FormatData().toSlug(this.state.filterText.trim()))
        //  ||
        // (new FormatData().toSlug(item.class_name)).includes(new FormatData().toSlug(this.state.filterText)) ||
        // item.student_birthday.slice(item.student_birthday.indexOf("/") + 1,
        // item.student_birthday.indexOf("/", item.student_birthday.indexOf("/") + 1)) == this.state.filterText
      ) {
        return item;
      }
      return false;
    });

    const setDta = (filteredItems) => {
      this.setState({
        dataFilterStudent: this.state.dataSearch.filter((b) =>
          filteredItems.some((a) => a.id == b.id)
        ),
      });
      this.getStudentsDataExport(
        this.state.dataSearch.filter((b) =>
          filteredItems.some((a) => a.id == b.id)
        )
      );
    };
    const onChangeClass = (choice) => {
      this.setState({ filterText: "" });
      this.setState({ classS: choice });
      choice.value == -1 && this.state.month.value == 0
        ? this.setState({
            dataSearch: this.state.students,
          })
        : choice.value == -1 && this.state.month.value >= 1
        ? this.setState({
            dataSearch: this.state.students.filter(
              (item) =>
                this.state.month.value ==
                new Date(item.student_birthday * 1000).getMonth() + 1
            ),
          })
        : this.state.month.value >= 1
        ? this.setState({
            dataSearch: this.state.students.filter(
              (item) =>
                item.id_class == choice.value &&
                this.state.month.value ==
                  new Date(item.student_birthday * 1000).getMonth() + 1
            ),
          })
        : this.setState({
            dataSearch: this.state.students.filter(
              (item) => item.id_class == choice.value
            ),
          });
      choice.value == -1 && this.state.month.value == 0
        ? this.getStudentExport(this.state.students)
        : choice.value == -1 && this.state.month.value >= 1
        ? this.getStudentExport(
            this.state.students.filter(
              (item) =>
                this.state.month.value ==
                new Date(item.student_birthday * 1000).getMonth() + 1
            )
          )
        : this.state.month.value > 0
        ? this.getStudentsDataExport(
            this.state.students.filter(
              (item) =>
                item.id_class == choice.value &&
                this.state.month.value ==
                  new Date(item.student_birthday * 1000).getMonth() + 1
            )
          )
        : this.getStudentsDataExport(
            this.state.students.filter((item) => item.id_class == choice.value)
          );
    };

    const onChangeMonth = (choice) => {
      this.setState({ filterText: "" });
      this.setState({ month: choice });

      if (choice.value > 0) {
        const dateChoice = this.state.students.filter(
          (item) =>
            new Date(item.student_birthday * 1000).getMonth() + 1 ==
            choice.value
        );
        if (this.state.classS.value >= 0) {
          this.setState({
            dataSearch: dateChoice.filter(
              (item) => this.state.classS.value == item.id_class
            ),
          });
          this.getStudentsDataExport(
            dateChoice.filter(
              (item) => this.state.classS.value == item.id_class
            )
          );
        } else {
          this.setState({
            dataSearch: dateChoice,
          });
          this.getStudentsDataExport(dateChoice);
        }
      } else {
        if (this.state.classS.value >= 0) {
          this.setState({
            dataSearch: this.state.students.filter(
              (item) => this.state.classS.value == item.id_class
            ),
          });
          this.getStudentExport(
            this.state.students.filter(
              (item) => this.state.classS.value == item.id_class
            )
          );
        } else {
          this.setState({
            dataSearch: this.state.students,
          });
          this.getStudentExport(this.state.students);
        }
      }
    };

    return (
      <>
        {/* <Row>
          <Col md={3} sm={6} className="mb-2">
            <InputGroup>
              <Form.Control onChange={(e) => this.setState({ filterText: e.target.value })} value={this.state.filterText} placeholder="Nhập lớp, tên hoặc tháng sinh." />

              <InputGroup.Text><i className="fa-solid fa-search me-1" /></InputGroup.Text>
            </InputGroup >
          </Col>
        </Row> */}
        <Row style={{ justifyContent: "flex-start" }}>
          <Col md={2} style={{ zIndex: 3 }} className="mb-2">
            <Select
              options={this.state.dataSelectclass}
              defaultValue={this.state.dataSelectclass[0]}
              value={this.state.classS}
              onChange={(choice) => {
                onChangeClass(choice);
              }}
            ></Select>
          </Col>

          <Col md={3} sm={6} className="mb-2">
            <InputGroup style={{ height: "38px" }}>
              <Form.Control
                onChange={(e) => {
                  // this.setState({
                  //   month: {
                  //     value: 0, label: "Tháng sinh nhật"
                  //   }, classS: {
                  //     value: 0, label: "Tất cả lớp"
                  //   }
                  // })
                  this.setState({ filterText: e.target.value });
                  // searchStudentByName(e.target.value)
                  setDta(filteredItems);
                }}
                value={this.state.filterText}
                placeholder="Nhập tên học sinh"
              />

              {/* <InputGroup.Text><i className="fa-solid fa-search me-1" /></InputGroup.Text> */}
            </InputGroup>
          </Col>

          <Col md={7} style={{ zIndex: 2, display: "flex", zIndex: 2 }}>
            <span style={{ padding: "10px", whiteSpace: "nowrap" }}>
              Sinh nhật tháng :
            </span>
            <Select
              options={this.state.dataselectMonth}
              defaultValue={this.state.dataselectMonth[0]}
              value={this.state.month}
              onChange={(choice) => {
                onChangeMonth(choice);
              }}
            ></Select>
          </Col>
        </Row>
        <DataTables
          data={filteredItems}
          columns={columns}
          selectedRows={selectedRows}
          // className="striped-rows"

          selectableRows={this.state.isDelete}
          onSelectedRowsChange={(item) => this.deleteMore(item.selectedRows)}
        />
      </>
    );
  }

  renderSelectedRows() {
    let num = this.state.selectedRows.length;
    if (num > 0 && this.state.alertShow === true) {
      return (
        <Alert
          style={{
            position: "absolute",
            zIndex: "1",
            left: "91%",
          }}
          variant="info mb-0"
          onClose={() => this.setState({ alertShow: false })}
          dismissible
        >
          {num} mục đã chọn.
        </Alert>
      );
    }
  }

  renderStudentList() {
    const addModalClose = () => {
      this.setState({
        addModalShow: false,
      });
      this.setState({ bntClick: true });
      this.getStudentsData();
      this.setState({
        filterText: "",
        month: {
          value: 0,
          label: "Tất cả tháng",
        },
        classS: {
          value: -1,
          label: "Tất cả lớp",
        },
      });
    };
    const editModalClose = () => {
      this.setState({
        editModalShow: false,
      });
      this.setState({
        // filterText: '',
        // month: {
        //   value: 0, label: "Tháng sinh nhật"
        // },
        // classS: {
        //   value: 0, label: "Tất cả lớp"
        // },
      });
      this.getStudentsData();
    };

    return (
      <>
        <Card.Header>
          <div className="d-block d-xl-flex justify-content-between">
            <Card.Title>
              <i className="fas fa-list me-1"></i> Danh sách học sinh
            </Card.Title>
            {/* {new Check().permission(['19']) ? */}

            {this.state.isDelete ? (
              <div>
                <Button
                  size="sm"
                  onClick={() => this.setState({ isDelete: false })}
                  variant="secondary"
                  className="me-2"
                >
                  Hủy
                </Button>
                <Button
                  size="sm"
                  onClick={() => {
                    this.deleteMoreByArrId(this.state.arrid);
                  }}
                  variant="danger"
                  disabled={this.state.arrid == ""}
                >
                  Xóa
                </Button>
              </div>
            ) : this.state.isAddStudentListLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <div className="me-3">
                  <div>Đang thêm mới học sinh...</div>
                </div>
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="d-block d-xl-flex my-3 my-md-0">
                <div className="d-block d-md-flex my-xl-0">
                  {new Check().permission(["18"]) ? (
                    <>
                      <label
                        className="btn btn-success me-3"
                        style={{ fontSize: 12, cursor: "pointer" }}
                        htmlFor="studentlistFile"
                      >
                        <i className="fas fa-file-import"></i> Nhập DSHS
                      </label>
                      <input
                        type="file"
                        id="studentlistFile"
                        className="d-none"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        multiple={false}
                        onChange={(e) => this.handleSelectFile(e)}
                        disabled={this.state.isAddStudentListLoading}
                      />
                    </>
                  ) : null}
                  {this.state.studentListFile ? (
                    <div className="d-flex mt-3 mt-md-0">
                      <div
                        style={{ minWidth: 100 }}
                        className="d-flex align-items-center text-truncate me-3"
                      >
                        Đã chọn: {this.state.studentListFile.name}
                      </div>

                      <Button
                        type="button"
                        variant="success"
                        size="sm"
                        className="me-3"
                        onClick={() => this.handleImportStudentListExcel()}
                        disabled={this.state.isAddStudentListLoading}
                      >
                        Lưu
                      </Button>

                      <Button
                        type="button"
                        variant="secondary"
                        size="sm"
                        className="me-3"
                        onClick={() => {
                          document.getElementById("studentlistFile").value = "";
                          this.setState({
                            studentListFile: null,
                          });
                        }}
                        disabled={this.state.isAddStudentListLoading}
                      >
                        Hủy
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                {this.state.studentListFile ? (
                  ""
                ) : (
                  <>
                    <Button
                      type="button"
                      variant="success"
                      size="sm"
                      // className="me-3 "
                      className="me-3 btn-dels"
                      onClick={() => this.handleExport_()}
                      hidden={
                        this.state.classS.value == 0 ||
                        this.state.dataExport.length == 0
                      }
                    >
                      <i className="fa-regular fa-cloud-arrow-down"></i> Xuất
                      DSHS
                    </Button>

                    <Button
                      type="button"
                      variant="success"
                      size="sm"
                      className="me-3 btn-dels"
                      href="/File mẫu danh sách học sinh.xlsx"
                      target="_blank"
                      download
                    >
                      <i className="fa-solid fa-download"></i> Tải file mẫu
                    </Button>

                    {new Check().permission(["21"]) ? (
                      <Button
                        size="sm"
                        variant="secondary"
                        // type="button"
                        onClick={() => this.setState({ isDelete: true })}
                        title="Chọn xóa nhiều"
                        hidden={this.state.students.length == 0}
                        // className="me-3"
                        className="btn-del me-3"
                      >
                        <i className="fas fa-trash"></i> Chọn xóa nhiều
                      </Button>
                    ) : null}

                    {new Check().permission(["19", "7", "12"]) ? (
                      <Button
                        type="button"
                        variant="success"
                        size="sm"
                        className="btn-del me-3"
                        onClick={() => this.setState({ addModalShow: true })}
                      >
                        <i className="fa-solid fa-plus"></i> Thêm
                      </Button>
                    ) : null}
                  </>
                )}
              </div>
            )}
          </div>
        </Card.Header>
        <Card.Body>
          {this.state.checkload ? this.loading() : this.dataTable()}

          {this.state.dataExport?.map((student) => {
            return (
              <Table
                bordered
                id={`ds-${student.class_name}`}
                className="d-none"
              >
                <tr>
                  <th> Danh sách học sinh lớp {student.class_name}</th>
                </tr>
                <tr>
                  <th>Năm học: 2022 - 2023 </th>
                </tr>
                <tr>
                  <th className="align-middle text-center">STT</th>
                  <th className="align-middle text-center">
                    {" "}
                    Mã định danh Bộ GD&ĐT
                  </th>

                  <th
                    className="align-middle text-center"
                    style={{ width: "50px" }}
                  >
                    Họ và tên
                  </th>
                  <th
                    className="align-middle text-center"
                    style={{ width: "80px" }}
                  >
                    {" "}
                    Ngày sinh
                  </th>
                  <th className="align-middle text-center">Giới tính</th>
                  <th className="align-middle text-center"> Dân tộc</th>
                  <th className="align-middle text-center"> Ngày nhập học</th>
                  <th className="align-middle text-center"> Ngày nghỉ học</th>
                  <th className="align-middle text-center">SĐT liên hệ</th>
                  <th className="align-middle text-center"> Tên giáo viên</th>
                  <th className="align-middle text-center"> Nhóm học sinh</th>
                  <th className="align-middle text-center"> Số nhà</th>
                  <th className="align-middle text-center"> Tổ</th>
                  <th className="align-middle text-center"> Khu vực </th>
                  <th className="align-middle text-center">Đường </th>
                  <th className="align-middle text-center"> Phường</th>
                  <th className="align-middle text-center"> Quận</th>
                  <th className="align-middle text-center">Tỉnh, TP </th>
                  <th className="align-middle text-center">Họ và tên cha</th>
                  <th className="align-middle text-center">
                    {" "}
                    Nghề nghiệp cha{" "}
                  </th>
                  <th className="align-middle text-center"> Điện thoại</th>
                  <th className="align-middle text-center">Họ và tên mẹ</th>
                  <th className="align-middle text-center"> Nghề nghiệp mẹ </th>
                  <th className="align-middle text-center"> Điện thoại</th>
                  <th className="align-middle text-center"> Ghi chú</th>
                  <th className="align-middle text-center">
                    Mã định danh dân cư{" "}
                  </th>
                </tr>
                {student?.students?.map((item, index) => {
                  // const numHome =  item.address !=null && item.address.slice("-");
                  return (
                    <tr key={item.id}>
                      <td className="text-center">{index + 1}</td>
                      <td>{this.changeEmpty(item.student_identifier)}</td>
                      {/* <td>{item.class_name}</td> */}
                      <td style={{ width: "250px" }}>{item.student_name}</td>
                      <td>{this.getDate(item.student_birthday)}</td>
                      <td>{this.changeEmpty(item.gender_name)}</td>
                      <td>
                        {" "}
                        {this.changeEmpty(
                          this.state.listEthnic.filter(
                            (e) => e.id == item.student_ethnic
                          )[0]?.ethnic_name
                        )}
                      </td>
                      {/* <td>{this.getDate(item?.process[0]?.start_at)}</td> */}
                      <td>
                        {this.changeEmpty(
                          this.getDate(item?.process[0]?.start_at)
                        )}{" "}
                      </td>
                      <td>
                        {this.changeEmpty(
                          this.getDate(item?.end_at) == "null"
                            ? ""
                            : this.getDate(item?.end_at)
                        )}{" "}
                      </td>
                      <td>
                        {this.changeEmpty(
                          item.student_contact == "null"
                            ? ""
                            : item.student_contact
                        )}
                      </td>
                      {/* <td>{item.student_status == 'null' ? "" : item.student_status}</td> */}
                      <td>{this.changeEmpty(student.user_name)}</td>
                      <td>{this.changeEmpty(student.group_name)}</td>
                      <td> {this.changeEmpty(item.address.number)} </td>
                      <td> {this.changeEmpty(item.address.group)} </td>
                      <td> {this.changeEmpty(item.address.zone)} </td>
                      <td> {this.changeEmpty(item.address.street)} </td>
                      <td> {this.changeEmpty(item.address.ward)} </td>
                      <td> {this.changeEmpty(item.address.district)} </td>
                      <td> {this.changeEmpty(item.address.city)} </td>
                      <td> {this.changeEmpty(item.parent.name)} </td>
                      <td> {this.changeEmpty(item.parent.job)} </td>
                      <td> {this.changeEmpty(item.parent.phone)} </td>
                      <td> {this.changeEmpty(item.mother.name)} </td>
                      <td> {this.changeEmpty(item.mother.job)} </td>
                      <td> {this.changeEmpty(item.mother.phone)} </td>

                      {/*  */}
                      {/* {this.renderAddress(item.address)}
                        {this.renderFamily(item.parent)}
                        {this.renderFamily(item.mother)} */}
                      <td>
                        {this.changeEmpty(
                          item.address == null
                            ? ""
                            : item.student_note == "null"
                            ? ""
                            : item.student_note
                        )}
                      </td>
                      <td>
                        {this.changeEmpty(
                          item.address == null
                            ? ""
                            : item.student_population == "null"
                            ? ""
                            : item.student_population
                        )}
                      </td>
                    </tr>
                  );
                })}
              </Table>
            );
          })}
        </Card.Body>

        <StudentCreate
          show={this.state.addModalShow}
          onHide={addModalClose}
          bntClick={this.state.bntClick}
          listclass={this.state.listclass}
          ableForm={{
            value:
              this.state.studentitem[0]?.student_status == "1" ? false : true,
          }}
        />

        <StudentEdit
          show={this.state.editModalShow}
          onHide={editModalClose}
          listclass={this.state.listclass}
          obj={{ student: this.state.studentitem }}
          ableForm={{
            value:
              this.state.studentitem[0]?.student_status == "1" ? false : true,
          }}
        />
      </>
    );
  }

  renderAddress(address) {
    const splitAdress = address ? address.split("-") : 0;
    for (var i = 0; i <= 7; i++) {
      return (
        <td>
          {splitAdress.length > 0
            ? splitAdress[i] != "null"
              ? splitAdress[i]
              : ""
            : ""}
        </td>
      );
    }
  }

  //1: parent , 2 : mother
  renderFamily(data) {
    const renderFamily = data ? data.split("-") : 0;
    for (var i = 0; i <= 3; i++) {
      return (
        <td>
          {renderFamily.length > 0
            ? renderFamily[i] != "null"
              ? renderFamily[i]
              : ""
            : ""}
        </td>
      );
    }
  }
  render() {
    return (
      <>
        <Container fluid>
          {this.renderSelectedRows()}
          <Breadcrumb className="mb-2 mt-2">
            <Breadcrumb.Item active>Học sinh</Breadcrumb.Item>
            <Breadcrumb.Item active>Danh sách</Breadcrumb.Item>
          </Breadcrumb>
          <Card>{this.renderStudentList()}</Card>
        </Container>
      </>
    );
  }
}
function myParams(Component) {
  //Back link AND use "params"
  return (props) => (
    <Component navHook={useNavigate()} {...props} params={useParams()} />
  );
}

export default myParams(StudentIndex);
