import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler, // Thêm Filler ở đây
  Colors,
} from "chart.js";
// Chart.defaults.locale = "vi";
// Đăng ký các phần tử của ChartJS
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  Colors
); // Đăng ký Filler

const GrowthChartHeight = ({ dataChart, listChildGrowth }) => {
  const linearInterpolation = (data, x) => {
    for (let i = 0; i < data.length - 1; i++) {
      const x1 = data[i].x;
      const y1 = data[i].y;
      const x2 = data[i + 1].x;
      const y2 = data[i + 1].y;

      if (x >= x1 && x <= x2) {
        return parseFloat((y1 + ((x - x1) * (y2 - y1)) / (x2 - x1)).toFixed(1));
      }
    }
    return null;
  };

  const handleSpot = (att) => {
    const arr = [];

    for (let x = 0; x <= 66; x++) {
      const y = linearInterpolation(
        dataChart?.map((e) => {
          return { x: e.Month, y: e[att] };
        }),
        x
      );
      arr.push({ x, y });
    }

    return arr;
  };
  const updateHeightBasedOnMonth = (continuousMonths) => {
    return continuousMonths.map((month) => {
      const child = listChildGrowth.find((child) => child.month == month);
      return {
        month,
        height: child ? child.height : null,
      };
    });
  };

  const data = {
    labels: handleSpot("WSD2pos")?.map((e) => e.x) || [],
    //
    datasets: [
      {
        label: "",
        type: "scatter",
        data:
          updateHeightBasedOnMonth(
            handleSpot("WSD2pos")?.map((e) => e.x) || []
          )?.map((child) => child.height) || [],

        // borderColor: "#000",
        backgroundColor: "#000",
        showLine: false,

        pointRadius: 3,
      },

      {
        label: "Suy dinh dưỡng, thể thấp còi",
        // data: HSD2neg,
        data: handleSpot("HSD2neg")?.map((e) => e.y) || [],
        type: "line",
        borderColor: "#6c757d",
        backgroundColor: "#fecdd3",
        fill: true,
        tension: 0.4,
        borderWidth: 1,

        pointRadius: 0,
      },
      // {
      //   label: "Bình thường",
      //   // data: HSD0,
      //   data: handleSpot("HSD0")?.map((e) => e.y),

      //   borderColor: "#6c757d",
      //   backgroundColor: "rgba(54, 162, 235, 0.2)",
      //   pointRadius: 0,
      //   fill: true,
      //   pointRadius: 0,
      //   tension: 0.7,
      // },
      {
        label: "Bình thường",
        // data: HSD2pos,
        data: handleSpot("HSD2pos")?.map((e) => e.y) || [],

        type: "line",
        borderWidth: 1,
        borderColor: "#6c757d",
        backgroundColor: "#a5f3fc",
        fill: true,
        pointRadius: 0,

        tension: 0.5,
      },
      {
        label: "Cao trên mức bình thường",
        // data: HSD2neg,
        data: handleSpot("HSD2neg")?.map((e) => 125) || [],
        type: "line",
        // borderColor: "red",
        borderWidth: 1,

        backgroundColor: "#fef3c7",
        fill: true,
        tension: 0.4,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,

    plugins: {
      title: {
        display: true,
        text: "Biểu đồ tăng trưởng chiều cao",
        color: "#000",
        font: {
          size: 16,
          weight: "normal",
        },
        // padding: {
        //   top: 10,
        //   bottom: 10,
        // },
      },
      legend: {
        position: "top",

        labels: {
          color: "black",
          filter: (legendItem) => {
            return legendItem.text !== "";
          },
        },
        // title: {
        //   display: true,
        //   text: "Tr\u1ebb d\u01b0\u1edbi 2 tu\u1ed5i \u0111o chi\u1ec1u d\u00e0i khi n\u1eb1m, tr\u1ebb tr\u00ean 2 tu\u1ed5i \u0111o chi\u1ec1u cao khi \u0111\u1ee9ng.",

        //   // text: "Trẻ dưới 2 tuổi đo chiều dài khi nằm, trẻ trên 2 tuổi đo chiều cao khi đứng.",
        // },
      },

      tooltip: {
        callbacks: {
          title: function (tooltipItems) {
            return `Tháng: ${tooltipItems[0].label}`;
          },
          label: function (tooltipItem) {
            const height = tooltipItem.raw;
            return `Chiều cao: ${height} cm`;
          },
        },
      },
    },

    hover: {
      mode: "nearest",
      intersect: false,
    },
    scales: {
      x: {
        type: "linear",
        title: {
          display: true,
          text: "Tháng tuổi (tháng)",
        },
        ticks: {
          stepSize: 1,
        },
      },
      y: {
        type: "linear",
        title: {
          display: true,
          text: "Chiều cao (cm)",
        },
        ticks: {
          stepSize: 5,
          max: 125,
        },
      },
    },
  };

  return (
    <div
      style={{
        minHeight: "350px",
        position: "relative",
        maxHeight: "450px",
        height: "100%",
      }}
    >
      <Line data={data} options={options} />
    </div>
  );
};

export default GrowthChartHeight;
